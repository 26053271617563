#profitsWrapper {
    background-color: white;
    position: relative;
    padding: 12vh 5vw;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    z-index: 1;

    .profits__titleWrapper {
        width: 340px;
        margin-bottom: 20px;

        .profitsTitle {
            font-size: 50px !important;
            margin: 0;
            margin-left: 10px;
            text-align: left;
            width: max-content;
        }

        .profitsSubtitle {
            font-size: 18px !important;
            font-weight: lighter;
            margin: 0;
            margin-right: 10px;
            color: var(--gray);
            text-align: right;
        }
    }

    .profits__imgWrapper {
        position: relative;
        width: 80%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 0 4vw;
    }

    .profits__imgWrapped {
        position: relative;
        left: 0;

        &>hr {
            position: absolute;
            margin: 0;
            padding: 0;
            width: 50%;
            height: 20px;
            border: 0;
            background: linear-gradient(to right, #0099ffab 0%, #78C939ab 100%);

            bottom: 4px;
            left: 0;
        }

        &>img {
            height: 240px;
        }
    }

    .profit-list {
        padding: 5vh 16vw;

        &-item {
            &:not(:last-child) {
                margin-bottom: 4vh;
            }
        }
    }

    .profits__bubbles {
        position: relative;
        width: 60%;
        padding: 2vh 10vw;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;

        --bubbleSize: 160px;

        .bubbleWrapper {
            width: var(--bubbleSize);
            height: var(--bubbleSize);
            position: relative;
            background: var(--gradient);
            padding: 3px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            margin: 10px;

            border-radius: 100%;
            transition: all 100ms linear;

            .bubbleWrapped {
                width: calc(var(--bubbleSize) - 2*10px);
                height: calc(var(--bubbleSize) - 2*10px);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: white;
                border-radius: 100%;

                text-align: center;
                align-items: center;
                transition: all 0.1s linear;

                padding: 10px;

                p {
                    margin: 0;
                }

                &-title {
                    font-size: 16px;
                }

                &-subtitle {
                    font-size: 16px;
                }
            }
        }
    }

    @media screen and (max-width: 918px) {
        .profits__imgWrapped {
            &>img {
                width: 100%;
                height: auto;
            }
        }

        .profits__list {
            padding: 5vh 6vw;
        }

        .profits__bubbles {
            width: 100%;
            padding: 2vh 2vw;
        }
    }
}

@media screen and (max-width: 740px) {
    #profitsWrapper {
        padding: 5vh 0 3vh 0;

        .profits__titleWrapper {
            padding: 0 2vw;
        }

        .profit-list {
            padding: 4vh 7vw 2vh 7vw;
        }

        .profits__imgWrapper {
            position: relative;
            width: 100%;
            padding: 0;
        }

        .profits__imgWrapped {
            &>hr {
                height: 10px;
            }
        }
    }
}

%basePin {
    display: block;
    position: absolute;
    border-radius: 50%;
    color: var(--dashboard-main-color);
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .1s ease-in-out;

    &:hover {
        transform: translate(-50%, -50%) scale(1.1);
        cursor: pointer;
    }
}

.pins {
    &-left {
        @extend %basePin;
        left: 20px;
    }

    &-right {
        @extend %basePin;
        right: 0px;
    }

    &-disabled {
        display: none;
    }
}