@import '/src/index.scss';

.parameter-wrapper {
    @extend .flex-row;

    margin: .5vh 0;

    &>:first-child {
        margin-left: 0;
        margin-right: auto;
    }

    &>:last-child {
        margin: 0 1vw;
    }
}