#reset-password {
    align-items: center;
    justify-content: center;
}

#wrapper {
    width: 50%;
    max-width: 50rem;
    min-width: 40rem;
    border-radius: 20px;
    border: solid 1px var(--light-gray2);
    padding: 2rem;
    margin: 3vw;
  
    @media screen and (max-width: 500px) {
        max-width: 0rem !important;
        #login-sub-title {
            font-size: 0.8em !important;
        }
        #login-title {
            font-size: 1.25em !important;
        }
    }
    @media screen and (max-width: 900px) {
        min-width: 70vw;
        #login-title {
            font-size: 1.5em;
        }
        #login-sub-title {
            font-size: 1em;
            margin-bottom: 0px;
        }
        #login-register-a,
        #login-register-p,
        #forgot-password-text {
            font-size: 0.75em !important;
        }
    }
}
