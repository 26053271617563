#order-solution {
    box-sizing: border-box;
    width: 100%;
    flex-grow: 1;

    padding: 5vh 8vw 0 8vw;

    row-gap: 4vh;
}

#order-solution-page {
    flex-grow: 1;

    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;

    // flex-wrap: wrap;

    width: 100%;
}

.left-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: space-between;

    max-height: 100%;

    &_detail {
        width: 100%;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        background-color: var(--background-dashboard-color);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 1vh 2vw;
        box-sizing: border-box;

        &:hover {
            cursor: pointer;
        }
    }
}

.order-gallery-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 1vh 2vw;
}

.kit_image {
    width: 40vw;
    max-width: 670px;
    height: auto;
}

.order-informations-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    overflow-y: scroll;

    max-height: 80vh;
    width: 30vw;

    padding: 2vh 2vw;

    border-radius: 20px;

    // border: 1px solid rgba(0, 0, 0, 0.12);
    // box-shadow: -5px 5px 20px 1px rgba(0, 0, 0, 0.20);

    &-step {
        width: 100%;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        &-title {
            position: relative;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            
            width: 100%;

            &>p {
                width: 32px;
                
                margin: 0;
                text-align: center;

                color: var(--dashboard-main-color);
                font-size: 24px;
                font-weight: bold;
            }

            &>div {
                margin-left: .5vw;

                &>h2 {
                    color: var(--dashboard-main-color);
                }
            }
        }
    }
}

#forgot-password-text {
    margin: 1vh 0 0 0;
}

#our-other-kits {
    box-sizing: border-box;

    width: 100%;
    max-width: 80vw;

    padding: 2vh 3vw;
    border-radius: 20px;

    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: -5px 5px 20px 1px rgba(0, 0, 0, 0.20);

    .solution_list {
        width: 100%;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        overflow-x: scroll;

        padding: 1vh 0;

        &>div {
            padding: .5vh 1vw;
            border-radius: 20px;
            border: 1px solid rgba($color: #000000, $alpha: 0.0);

            transition: all .2s ease-in-out;

            &>img {
                width: 12vw;
                min-width: 240px;
                max-width: 460px;
            }

            &:hover {
                cursor: pointer;

                border: 1px solid rgba(0, 0, 0, 0.12);
                box-shadow: -5px 5px 15px 1px rgba(0, 0, 0, 0.20);
            }
        }
    }
}

@media screen and (max-width: 1160px) {
    #order-solution-page {
        flex-wrap: wrap;
    }

    .order-gallery-wrapper {
        flex-grow: 1;
    }

    .kit_image {
        width: 80vw;
    }

    .order-informations-wrapper {
        flex-grow: 1;
        
        max-height: none;

        overflow-y: visible;
    }

    .left-section {
        &_detail {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;

            margin: 1vh 0;
        }
    }
}