.CustomLinkWrapper {
    background-color: var(--light-gray2);
    padding: 2vh 0 2vh 0;
    margin: 15px 0 15px 0;
    width: 100%;

    &-title {
        margin: 0 0 10px 0;
    }

    &-btn {
        text-decoration: none;
        background-color: var(--dashboard-main-color);
        color: white;
        padding: .5vh 3vw .5vh 3vw;
        border: 0;
        border-radius: 30px;

        &:hover {
            cursor: pointer;
        }
    }
}