#document-language-selection {
    align-items: center;
    height: 80vh;
    #language-selection-wrapper {
        width: 90%;
        #title {
            margin: 3vh 0vw 8vh 0vw;
            font-size: 22px;
            text-transform: uppercase;
        }
    }
    #languages-wrapper {
        .language {
            align-items: center;
            margin-bottom: 5vh;
            .language-flag {
                width: 7rem;
                height: 3rem;
                object-fit: cover;
            }
            .language-name-wrapper {
                border: solid 1px var(--dashboard-main-color);
                border-radius: 10px;
                margin-top: 2vh;
                .language-name {
                    color: var(--dashboard-main-color);
                    font-size: 18px;
                    padding: 0.75vh 2rem 0.75vh 2rem;
                    text-transform: uppercase;
                }
            }
        }
    }
}
