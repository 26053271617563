.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Montserrat font loading */
@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format("opentype");
    font-display: block;
}
@font-face {
    font-family: "Montserrat-Bold";
    src: local("Montserrat-Bold"), url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format("opentype");
    font-display: block;
}

/* MadeCanvas font loading */
@font-face {
    font-family: "MadeCanvas";
    src: local("MadeCanvas"), url(./assets/fonts/MadeCanvas/MadeCanvas-Regular.otf) format("opentype");
    font-display: block;
}
@font-face {
    font-family: "MadeCanvas-Bold";
    src: local("MadeCanvas-Bold"), url(./assets/fonts/MadeCanvas/MadeCanvas-Black.otf) format("opentype");
    font-display: block;
}

/* NewJune font loading */
@font-face {
    font-family: "NewJune";
    src: local("NewJune"), url(./assets/fonts/NewJune/NewJune-Regular.otf) format("opentype");
    font-display: block;
}
@font-face {
    font-family: "NewJune-Bold";
    src: local("NewJune-Bold"), url(./assets/fonts/NewJune/NewJune-Heavy.otf) format("opentype");
    font-display: block;
}

/* MavenPro font loading */
@font-face {
    font-family: "MavenPro-Regular";
    src: local("MavenPro-Regular"), url(./assets/fonts/MavenPro/MavenPro-Regular.otf) format("opentype");
    font-display: block;
}
@font-face {
    font-family: "MavenPro-Bold";
    src: local("MavenPro-Bold"), url(./assets/fonts/MavenPro/MavenPro-Bold.otf) format("opentype");
    font-display: block;
}
@font-face {
    font-family: "MavenPro-Black";
    src: local("MavenPro-Black"), url(./assets/fonts/MavenPro/MavenPro-Black.otf) format("opentype");
    font-display: block;
}

/* Sofachrome font loading */
@font-face {
    font-family: "Sofachrome-Italic";
    src: local("Sofachrome-Italic"), url(./assets/fonts/Sofachrome//sofachrome\ rg\ it.ttf) format("opentype");
    font-display: block;
}
@font-face {
    font-family: "Sofachrome-Regular";
    src: local("Sofachrome-Regular"), url(./assets/fonts/Sofachrome/sofachrome\ rg.ttf) format("opentype");
    font-display: block;
}
