@import "/src/index.scss";

#help-container {
    @extend .flex-column;
    @extend .full-width;

    overflow: hidden;
}

.help-step-welcome-img {
    z-index: 1;
    position: absolute;
    left: 0;
    height: 90%;
    top: 0;
}

.help-step {
    @extend .flex-column;
    @extend .full-width;
    @extend .flex-grow;

    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 0 4vw;
    z-index: 3;
    
    &-content {
        @extend .flex-row;
        @extend .full-width;
        @extend .flex-wrap;
        
        margin-top: 14px;
        p {
            line-height: 1.6;
        }
    }

    .help-sum-up {
        @extend .flex-column;
        @extend .full-width;

        align-items: normal;

        &>div {
            @extend .flex-row;
            @extend .align-items-end;
            @extend .full-width;
            
            box-sizing: border-box;
            position: relative;
            justify-content: flex-end;
            overflow-x: hidden;

            padding: 0 5vw;

            height: 25vh;

            margin-bottom: 25px;

            &:not(:last-child) {
                border-bottom: 1px black solid;
            }

            &>div {
                @extend .full-height;

                z-index: 1;
                background: rgb(255,255,255);
                background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%);

                &>p {
                    text-align: center;
                }
            }

            &>img {
                left: 15%;
                z-index: 0;
                position: absolute;
                object-fit: cover;
                object-position: left top;
                height: 100%;
                width: 50%;
            }
        }
    }
}

%tick {
    width: 10px;
    height: 10px;

    margin: 5px;

    border-radius: 50%;

    &:hover {
        cursor: pointer;
    }
}

.list-tick {
    @extend %tick;
    background-color: var(--light-gray);

    &-actual {
        @extend %tick;
        background-color: var(--dashboard-main-color);
    }
}

.help-img {
    width: auto;
    height: 280px;
    margin: 0 40px;
}

.button-wrapper {
    @extend .flex-row;
    @extend .full-width;
    @extend .flex-wrap;
    @extend .space-around;

    margin: 1vh 1vw;
}