#modal-food-details-header {
    #title {
        font-size: 18px;
        text-transform: uppercase;
    }
    #icon-close {
        color: var(--dashboard-main-color);
        &:hover {
            cursor: pointer;
        }
    }
}

#food-wrapper {
    justify-content: center;
    align-items: center;
    margin: 0vh 0vw 3vh 0vw;
    #food-img-wrapper {
        margin-right: 1rem;
        padding: 3px;
        background-color: var(--gray);
        width: 6rem;
        height: 6rem;
        border-radius: 100%;
        #food-img {
            border-radius: 100%;
            width: 100%;
            height: 100%;
            background-color: white;
            object-fit: cover;
        }
    }
    #food-info-wrapper {
        max-width: 40%;

        #food-type {
            color: var(--gray);
            text-transform: uppercase;
        }
    }
}
