#special-information {
    #title-wrapper {
        justify-content: space-between;
        align-items: center;
    }
    .special-information-content {
        border: solid 1px var(--dashboard-main-color);
        min-height: 20vh;
        padding: 1vh 1vw 1vh 1vw;
        border-radius: 15px;
        margin: 3vh 0vw 3vh 0vw;
        #special-information-placeholder {
            color: var(--dark-gray);
            font-size: 15px;
        }
    }
}
