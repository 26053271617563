.accountOrder {
    &-wrapper {
        width: 100%;

        &-table {
            border-collapse: separate;
            border-spacing: 0 5px;

            &-header {

            }

            &-body {
                &-row {
                    &:nth-child(odd) {
                        background-color: var(--light-gray3);
                    }
        
                    &:nth-child(even) {
                        background-color: var(--background-color);
                    }

                    min-height: 50px !important;
                    transition: all 0.4s ease-in-out;

                    td {
                        padding: 10px;
                        margin: auto;
                        transition: inherit;

                        &>button {
                            position: relative;
                            display: block;
                            margin: auto;
                        }

                        &>p {
                            margin: auto;
                            text-align: center;
                            transition: inherit;
                        }
                    }

                    &-btn {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        transition: inherit;
                    }
                }
            }
        }

        &-buttons {
            button {
                border: 0;
                background-color: var(--background-color);
                font-size: 1.2em;
                
                &>svg {
                    color: var(--dashboard-main-color);
                }
            }
        }
    }
}