@import '/src/index.scss';

#patient-order-processing {
    width: 100%;
    padding-bottom: 1vh;
    margin-top: 5vh;

    .indexedStep {
        color: white;
        width: 20px;
        height: 20px;
        font-size: 12px;
        background-color: var(--light-gray);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    span {
        text-align: center;
    }
}