@import "index.scss";

#register {
    align-items: center;
    justify-content: center;
}

#register-title {
    font-size: 42px;
    margin: 1vh 0vw 0vh 0vw;
    color: var(--gray);
}
#register-sub-title {
    font-size: 18px;
    color: var(--gray);
    margin: 2vh 0vw 2.5vh 0vw;
}

.register-row {
    @extend .flex-row;
    @extend .full-width;
    @extend .space-between;

    align-items: start;
}

.register-row:not(:last-child) {
    margin-bottom: 20px;
}