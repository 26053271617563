#doctor-questionnaire-overview {
    justify-content: center;
    align-items: center;
    height: 100vh;
    #doctor-questionnaire-overview-wrapper {
        padding: 3vh 2vw 3vh 2vw;
        width: 70%;
        height: 80vh;
        background-color: white;

        #question-overview-header {
            align-items: center;
            justify-content: space-between;
            margin-bottom: 3vh;
            #patient-name {
                color: var(--dashboard-main-color);
                margin: 0px;
            }
            #icon-close {
                cursor: pointer;
            }
        }

        #qa-list-wrapper {
            width: 70%;
            align-self: center;
            flex-grow: 1;
            overflow-y: auto;
            align-items: center;
        }
        #button-see-more {
            cursor: pointer;
            background-color: var(--dashboard-main-color);
            padding: 0.25rem 1rem 0.25rem 1rem;
            margin-bottom: 2vh;
            border-radius: 20px;
            span {
                color: white;
            }
        }
        #empty-survey-wrapper {
            flex-grow: 1;
            justify-content: center;
        }
    }
}
