@import '/src/index.scss';

.historyAppointment {
    &-wrapper {
        @extend .full-width;
        @extend .flex-column;
        @extend .justify-content-start;
        @extend .flex-grow;

        max-height: 68vh;
        margin: 0 0 0 20px;
        border-radius: 10px;
        overflow-y: auto;

        &>div {
            @extend .full-width;
            @extend .flex-row;
            @extend .space-between;

            box-sizing: border-box;
            padding: 0 20px;
            background-color: var(--light-gray2);

            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }
}