.test-result{
    &:not(:first-child) {
        margin-top: 0vh;
    }
}
.title-test-result {
    color: var(--dashboard-main-color);
    margin-top: 2vh;
    &:first-child {
        margin-top: Ovh;
    }

    p {
        margin: 0;
        font-size: 20px;
    }

    .divider {
        position: relative;
        display: block;
        width: 70%;
        border: 1px solid black;
        margin: 0 0;
        top: .5em;
        height: 0;
        align-items: center;
        dominant-baseline: middle;
        text-anchor: middle;
    }
}

.downloadable {
    width: 80%;
    margin: auto;

    .card-downloadable {
        margin: 1vh 0;
        padding: 2vh 8vw 4vh 8vw;
        background-color: var(--light-gray2);
    }
}