#how-it-works-banner {
    align-items: center;
    #header-banner-wrapper {
        width: 100%;
        position: relative;
        height: 70vh;
        margin-bottom: 12vh;
        #banner-img {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
        }

        #header-banner-text-wrapper {
            z-index: 1;
            margin: 0vh 6vw 0vh 0vw;
            height: 100%;
            justify-content: center;
            min-width: 15rem;
            align-self: flex-end;
            width: 30vw;
            min-width: 15rem;
            #title {
                margin: 0px;
                text-transform: uppercase;
                color: white;
                font-size: 36px;
                @media screen and (max-width: 900px) {
                    font-size: 28px;
                }
            }
            #sub-title {
                color: white;
                line-height: 1.5rem;
            }
        }
    }
    #instructions-wrapper {
        width: 50%;
        margin-bottom: 12vh;
        align-items: center;

        @media screen and (max-width: 900px) {
            width: 70%;
        }
        #instructions-text {
            margin: 0px 0px 3vh 0px;
            text-transform: uppercase;
            text-align: center;
            font-size: 18px;
            @media screen and (max-width: 600px) {
                font-size: 16px;
            }
        }
        #instructions-button {
            border-radius: 30px;
            padding: 2px;
            &:hover {
                cursor: pointer;
            }
            #instructions-button-wrapper {
                border-radius: 30px;
                background: white;
                padding: 1.5vh 2rem 1.5vh 2rem;
            }
            span {
                font-size: 16px;
                text-transform: uppercase;
            }
        }
    }
}
