@import "/src/index.scss";

.tracking-detail-wrapper {
    .now-steps {
        @extend .flex-row;
        @extend .full-width;
        @extend .space-around;

        .step {
            @extend .flex-column;
            width: 45%;
        }
    }

    .all-steps {
        @extend .flex-column;
        @extend .full-width;

        &>div {
            @extend .full-width;
            @extend .flex-column-start;
            
            &:not(:last-child) {
                border-bottom: 1px solid black;
                margin-bottom: 10px;
            }
        }
    }
}