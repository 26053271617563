$allocate-main: var(--dashboard-main-color);
$gray-color: #808080;

.CompanyProfileModal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: calc(100%);
    height: 100vh;

    padding: 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #000000, $alpha: 0.2);

    &-content {
        box-shadow: 5px 5px 6px #0000003a;
        position: relative;
        width: 40%;
        min-width: 400px;
        max-width: 500px;
        padding: 1vh 2vw;
        background-color: white;

        @media screen and (max-width: 740px) {
            width: 70%;
        }

        &-header {
            position: relative;
            width: 100%;

            &-close-btn {
                position: absolute;
                top: 0;
                right: 0;
                color: $allocate-main;
                width: 30px;
                height: 30px;
            
                &:hover {
                    cursor: pointer;
                }
            }
        }

        &-body {
            .CompanyProfileModal-content-body-form-fields-wrapper {
                margin: 1em 0;
            }

            &-form {
                padding: 0 2vw;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: center;

                &-fields {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                    align-items: center;

                    &>div {
                        margin: 10px 0;
                    }
                }

                &-validate {
                    background-color: $allocate-main;
                    color: white;
                    border: 0;
                    margin-top: 10px;
                    padding: 10px 25px;
                    border-radius: 30px;
                    width: fit-content;
                    font-size: 14px;
                    transition: all .3s ease-in-out;

                    &:hover {
                        cursor: pointer;
                        transform: scale(1.05);
                    }

                    &:disabled {
                        background-color: $gray-color;

                        &:hover {
                            cursor: not-allowed;
                            transform: scale(1);
                        }
                    }
                }

                &-sub {
                    font-size: 12px;
                }
            }
        }
    }
}