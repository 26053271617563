#doctor-form {
    align-items: center;
    #content {
        width: 90%;
        #title-wrapper {
            margin: 10vh 0vw 2vh 0vw;
            align-items: center;
            color: var(--dashboard-main-color);
            #title {
                margin: 0px;
                font-size: 24px;
                text-transform: uppercase;
            }
            #icon-go-back {
                margin-right: 0.75vw;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        #doctor-form-wrapper {
            #last-update-label {
                font-size: 12px;
                font-style: italic;
            }
            #doctor-form-header-wrapper {
                align-items: center;
                justify-content: space-between;
                #header-title {
                    font-size: 22px;
                }
            }
            #input-wrapper {
                margin-bottom: 6vh;
                .textarea {
                    border: solid 2px var(--dashboard-main-color);
                    border-radius: 25px;
                    padding: 1.5vh 1rem 1.5vh 1rem;
                    height: 15vh;
                    margin-top: 1.5vh;
                    font-size: 16px;
                }
            }
        }
    }
}
