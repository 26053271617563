$sectionBackground: #ebf1f3;

#urinary {
    position: absolute;
    transform: translateY(-90px);
}

.urinaryWrapper {
    background-color: $sectionBackground;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 4vh 15vw;

    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

    &-title {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;

        --bubbleSize: 120px;

        &-number {
            width: var(--bubbleSize);
            height: var(--bubbleSize);
            position: relative;
            background: var(--gradient);
            padding: 3px;

            margin: 10px;

            border-radius: 100%;
            transition: all 100ms linear;

            &>div {
                width: calc(var(--bubbleSize) - 2*10px);
                height: calc(var(--bubbleSize) - 2*10px);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: $sectionBackground;
                border-radius: 100%;

                text-align: center;
                align-items: center;
                transition: all 0.1s linear;

                padding: 10px;

                &>p {
                    font-size: 4em;
                }
            }
        }

        &-content {
            position: relative;
            min-width: 728px;

            &-main {
                font-size: 4em;
                width: max-content;
            }

            &-sub {
                position: absolute;
                margin: 0;
                right: 0;
                bottom: 2vh;
                font-size: 1.5em;
            }
        }
    }

    &-banner {
        position: relative;
        padding: 0 5vw;
        width: 100%;

        &>img {
            position: relative;
            left: 50%;
            transform: translateX(-50%);

            overflow: hidden;
            width: 100%;
            max-width: 1000px;
            max-height: 20vh;
            object-fit: cover;
            object-position: 0 75%;
        }

        &>hr {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 45%);

            border: 0;
            background: var(--gradient);
            width: 60%;
            max-width: 610px;
            height: 20px;
        }
    }

    &-content {
        position: relative;
        padding: 4vh 14vw;
        font-size: 22px;

        &-sub {
            font-size: 18px;
            line-height: 1.7em;
        }

        &-detail {
            font-size: 18px;
        }
    }

    &-button {
        margin: 4vh 0;

        :first-child {
            background-color: $sectionBackground;
        }

        &:hover {
            cursor: pointer;
    
            :first-child {
                background-color: rgba($color: #000000, $alpha: 0.0);
    
                :first-child {
                    background: $sectionBackground;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }

    @media screen and (max-width: 1500px) {
        padding: 2vh 10%;
    }

    @media screen and (max-width: 1290px) {
        &-title {
            --bubbleSize: 10vw;

            &-content {
                min-width: 0;
                width: auto;

                &-main {
                    font-size: 5vw;
                }

                &-sub {
                    min-width: 536px;
                    transform: translate(20%, -20%);
                }
            }
        }
    }

    @media screen and (max-width: 1151px) {
        &-title {
            &-content {
                &-main {
                    font-size: 4em;
                }

                &-sub {
                    position: relative;
                    min-width: 0;
                    transform: translate(0, 0);
                    margin-top: 0;
                }
            }
        }
    }

    @media screen and (max-width: 770px) {
        &-title {
            
            --bubbleSize: 80px;
            &-number {
                &>div {
                    &>p {
                        font-size: 32px;
                    }
                }
            }
            
            &-content {    
                &-main {
                    font-size: 2em;
                }

                &-sub {
                    font-size: 15px;
                }
            }
        }

        &-content {
            padding: 4vh 8vw;
        }
    }

    @media screen and (max-width: 740px) {
        &-banner {
            &>hr {
                height: 10px;
            }
        }
    }

    @media screen and (max-width: 690px) {
        padding: 2vh 0;

        &-title {
            padding: 0 1vw;

            &-main {
                text-align: center;
            }
        }

        &-banner {
            padding: 0;
        }
    }
}