$main-color: var(--dashboard-main-color);
$button-color: #b7b7b7;

#invoicing {
    padding: 0 5vw;
}

.attribute-solution {
    border: 0;
    border-radius: 30px;
    background-color: $main-color;
    color: white;
    padding: .5vh 2vw;
    
    &:hover {
        cursor: pointer;
    }

    &:disabled {
        cursor: not-allowed;
        background-color: $button-color;
    }
}

.kit-warning {
    color: red;
    margin-top: 25px;
    font-style: italic;
}