#health-information {
    #title-wrapper {
        justify-content: space-between;
        align-items: center;
    }
    
}

#health-information-wrapper {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;
    flex-wrap: wrap;

    row-gap: 2vh;
    column-gap: 2vw;
}
