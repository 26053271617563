#general-information-wrapper {
    margin: 1vh 0vw 1vh 0vw;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    @media screen and (max-width: 1100px) {
        flex-wrap: wrap;
    }
}

#profile-picture-container {
    margin: 20px 0;
    box-sizing: border-box;
    min-width: 8rem;
    min-height: 8rem;
    width: 8vw;
    height: 8vw;
    aspect-ratio: 1/1;
}

#list-information-wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;

    flex-wrap: wrap !important;

    flex-grow: 1;
    row-gap: 1vh;
    column-gap: max(1vw, 20px);

    .information-wrapper {
        flex: 33%;
        width: auto;
        margin: 1vh 0vw 1vh 0vw;
    }
}