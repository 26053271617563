#medecin-referent {
    width: 100%;

    #content-medecin-information {
        margin-top: 2vh;
        align-items: center;

        #patient-information-photo {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2px;
            border-radius: 100px;
            width: 4rem;
            height: 4rem;

            img {
                border-radius: 100px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            #icon-no-profil-pic {
                width: 100%;
                height: 100%;
            }
        }

        #information-personnel {
            flex-grow: 1;
            margin: 0 0 0 2vw;
        }
    }
}
