#doctor-next-appointement {
    background-color: var(--dashboard-main-color);
    border-radius: 10px;
    width: 100%;
    color: #fff;
    padding: 1em 0.5em;
    margin: auto;
    box-sizing: border-box;

    #title {
        padding: 0;
        margin: 0;
        text-align: center;
    }

    #text-appointement {
        padding: 0.5em 0 0 0;
        margin: auto;
        width: 90%;

        .sidebar-patient-name {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
