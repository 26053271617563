@import '/src/index.scss';

#patients-docteur {
    margin-bottom: 20vh;
}

#doctor-dashboard {
    align-items: center;

    #content-wrapper {
        margin: auto;
        width: 90%;
    }

    h2 {
        font-size: 20px;
    }
}

#header-prochain-rdv {
    position: relative;
    div {
        display: flex;
        align-items: center;
    }
}

#prochain-rdv {
    margin: 3vh 2vw;
    padding: 1vh 2vw;
    align-items: center;
    font-weight: bold;

    #info-rdv {
        padding: 0 1em;
        align-items: center;
    }

    #check-rdv {
        margin: 0.8em 0 0 0;
    }

    .step {
        border-radius: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .step-label {
        font-size: 16px;
    }
    .step-not-done {
        background-color: white;
        color: var(--dashboard-main-color);
        border: solid 2px white;
        font-size: 20px;
    }
    .step-done {
        border: solid 2px var(--dashboard-main-color);
        color: var(--dashboard-main-color);
    }
    .MuiStepConnector-horizontal {
        border: solid 1px var(--dashboard-main-color);
    }

    .check {
        position: relative;
        background-color: #fff;
        border-radius: 40px;
        border: 1px solid var(--dashboard-main-color);
        padding: 1vh 3vw;
        align-items: center;
        z-index: 100;

        img {
            width: 40px;
            height: 40px;
            border-radius: 25px;
            vertical-align: middle;
            margin: 0 0.5vw 0 0;
        }

        p {
            padding: 0;
            margin: 0;
        }

        &:after {
            padding: auto;
            display: block;
            margin: auto;
            position: absolute;
            content: "";
            background-color: black;
            width: 100px;
            height: 100px;
            top: 0;
        }

        &:after {
            content: "";
            z-index: 1;
            width: 50%;
            height: 2px;
            background: var(--dashboard-main-color);
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 100%;
            transform: translate(0, -100%);
        }
    }

    p {
        padding: 0;
        margin: 0;
    }
}

.check-button {
    z-index: 100;
    align-items: center;
    background-color: var(--light-gray);
    border-radius: 30px;
    padding: 1vh 2vw;
    font-weight: bold;
    color: #fff;
    border: none;
    cursor: pointer;
    outline: inherit;
    font-family: MavenPro-Bold;

    p {
        padding: 0;
        margin: 0;
    }
}

#tous-les-rdv {
    font-size: 16px;
    border-radius: 5px;
    margin: 1vh 2vw 3vh 2vh;
    padding: 1vh 2vw;
    align-items: center;

    #calendrier {
        // width: 50%;
        margin: 0 2em;
    }

    #liste-des-patients {
        margin: 0 2em;
        text-decoration: none;
        max-width: 450px;
        min-width: 350px;
        width: 50%;

        .left {
            margin: 0;
            padding: 0;
            font-weight: bold;
            cursor: pointer;

            &:hover {
                text-decoration-line: underline;
            }
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    li {
        margin: 0 0 0.7em 0;
        background-color: var(--light-gray2);
        padding: 1vh 0.4vw;
    }
}

.right {
    margin: 0;
    padding: 0;
    text-align: right;
    font-weight: normal;
}

#historique {
    font-size: 18px;
    border-radius: 5px;
    margin: 1vh 0;

    #historique-rdv {
        font-size: 16px;
        margin-top: 1em;
        text-decoration: none;
        width: 100%;
        flex-grow: 1;

        p {
            font-weight: bold;
            margin: 0;
            padding: 0 0 0 1.5em;
        }

        ul {
            flex-grow: 1;
            padding: 0;
            margin: 0;
            list-style: none;
        }

        li {
            margin: 0 0 0.7em 0;
            background-color: var(--light-gray2);
            padding: 1vh 0.4vw;
        }
    }

    #no-data-wrapper {
        margin: 1em 0px 1em 0px;
    }
    .full-height {
        height: 100%;
    }

    #historique-action {
        font-size: 16px;
        margin-top: 1em;
        text-decoration: none;
        width: 100%;
        flex-grow: 1;

        ul {
            padding: 0;
            margin: 0;
            flex-grow: 1;
            list-style: none;
        }

        li {
            margin: 0 0 0.7em 0;
            background-color: var(--light-gray2);
            padding: 1vh 0.4vw;
        }

        p {
            font-weight: bold;
            margin: 0;
            padding: 0 0 0 1.5em;
        }
    }

    .left {
        text-decoration-line: none;
        cursor: pointer;
        &:hover {
            text-decoration-line: underline;
        }
    }

    .right {
        font-weight: normal;
    }

    .historique-button {
        margin: auto;
        align-items: center;

        div {
            margin: 3em 0 1em 0;
        }
    }
}

#chat-interne {
    justify-content: center;
    border-radius: 5px;
    margin: 1vh 2vw;
    padding: 1vh 2vw;

    p {
        font-weight: bold;
        margin: 0;
        padding: 0 0 0 1.5em;
    }
}

#slack {
    align-items: center;
}

#div-icon-slack {
    background-color: black;
    border-radius: 20px;
    width: 90px;
    height: 90px;

    img {
        margin: auto;
        width: 60px;
        height: 60px;
    }
}

#discution {
    padding: 0 5em;
    align-items: center;
}

.card {
    background-color: #fff;
    position: relative;
    width: auto;
    margin: 2em 2em;
    padding: 3vh 2vw;

    &::after {
        content: "";
        height: 12px;
        display: block;
        position: absolute;
        background: linear-gradient(to right, var(--gradient-blue) 0%, var(--gradient-green) 100%);
        bottom: -6px;
        max-width: 300px;
        width: 50%;
        min-width: 10%;
        right: 40%;
        left: 40%;
    }
}

.MuiStep-root .MuiStep-horizontal Mu.i-completed .css-1bw0nnu-MuiStep-root {
    position: relative;
    label  {
        position: absolute;
    }

    &::after {
        content: attr(data);
    }
}

#date-prochain-rdv {
    #date-rdv {
        @extend .flex-row;
        @extend .align-items-stretch;

        &-day {
            @extend .MavenPro-bold;

            vertical-align: middle;
            margin: 0;
            font-weight: 100;
            font-size: 5em;
        }

        &-rest {
            @extend .MavenPro;
            @extend .flex-column;

            vertical-align: middle;
            margin: 0;
            font-size: 2em;
        }
    }

    hr {
        border: solid 1px var(--dashboard-main-color);
        color: var(--dashboard-main-color);
    }

    #heure-rdv {
        font-size: 40px;
        color: var(--dashboard-main-color);
        text-align: center;

        &>p {
            @extend .MavenPro;
            margin: 0;
        }
    }
}

#patient-prochain-rdv {
    .patient-desc {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        margin-bottom: auto;

        &-imgWrapper {
            background: var(--gradient);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px;
            width: fit-content;
            border-radius: 100%;
            -moz-border-radius: 100%;
            -webkit-border-radius: 100%;
        }

        #image-user {
            background: var(--gradient);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 5vw;
            height: 5vw;
            border-radius: 100%;
            -moz-border-radius: 100%;
            -webkit-border-radius: 100%;
        }

        &-name {
            font-size: 1.2em;
            padding: 0.4em 0 0 0;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        img {
            border-radius: 100%;
            -moz-border-radius: 100%;
            -webkit-border-radius: 100%;
        }
    }
}
