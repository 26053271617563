#validation-order {
    box-sizing: border-box;
    width: 100%;
    flex-grow: 1;

    padding: 5vh 8vw 0 8vw;

    row-gap: 4vh;
}

#validation-order-page {
    flex-grow: 1;

    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;

    // flex-wrap: wrap;

    width: 100%;
}

.order-informations-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    overflow-y: scroll;

    max-height: 80vh;
    width: 30vw;

    padding: 2vh 2vw;

    border-radius: 20px;
}

@media screen and (max-width: 1160px) {
    #validation-order-page {
        flex-wrap: wrap;
    }

    .order-gallery-wrapper {
        flex-grow: 1;
    }

    .kit_image {
        width: 80vw;
    }

    .order-informations-wrapper {
        flex-grow: 1;
        
        max-height: none;

        overflow-y: visible;
    }

    .left-section {
        &_detail {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;

            margin: 1vh 0;
        }
    }
}