@import "/src/index.scss";

$wording-bg: #ece9ef;

$left: #f1eff5;
$right: #dedde3;

.scienceBannerWrapper {
    @extend .flex-column;
    @extend .full-width;

    position: relative;
    overflow-x: hidden;

    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

    &-img {
        position: relative;
        width: 100%;
        height: 35vw;
        max-height: 70vh;
        min-height: 30vh;
        object-fit: cover;
        object-position: 0 0;
        pointer-events: none;

        transition: inherit;
    }

    &-content {
        transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
        position: absolute;
        height: 100%;
        width: 65%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);

        &-logo {
            z-index: -1;
            position: absolute;
            bottom: 0;
            right: 0;
            height: 60%;
            color: gray;
            transform: translateX(15%);
        }

        &-title {
            width: 100%;
            transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

            &-sub {
                font-size: 1.0em;
                margin: 0;
                color: var(--gray);
                line-height: 3vh;
            }

            &-main {
                overflow: visible;
                font-size: 6em;
                margin: 0;
                padding: 0 0 14px 0;
                width: min-content;
            }
        }
    }

    hr {
        width: 60%;
        height: 20px;
        border: 0;
        background: var(--gradient);
        position: absolute;
        z-index: 2;
        margin: 0;

        left: 50%;
        bottom: 0;
        transform: translate(-50%, 45%);
    }

    @media screen and (max-width: 1482px) {
        &-content {
            width: 70%;
        }
    }

    @media screen and (max-width: 1125px) {
        &-img {
            opacity: 0.9;
        }

        &-content {
            width: 90%;
            justify-content: flex-end;

            &-logo {
                margin-left: 2vw;
            }

            &-title {
                border-radius: 6px;
                width: 80%;
                padding: 15px;
                background-color: rgba($color: $wording-bg, $alpha: 0.9);
                box-shadow: 5px 5px 10px #dedde3;

                &-sub {
                    font-size: 1.0em;
                }

                &-main {
                    font-size: calc(28px + 1.5vw);
                }
            }
        }
    }

    @media screen and (max-width: 740px) {
        hr {
            height: 10px;
        }

        &-content {
            &-title {
                &-sub {
                    font-size: 12px;
                    line-height: 2vh;
                }
            }
        }
    }
}