#microbiota {
    position: absolute;
    transform: translateY(-90px);
}

.microbiotaAnalysisWrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    padding: 2vh 15vw;

    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

    &-title {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;

        --bubbleSize: 120px;

        &-number {
            width: var(--bubbleSize);
            height: var(--bubbleSize);
            position: relative;
            background: var(--gradient);
            padding: 3px;

            margin: 10px;

            border-radius: 100%;
            transition: all 100ms linear;

            &>div {
                width: calc(var(--bubbleSize) - 2*10px);
                height: calc(var(--bubbleSize) - 2*10px);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: white;
                border-radius: 100%;

                text-align: center;
                align-items: center;
                transition: all 0.1s linear;

                padding: 10px;

                &>p {
                    font-size: 4em;
                }
            }
        }

        &-content {
            position: relative;
            min-width: 728px;

            &-main {
                font-size: 4em;
                width: max-content;
            }

            &-sub {
                transform: translateX(50%);
                position: absolute;
                margin: 0;
                right: 0;
                bottom: 4vh;
                font-size: 1.5em;
            }
        }
    }

    &-banner {
        position: relative;
        padding: 0 5vw;

        &>img {
            position: relative;
            left: 50%;
            transform: translateX(-50%);

            overflow: hidden;
            width: 100%;
            max-width: 1000px;
            max-height: 260px;
            object-fit: cover;
            object-position: 0 100%;
        }

        &>hr {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 45%);

            border: 0;
            background: var(--gradient);
            width: 60%;
            max-width: 610px;
            height: 20px;
        }
    }

    &-list {
        position: relative;
        padding: 5vh 12vw;
        text-align: start;
        display: block;

        &-item {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;

            &:not(:last-child) {
                margin-bottom: 4vh;
            }

            &>div {
                position: relative;
                transform: translateY(50%);
                width: 10px !important;
                height: 10px !important;
                background: var(--gradient);
                border: 0;
                border-radius: 100%;
                content: "";

                margin: 2px 10px 0 0;
            }

            &>p {
                color: var(--gray);
                margin: 0;
                width: 95%;
                font-weight: lighter;
                font-size: 18px;
                line-height: 1.5em;
            }
        }
    }

    @media screen and (max-width: 1500px) {
        padding: 2vh 10%;
    }

    @media screen and (max-width: 1290px) {
        &-title {
            --bubbleSize: 10vw;

            &-content {
                min-width: 0;
                width: auto;

                &-main {
                    font-size: 5vw;
                }

                &-sub {
                    font-size: 2vw;
                }
            }
        }
    }

    @media screen and (max-width: 770px) {
        &-title {
            
            --bubbleSize: 80px;
            &-number {
                &>div {
                    &>p {
                        font-size: 32px;
                    }
                }
            }
            
            &-content {    
                &-main {
                    font-size: 2em;
                }

                &-sub {
                    position: relative;
                    text-align: right;
                    transform: translate(0, 0);
                    font-size: 15px;
                }
            }
        }
    }

    @media screen and (max-width: 740px) {
        &-banner {
            &>hr {
                height: 10px;
            }
        }
    }

    @media screen and (max-width: 640px) {
        padding: 2vh 0;

        &-title {
            padding: 0 1vw;

            &-content {
                width: 70vw;
                &-main {
                    text-align: left;
                    width: 100%;
                }
            }
        }

        &-banner {
            padding: 0;
        }
    }
}