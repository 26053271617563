#patient-profile {
    #content-wrapper {
        width: 90%;
        .profile-edit-icon {
            width: 1.5rem;
            &:hover {
                cursor: pointer;
            }
        }
    }
}
