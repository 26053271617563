#all-appointments-wrapper {
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 2vh;
}

.list-title {
    color: var(--dashboard-main-color);
    text-transform: uppercase;
    margin: 0px;
}

.appointment-wrapper {
    background-color: var(--background-dashboard-color);
    justify-content: space-between;
    padding: 1.5vh 1vw 1.5vh 1vw;
    margin: 1.5vh 0vw 1.5vh 0vw;
}

.appointment-doctor-name {
    font-size: 16px;
    text-transform: uppercase;
}

#past-appointments {
    min-width: 20rem;
    flex: 1;
}

#upcoming-appointments {
    flex: 1;
    min-width: 20rem;
}
