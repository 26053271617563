@import "/src/index.scss";

.sidebar-icon {
    @extend .align-items-center;

    position: absolute;

    left: 0;
    transform: translateX(-100%);
}

#sidebar {
    @extend .flex-column;
    @extend .justify-content-start;

    position: -webkit-sticky;
    position: sticky;
    top: 0;

    width: 18rem;
    height: calc(100vh - (2*4vh));
    flex: 1 1 auto;
    align-self: flex-start;
    padding: 4vh 0;

    overflow-x: hidden;
    overflow-y: auto;

    &-logo {
        width: 70%;

        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }

    &-welcome {
        @extend .flex-column;
        
        box-sizing: border-box;
        max-width: 90%;
        align-items: center;
        padding: 0 10px;

        .sidebar-title {
            @extend .text-align-center;
            @extend .text-uppercase;
            @extend .hide-text-overflow;

            margin: 3vh 0vw 0.5vh 0vw;
            font-size: 22px;
        }

        #subTitle {
            width: 90%;
            text-align: center;
            margin: 0vh 0vw 3vh 0vw;
            font-size: 15px;
        }
    }

    &-menu {
        @extend .flex-column;

        width: 100%;
        min-height: 550px;
        padding: 2vh 0;
        align-items: flex-end;
        justify-content: space-around;
        flex-grow: 1;

        .menu-item {
            @extend .flex-row-start;

            position: relative;
            width: 65%;
            padding: 10px;

            &:hover {
                cursor: pointer;
            }

            &-red {
                color: red;
            }

            &-selected {
                font-weight: bold;
                padding-right: 3px;
                border-right: solid 7px var(--dashboard-main-color);
            }
        }
    }

    &-language {
        @extend .flex-row;
        @extend .flex-wrap;

        width: 60%;
        justify-content: space-around;
        margin: 1vh 0;

        %language-flag-base {
            position: relative;
            object-fit: cover;
        }

        $flag-width: 60px;
        $flag-height: 35px;
        $selected-border-width: 3px;
        
        .language-flag {
            @extend %language-flag-base;
            width: $flag-width;
            height: $flag-height;

            &-selected {
                @extend %language-flag-base;

                width: $flag-width;
                height: $flag-height;
                border: solid $selected-border-width var(--dashboard-main-color);
            }
        }
    }

    &-id {
        position: relative;
        font-style: italic;
        width: 100%;
        text-align: center;
        color: gray;
        padding: 2vh 0;

        transition: all .5s ease-in-out;

        &:hover {
            .sidebar-id-placeholder {
                display: none;
            }

            .sidebar-id-element {
                display: block;
            }
        }

        .sidebar-id-copied {
            animation: in-n-out 2s normal;
        }

        .sidebar-id-placeholder {
            display: block;
        }

        .sidebar-id-element {
            display: none;
        }
    }

    @media screen and (max-width: 900px) {
        display: none;
    }
}

@keyframes in-n-out {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    70% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}