@import '/src/index.scss';

#consent-form {
    @extend .flex-column;

    position: relative;
    overflow: hidden;

    min-height: 100vh;

    #logo-container {
        position: absolute;
        z-index: 0;
        right: 0vh;
        top: -15rem;
        img {
            width: 23rem;
            @media screen and (max-width: 1000px) {
                display: none;
            }
        }
    }
    #form {
        @extend .flex-column;
        position: relative;

        width: 90%;
        min-height: 90vh;
        z-index: 1;

        #title {
            color: var(--dashboard-main-color);
            font-size: 40px;
            font-weight: 700;
        }
        .sub-title {
            font-weight: 600;
            font-size: 18px;
            margin: 0px 0px 1vh 0px;
        }
        .label-input {
            color: var(--light-gray);
            margin-bottom: 1vh;
        }
        #date-container {
            width: 25%;
            justify-content: space-between;
        }
        .adress-container {
            width: 75%;
            justify-content: space-between;
            &:first-child {
                margin-bottom: 3vh;
            }
        }
        #acknowledge-container {
            justify-content: flex-start;
            input {
                width: 60%;
                min-width: 25rem;
            }
        }
        #genetic-information {
            margin: 1vh 0vw 0vh 0vw;
            color: var(--light-gray);
            font-size: 18px;
            width: 70%;
        }

        #checkbox-container {
            margin-bottom: 2vh;
            .checkbox-section {
                margin-bottom: 1.5vh;
                align-items: center;
                input {
                    margin-right: 1vw;
                }
                span {
                    &:hover {
                        cursor: pointer;
                    }
                }
                &:nth-child(3) {
                    margin-bottom: 2vh;
                }
            }
        }
        #button-sign-container {
            align-items: flex-start;
            margin-bottom: 10vh;
            align-items: center;
        }
        #done-container {
            width: 40%;
            margin-bottom: 8vh;
            align-items: flex-end;
            justify-content: space-between;
            #disabled-date {
                background-color: white;
                color: var(--dashboard-main-color);
                font-family: MavenPro-bold;
                width: 12.5rem;
            }
        }
    }
}


#back-container {
    left: 0;
    top: 0;
    // margin-top: 8vh;
    #icon {
        width: 2rem;
    }

    @media screen and (max-width: 1000px) {
        margin-top: 8vw;
    }
}

.form-section {
    @extend .flex-row;
    @extend .align-items-stretch;
    @extend .flex-gap-col;
    @extend .full-width;

    margin-bottom: 4vh;

    @media screen and (max-width: 1000px) {
        flex-wrap: wrap;
    }
}