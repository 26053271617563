#address-information {
    #title-wrapper {
        justify-content: space-between;
        align-items: center;
    }
    #address-information-wrapper {
        margin: 3vh 0vw 3vh 0vw;
        #row-list-information {
            flex-wrap: wrap;
            margin: 2vh 0vw 0vh 0vw;
            .information-wrapper {
                margin: 0vh 8% 1vh 0vw;
            }
        }
    }
}
