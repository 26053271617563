@import "/src/index.scss";

#messaging {
    align-items: center;
    height: 100%;
}

#messaging-wrapper {
    @extend .flex-row;
    @extend .align-items-stretch;
    
    height: 80vh;
}

#last-messages-container {
    min-width: 35%;
    max-width: 35%;
}

#chat-container {
    flex-grow: 1;
    margin: 0vh 0rem 0vh 1rem;
}
