#performance-articles-header {
    justify-content: center;
    align-items: center;
    margin-bottom: 4vh;
    #performance-articles-header-wrapper {
        width: 90%;
        #go-back-container {
            align-items: center;
            margin: 5vh 0vw 7vh 0vw;
            align-self: flex-start;
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
            #label-go-back {
                color: var(--blue-performance);
                margin-left: 1rem;
            }
        }

        #title-wrapper {
            align-self: flex-start;
            padding-right: 6vw;
            border-bottom: solid 2vh var(--blue-performance);
            margin-left: 2vw;
            #title {
                margin: 0px 0px 1.5vh 0px;
                font-size: 30px;
                text-transform: uppercase;
                @media screen and (max-width: 700px) {
                    font-size: 20px;
                }
            }
        }
    }
}
