#qa {
    border-bottom: solid 2px var(--dashboard-main-color);
    padding-bottom: 2vh;
    margin-bottom: 5vh;
    width: 100%;

    .question {
        text-transform: uppercase;
    }
}
