#request-reset {
    flex-grow: 1;
    align-items: center;
    justify-content: center;

    #content {
        width: 50%;
        max-width: 50rem;
        min-width: 40rem;
        border-radius: 20px;
        border: solid 1px var(--light-gray2);
        padding: 2rem;
        margin: 3vw;

        #icon-back {
            align-self: flex-start;
            width: 2rem;
            margin-bottom: 1vh;
            &:hover {
                cursor: pointer;
            }
        }
        #input-container {
            width: 100%;
            min-width: 15rem;
        }
        #success-request-sent {
            padding: 2vw;
            width: 100%;
            border-radius: 20px;
            align-items: center;
            text-align: center;
            .success-request-sent-message {
                font-size: 18px;
                color: var(--dark-gray);
            }
            .success-request-sent-message-color {
                color: var(--dashboard-main-color);
            }
            #icon-success {
                margin-bottom: 3vh;
            }
            p {
                margin: 0vh 0vw 2vh 0vw;
            }
            .big-text {
                font-size: 22px;
            }
            .small-text {
                font-size: 15px;
                margin-bottom: 6vh;
            }
            #button-log-wrapper {
                background-color: var(--dark-gray);
                color: white;
                margin-top: 4vh;
                padding: 2vh 2vw 2vh 2vw;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        @media screen and (max-width: 700px) {
            #title {
                font-size: 20px;
            }
        }
    }
}

#request-reset-title {
    font-size: 42px;
    margin: 1vh 0vw 0vh 0vw;
    color: var(--gray);
}
#request-reset-sub-title {
    font-size: 18px;
    color: var(--gray);
    margin: 2vh 0vw 2.5vh 0vw;
}