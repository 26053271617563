$sectionBackground: #ffffff;
$buttonBaseColor: #b4b6b7;

.scienceButtonsMenuWrapper {
    position: relative;
    padding: 8vh 10vw;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

    &-buttonWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        position: relative;
        text-decoration: none;
        background: $buttonBaseColor;
        padding: 3px;
        width: 15vw;
        min-width: 180px;

        border-radius: 30px;
        white-space: nowrap;

        transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

        &>div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: $sectionBackground;
            border-radius: 30px;

            width: 100%;

            text-align: center;
            align-items: center;

            font-size: 16px;
            text-transform: uppercase;

            color: $buttonBaseColor;

            transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

            &>p {
                margin: 6px 0;
            }
        }

        &:hover {
            cursor: pointer;
            background: var(--gradient) !important;

            &>div {
                &>p {
                    background: var(--gradient);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }

    @media screen and (max-width: 990px) {
        flex-wrap: wrap;

        &-buttonWrapper {
            margin: 5px 10px;
        }
    }
}