#internal-doctors-note {
    .internalNote {
        background-color: var(--light-gray2);
        padding: 1.2vh 0.5vw;
        margin: 10px 0;

        p {
            margin: 0;
            padding: 0;
        }

        .text {
            &:hover {
                cursor: pointer;
                text-decoration: underline;  
            }
        }

        .createdAt {
            font-style: italic;
        }

        .buttons {
            width: 100%;

            .div {
                width: 40%;
            }
        }
    }

}
