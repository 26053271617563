.chart-caption-wrapper {
    position: relative;
    padding: 5px 0 0 0px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-height: 80%;
    overflow-y: scroll;

    &-horizontal {
        position: relative;
        padding: 5px 0 0 0px;

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        max-height: 80%;
        overflow-y: scroll;
    }

    &-item {
        transition: all .2s ease-in-out;

        &>div {
            width: 14px;
            height: 14px;

            border: 1px solid #000000;
            border-radius: 50%;
            transition: all .2s ease-in-out;
        }

        &>p {
            display: inline-block;
            margin: 4px 0 4px 5px;
            transition: all .2s ease-in-out;
        }

        &-hovered {
            transition: all .2s ease-in-out;

            &>div {
                width: 14px;
                height: 14px;
    
                border: 1px solid #000000;
                border-radius: 50%;
                transition: all .2s ease-in-out;
            }
    
            &>p {
                display: inline-block;
                font-weight: bold;
                margin: 5px 0 5px 5px;
                transition: all .2s ease-in-out;
            }
        }
    }
}