#claim-kit-user-information {
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    .label-input {
        margin-bottom: 0.5vh;
        font-family: MavenPro-Bold;
    }

    #button-next {
        background-color: var(--dashboard-main-color);
    }

    #form-container {
        width: 70%;
        .row-input {
            justify-content: space-between;
            margin-bottom: 4vh;
            .input-container {
                width: 40%;
                .icon-check {
                    position: absolute;
                    right: 0px;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                }
            }
            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                text-indent: 1px;
                text-overflow: "";
            }
        }
    }
}
