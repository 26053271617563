#next-appointment-wrapper {
    justify-content: space-between;
    align-items: center;

    #doctor-wrapper {
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        flex-wrap: wrap;
        #doctor-content-wrapper {
            margin: 2vh 0vw 2vh 0vw;
            align-items: center;
            #doctor-img-wrapper {
                padding: 2px;
                border-radius: 100%;
                min-width: 4em;
                min-height: 4em;
                width: 4em;
                height: 4em;
                #doctor-img {
                    border-radius: 100%;
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
            #doctor-info-wrapper {
                margin-left: 2vw;
                #doctor-name {
                    margin: 0vh 0vw 0.5vh 0vw;
                    font-size: 20px;
                }
                #appointment-date {
                    margin: 0px;
                    font-size: 18px;
                    color: var(--light-gray);
                }
            }
        }
    }
    #calendar-wrapper {
    }
}
#appointment-controls-wrapper {
    justify-content: center;
    padding: 3vh 0vw 0vh 0vw;
    .control-wrapper {
        align-items: center;
        margin: 0vh 2rem 0vh 2rem;
        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
        .icon-control {
            margin-right: 0.5rem;
        }
        .control-label {
            font-size: 16px;
            text-transform: uppercase;
        }
    }
    #reschedule {
        color: var(--dashboard-main-color);
    }
    #cancel {
        color: var(--red);
    }
}

#btn-schedule-appointment {
    align-self: center;
    background-color: var(--dashboard-main-color);
    padding: 1vh 2rem 1vh 2rem;
    border-radius: 10px;
    margin-top: 3vh;
    cursor: pointer;
    span {
        color: white;
    }
}
