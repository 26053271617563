$sectionBackground: #ffffff;

.microbiotaStateWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    transition: all 0.5s ease-in-out;

    font-size: 24px;

    padding: 5vh 10vw;

    &-detail {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        transition: inherit;

        &-left {
            position: relative;
            width: 40%;
            transform: translateY(-12%);
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            transition: inherit;
            
            &>img {
                position: relative;
                margin-right: 0;
                margin-left: auto;
                max-width: 90%;
                height: 90%;
                opacity: 0.9;
                object-fit: cover;
                object-position: 80% 0;
            }

            &-text {
                position: absolute;
                right: 0;
                bottom: 0;
                transform: translate(10%, -10%);

                width: 180px;
                height: 180px;
                background: linear-gradient(to right, rgba(0, 153, 255, 0.8) 0%, rgba(120, 201, 57, 0.8) 100%);
                color: $sectionBackground;
                font-size: 34px;
                text-align: left;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &>p {
                    font-size: 38px;
                    line-height: 3vh;
                    padding-left: 1vw;
                }
            }
        }

        &-desc {
            width: 60%;
            padding: 1vh 2vw;
            transition: inherit;
            color: var(--gray);

            &-preList {
                font-size: 18px;
            }

            &-list {
                position: relative;
                padding: 0 4vw 2vh 4vw;
                text-align: start;
                display: block;
        
                &-item {
                    display: flex;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    justify-content: center;
                    align-items: flex-start;
        
                    &:not(:last-child) {
                        margin-bottom: 2vh;
                    }
        
                    &>div {
                        position: relative;
                        transform: translateY(45%);
                        width: 10px !important;
                        height: 10px !important;
                        background: var(--gradient);
                        border: 0;
                        border-radius: 100%;
                        content: "";
        
                        margin: 2px 10px 0 0;
                    }
        
                    &>p {
                        margin: 0;
                        width: 95%;
                        font-weight: lighter;
                        font-size: 18px;
        
                        span {
                            text-transform: uppercase;
                            font-weight: bolder;
                        }
                    }
                }
            }
        }
    }

    &-button {
        margin: 4vh 0;

        :first-child {
            background-color: $sectionBackground;
        }

        &:hover {
            cursor: pointer;
    
            :first-child {
                background-color: rgba($color: #000000, $alpha: 0.0);
    
                :first-child {
                    background: $sectionBackground;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }

    @media screen and (max-width: 910px) {
        &-detail {
            justify-content: center;
            align-items: center;

            &-left {
                position: absolute;
                display: none;
            }

            &-desc {
                width: 90%;
            }
        }
    }

    @media screen and (max-width: 740px) {
        &-detail {
            &-desc {
                width: 95%;
                padding: 0;
            }
        }
    }
}