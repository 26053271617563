#order-detail {
    align-items: center;
}

.container {
    border: solid 1px var(--light-gray2);
    border-radius: 10px;
    padding: 1vw;
    margin: 1%;
}

#button-return-container {
    width: 95%;
    align-items: flex-start;
    padding: 1vw 0vw 0vw 1vw;
    #icon-back{
        &:hover{
            cursor: pointer;
            opacity: .7;
        }
    }
}

#top-container {
    width: 95%;
}

#order-details-container {

    #product-container {
        #product-info-left {
            width: 60%;
            border-radius: 10px;
            background-color: white;
            margin-right: 2vw;

            #product-ref-container {
                border-bottom: solid 1px var(--gray);
                padding-left: 1vw;
                padding-right: 1vw;
            }
            #product-img-container {
                align-items: center;

                img {
                    width: 8vw;
                    height: 8vw;
                    border-radius: 10px;
                    margin: 1vw;
                    padding: 1vw;
                    border: 4px solid var(--dashboard-main-color);
                }
                #img-desc {
                    align-self: center;
                }
            }
        }

        #product-info-right {
            padding: 1vw;
            width: 40%;
            border-radius: 10px;
            background-color: white;

            .information {
                justify-content: space-between;
                padding-bottom: 1.5vh;
                margin-bottom: 2vh;
                align-items: center;

                .information-bold{
                    font-weight: 600;
                }

                &:not(:last-child) {
                    border-bottom: solid 1px var(--gray);
                }
            }
        }
    }
}
