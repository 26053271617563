#chemical-reactions {
    justify-content: center;
    align-items: center;
    margin-bottom: 15vh;
    flex-wrap: wrap;
    position: relative;
    #image-wrapper {
        flex-shrink: 1;
        margin: 0vh 1rem 0vh 1rem;

        #tennis-image {
            width: 25vw;
            min-width: 18rem;
            object-fit: cover;
        }
        @media screen and (max-width: 500px) {
            // justify-content: center;
            #tennis-image {
                // width: 90%;
            }
        }
    }

    #text-wrapper {
        max-width: 50rem;
        flex: 1 1 0;
        min-width: 18rem;
        padding: 0vh 2rem 0vh 2rem;
        font-size: 20px;
        z-index: 1;
        @media screen and (max-width: 1000px) {
            font-size: 18px;
        }
        @media screen and (max-width: 500px) {
            font-size: 16px;
        }

        #text-title-list {
            margin: 0vh 0px 1vh 0px;
        }
        #main-text {
            margin: 3vh 0px 10vh 0px;
            p {
                margin: 0px;
            }
        }
        ul {
            margin: 0px;
        }
        li {
            color: var(--blue-performance);
            margin-bottom: 1.5vh;
            span {
                color: black;
            }
        }
    }

    #logo-bodycheckup {
        position: absolute;
        bottom: -15vh;
        right: 0px;
        width: 20vw;
        @media screen and (max-width: 700px) {
            display: none;
        }
    }
}
