#dashboard-default-button {
    color: white;
    padding: 0.25vh 2vw 0.25vh 2vw;
    border-radius: 30px;
    font-family: Montserrat-Bold;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 13px;
    min-width: 6rem;

    @media screen and (max-width: 600px) {
        font-size: 11px;
    }

    &:hover {
        opacity: 0.7;
    }
}
.button-default {
    border: solid 3px var(--light-gray);
    background-color: var(--light-gray);
}

.button-download {
    border: solid 3px var(--dashboard-main-color);
    background-color: var(--dashboard-main-color);
}
.button-disabled {
    opacity: 0.7;
    cursor: not-allowed;
}
.button-enabled {
    opacity: 1;
    cursor: pointer;
}
