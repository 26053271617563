.flex {
    display: flex;
}

.flex-end {
    justify-content: flex-end;
}

.column {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}

.flex {
    &-wrap {
        flex-wrap: wrap;
    }

    &-grow {
        flex-grow: 1;
    }

    &-gap {
        &-col {
            column-gap: 1vw;
        }
        &-row {
            row-gap: 1vh;
        }
    }
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &-start {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    &-end {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    &-reverse {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
    }
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-start {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    &-reverse {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
}