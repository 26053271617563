#informations-medicals {
    width: 100%;

    .col-data {
        margin-top: 2vh;

        div:not(:first-child) {
            margin-top: 2vh;
        }
    }
}
