.bannerWrapper {
    position: relative;

    &-img {
        position: relative;
        width: 100%;
        height: 35vw;
        max-height: 70vh;
        min-height: 30vh;
        object-fit: cover;
        object-position: 0 0;
        pointer-events: none;
    }

    &-content {
        position: absolute;
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);

        &-logo {
            height: 70%;
        }

        &-title {
            width: 524px;

            &-sub {
                font-size: 2.3em;
                margin: 0;
            }

            &-main {
                font-size: 7em;
                margin: 0;
                width: min-content;
            }
        }
    }

    hr {
        width: 60%;
        height: 20px;
        border: 0;
        background: var(--gradient);
        position: absolute;
        z-index: 2;
        margin: 0;

        left: 50%;
        bottom: 0;
        transform: translate(-50%, 45%);
    }

    @media screen and (max-width: 1482px) {
        &-content {
            width: 738px;
        }
    }

    @media screen and (max-width: 1125px) {
        &-content {
            width: 90%;
            justify-content: flex-start;

            &-logo {
                margin-right:2vw;
            }

            &-title {
                width: 70%;
                &-sub {
                    font-size: calc(12px + 1.5vw);
                    
                }

                &-main {
                    font-size: calc(28px + 1.5vw);
                }
            }
        }
    }

    @media screen and (max-width: 740px) {
        &-img {
            object-position: 50% 0;
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            padding: 2vh 0 0 0;

            &-logo {
                height: 60%;
            }

            &-title {
                margin-left: 15px;
                margin-bottom: 15px;
            }
        }

        hr {
            height: 10px;
        }
    }
}