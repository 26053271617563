$sectionBackground: #ffffff;

#processStepsWrapper {
    background-color: $sectionBackground;
    position: relative;
    padding: 15vh 4vw;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .processStepsWrapper-titleWrapper {
        width: 260px;
        .processStepsTitle {
            font-size: 50px !important;
            margin: 0;
            text-align: left;
            width: max-content;
        }

        .processStepsSubtitle {
            font-size: 18px !important;
            font-weight: lighter;
            margin: 0;
            color: var(--gray);
            text-align: right;
        }
    }

    .stepperWrapper {
        position: relative;
        width: 70vw;
        height: auto;

        ol {
            margin: 0;
            padding: 0;
        }

        .stepper {

            .stepperItem {
                position: relative;
                display: flex;
                flex-direction: row;
                gap: 1.5rem;
                text-align: start;

                &:not(:last-child) {
                    padding-bottom: 6vh;
                }

                &:nth-child(1) {
                    .stepperItemWrapper {
                        &>div {
                            background-color: #75c045;
                        }
                    }
                }
                &:nth-child(2) .stepperItemWrapper {
                    &>div {
                        background-color: #04b59a;
                    }
                }
                &:nth-child(3) .stepperItemWrapper {
                    &>div {
                        background-color: #00aee3;
                    }
                }

                &-title {
                    margin: 0;
                }

                &-desc {
                    margin: 0;
                    line-height: 1.5em;
                }

                &-content {
                    order: 1;
                    justify-content: center;
                }

                .stepperItemWrapper {
                    position: relative;

                    &>hr {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 40%;
                        height: 15px;
                        margin: 0;

                        border: 0;
                        // cannot use global var(--gradient) needed to add transparency
                        background: linear-gradient(to right, #0099ffd3 0%, #78C939d3 100%);
                    }

                    &>div {
                        position: absolute;
                        right: 5%;
                        top: 50%;
                        width: 35px;
                        height: 35px;
                        transform: translateY(-50%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        color: white;
                        border-radius: 100%;
                    }

                    &-img {
                        height: 175px;
                    }
                }
            }
        }

        @media screen and (max-width: 1313px) {
            .stepper {
                .stepperItem {
                    display: flex;
                    flex-direction: column;

                    &:first-child {
                        margin-top: 40px;
                    }

                    .stepperItemWrapper {
                        &-img {
                            width: 100%;
                            height: auto;
                            max-height: 200px;

                            object-fit: cover;
                            object-position: 0 50%;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 758px) {
            .stepper {
                padding: 0;
                
                .stepperItem {
                    .stepperItemWrapper {
                        &>hr {
                            height: 10px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 530px) {
        padding: 6vh 0vw;

        .stepperWrapper {
            width: 100%;

            .stepper {
                .stepperItem {
                    &-content {
                        padding: 0 4vw;
                    }
                }
            }
        }
    }
}

.processStepsWrapper-btn {
    margin: 4vh 0;

    :first-child {
        background-color: $sectionBackground;
    }

    &:hover {
        cursor: pointer;

        :first-child {
            background-color: rgba($color: #000000, $alpha: 0.0);

            :first-child {
                background: $sectionBackground;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
}