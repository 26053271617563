#markers {
    align-items: center;
    #header-markers {
        width: 70%;
        margin-bottom: 5%;
        margin-top: 2%;
        text-align: center;

        #title {
            font-size: 36px;
        }
        #sub-title {
            color: var(--light-gray);
        }
    }

    #filter-markers {
        width: 90%;
        margin-bottom: 5%;
        align-items: center;
        justify-content: space-between;
        height: 7vh;

        #input-container {
            width: 30vw;
            border-radius: 30px;
            border: none;
            background-color: #ededed;
            padding: 0% 2% 0% 2%;
            align-items: center;
            height: 100%;

            #search-bar {
                font-size: 14px;
                width: 90%;
                border: none;
                background-color: transparent;
                padding: 2%;
                font-family: Montserrat;
            }
            #input-icon {
                margin-left: 3%;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        #tests-container {
            width: 50vw;
            justify-content: space-between;
            height: 100%;

            .test {
                background-color: var(--light-gray2);
                padding: 0% 4% 0% 1%;
                border-radius: 30px;
                align-items: center;
                width: 9vw;
                border: solid 1px transparent;
                &:hover {
                    cursor: pointer;
                }
                .test-name {
                    margin-left: 1.75vw;
                    font-size: 14px;
                }
            }
            .test-selected {
                border: solid 1px var(--light-gray);
            }
        }
    }

    #list-markers {
        width: 90%;
        margin-bottom: 5%;
        border-top: solid 1px var(--light-gray2);
        .marker {
            // border-top: solid 1px var(--light-gray2);
            border-bottom: solid 1px var(--light-gray2);
            padding: 2% 2% 2% 2%;
            align-items: center;
            justify-content: space-between;
            .marker-test {
                align-items: center;
                .name {
                    margin-left: 2vw;
                }
            }
            .marker-view-more {
                align-items: center;
                &:hover {
                    cursor: pointer;
                }
                .name {
                    margin-right: 1vw;
                }
            }
            .marker-name {
                width: 6vw;
            }
        }
        .marker-details {
            width: 100%;
            align-self: center;
            background-color: var(--light-gray3);
            border-bottom: solid 1px var(--light-gray2);
            line-height: 1.5rem;
            align-items: center;
            .description {
                width: 90%;
            }
        }
    }
}
