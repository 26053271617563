$sectionBackgroundColor: #ffffff;

.appointmentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12vh 0 20vh 0;

    &>div {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &>p {
            max-width: 620px;
            font-size: 24px;
            text-align: center;
            margin-bottom: 6vh;
            line-height: 1.3em;
        }

        .buttonWrapper {
            position: relative;
            background: var(--gradient);

            display: flex;
            justify-content: center;
            align-items: center;

            padding: 3px;
            border-radius: 30px;
            overflow: hidden;
            white-space: nowrap;
            transition: all 100ms linear;

            &>div {
                background-color: $sectionBackgroundColor;
                border-radius: 30px;

                padding: 0vh 2vw;

                display: flex;
                justify-content: center;
                align-items: center;

                text-align: center;
                align-items: center;
                color: var(--gradient);
                font-size: 16px;
                transition: all 0.1s linear;

                &>p {
                    margin: 1vh 0;
                }
            }

            &:hover {
                cursor: pointer;
                color: $sectionBackgroundColor !important;

                &>div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--gradient);
                    border-radius: 30px;

                    text-align: center;
                    align-items: center;
                    transition: all 0.1s linear;
                    &>p {
                        background: $sectionBackgroundColor;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 800px) {
        &>div {
            width: auto;
            padding: 0 85px;
        }
    }
}