@import "/src/index.scss";

#dashboard-header {
    @extend .flex-row;

    width: 100%;
    height: 10vh;
    #title-container {
        @extend .flex-row-reverse;
        @extend .space-between;
        @extend .full-width;

        align-items: center;

        #title {
            font-size: 32px;
            @media screen and (max-width: 600px) {
                font-size: 24px;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    #dashboard-header {
        #title-container {
            &>button {
                margin-left: 8vw;
            }
        }
    }
}
