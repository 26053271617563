@import '/src/index.scss';

$main-color: var(--dashboard-main-color);
$disabled-color: #8b8b8b;

.page-wrapper {
    width: auto;
    min-height: 50vh;
    padding: 10vh 5vw;

    hr {
        border: 1px solid $main-color;
    }

    .order-dashboard {
        @extend .shadow;

        width: 100%;
        background-color: white;
        position: relative;
        margin-bottom: 8vh;
        flex: 1 1 0px;
        padding: 0 0;

        button {
            margin: 5px;
        }

        &>form {
            width: auto !important;
            padding: 2rem;
        }

        &-banner {
            position: sticky;
            width: 100%;
            max-height: 200px;

            &>img {
                width: 100%;
                max-height: 200px;
                position: relative;
                
                overflow: hidden;
                top: 0;
                left: 0;

                object-fit: cover;
                object-position: 50% 25%;
            }

            &>h2 {
                margin: 0;
                position: absolute;
                top: 50%;
                right: 25%;
                transform: translateX(50%) translateY(-45%);
                color: var(--dashboard-main-color);
                font-size: 2.5em;
                hyphens: none;
            }

            &>hr {
                position: absolute;
                height: 16px;
                border: 0;
                background: var(--gradient);
                bottom: 0;
                margin-left: 50%;
                transform: translate(-50%, 100%);
                width: 25%;
            }
        }
    }
}

.fit-content {
    width: fit-content;
}

%baseKitContainer {
    @extend .flex-row;
    @extend .flex-wrap;

    border-width: 2px;
    border-style: solid;
    border-radius: 8px;

    &>div {
        max-width: 400px
    }

    &:hover {
        cursor: pointer;
    }
}

.order-form-wrapper {
    width: 100%;

    &>label {
        font-size: 1.2em;
    }

    .not-last {
        margin-bottom: 1vh;
    }

    &-kit {
        @extend %baseKitContainer;

        border-color: var(--light-gray);

        &-selected {
            @extend %baseKitContainer;

            border-width: 3px;
            border-color: var(--dashboard-main-color);
        }
    }

    &-receip {
        margin: 25px 0;

        &-title {
            margin-bottom: 0;
        }

        &-data {
            flex-wrap: wrap;
            justify-content: space-between;

            &-price {
                margin: 0 !important;
            }

            &-conditions {
                &>div {
                    &>p {
                        margin: 5px;
                    }
                }
            }
        }
    }
}

.color-contour {
    margin: 25px 0;
    position: relative;
    border: 2px solid $main-color;
    border-radius: 15px;
    padding: 30px 30px;

    p {
        margin: 0;
    }

    &-bot {
        position: relative;
        display: flex;
        justify-content: center;
        bottom: 0;

        &-btn {
            padding: 1vh 3vw;
            border: 0;
            border-radius: 30px;
            background-color: $main-color;
            color: white;
            position: absolute;
            top: .6vh;
            margin-left: auto;
            margin-right: auto;
            transition: all .2s ease-in-out;
    
            &:hover {
                cursor: pointer;
                transform: scale(1.1);
            }

            &:disabled {
                background-color: $disabled-color;

                &:hover {
                    cursor: not-allowed;
                    transform: scale(1);
                }
            }
        }
    }

    &-input {
        margin: 15px;
        position: relative;
        border: 2px solid $main-color;
        border-radius: 15px;
        padding: 10px 15px;

        text-align: right;
        -webkit-appearance: none;
        -moz-appearance: textfield;
    }
}

.payment-method-handler {
    &>:first-child {
        margin-right: 15px;
    }
}