#allMyAppointments-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;

    padding: 20px;
}

.appointments-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    flex-grow: 1;

    max-height: 310px;
    margin: 0 0 0 20px;
    border-radius: 10px;
    overflow-y: auto;

    &>div {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        column-gap: 1vw;

        box-sizing: border-box;
        padding: 0 20px;
        background-color: var(--light-gray2);

        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
}