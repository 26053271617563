#our-solution {
    align-items: center;
    padding: 10vh 0vw 20vh 0vw;
    #our-solution-header {
        width: auto;
        padding-left: 15vw;
        margin-bottom: 15vh;
        color: var(--dark-gray);
        #title {
            white-space: pre-wrap;
            font-size: 48px;
            margin: 1vh 0vw 4vh 0vw;
        }
        #pre-title {
            text-transform: uppercase;
            margin: 0px;
        }
        #desc {
            width: 40%;
            min-width: 20rem;
            line-height: 2rem;
        }
    }
    #solutions-container {
        width: 100%;
        align-items: center;
        margin-bottom: 15vh;
        .solution-wrapper {
            margin-bottom: 10vh;
            position: relative;
            width: 50%;
            height: 100vh;
            .solution-content {
                position: absolute;
                color: var(--dark-gray);
                height: 100%;
                width: 100%;
                justify-content: space-between;
                .solution-title {
                    text-transform: uppercase;
                    margin-top: 10%;
                }
                .text-margin-left {
                    margin-left: 3vw;
                }
                .solution-desc {
                    width: 40%;
                    line-height: 2rem;
                    white-space: pre-line;
                    min-width: 15rem;
                }
                .solution-add-info-container {
                    align-self: flex-start;
                    background-color: white;
                    border-radius: 0px 20px 20px 0px;
                    padding: 2vh 2vw 2vh 0vw;
                    margin-bottom: 5vh;
                    .solution-add-info-text {
                        font-size: 22px;
                    }
                }
                .solution-button {
                    align-self: center;
                    margin-bottom: 5vh;
                    padding: 1vh 2vw 1vh 2vw;
                    border-radius: 20px;
                    .button-label {
                        color: white;
                        font-size: 20px;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .solution-background {
                top: 0px;
                object-fit: cover;
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }
    }
    #schedule-appointment-container {
        text-transform: uppercase;
        align-items: center;
        width: 50%;
        text-align: center;
        #text {
            font-size: 24px;
            color: var(--dark-gray);
            line-height: 2rem;
            margin: 0vh 0vw 3vh 0vw;
        }
        #button-contact-us {
            padding: 2vh 3vw 2vh 3vw;
            border-radius: 30px;
            #label-button {
                color: white;
                font-size: 18px;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
}
