.question-container {
    align-items: center;
    justify-content: space-between;
    background-color: var(--background-dashboard-color);
    margin: 1vh 0vw 1vh 0vw;
    &:hover {
        cursor: pointer;
    }
    .icon-faq {
        padding: 0vh 3vw 0vh 0vw;
    }
    .question-wrapper {
        padding: 0vh 2vw 0vh 2vw;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        .question {
            padding: 0vh 0vw 0vh 3vw;
        }
    }
    .answer-wrapper {
        width: 100%;
        padding: 1vh 2vw;
        box-sizing: border-box;
        background-color: white;
        .answer {
            padding: 0vh 1vw 0vh 1vw;
            font-size: 15px;
        }
    }
}
