#next-appointemnt {
    
}

.css-vnkopk-MuiStepLabel-iconContainer {
    padding: 0px;
}

.step-title {
    cursor: pointer;
    position: absolute;
    top: -6vh;
    left: 50%;
    transform: translate(-50%, 0);
}

.edit-icon {
    width: 1.6rem;
    
    &:hover {
        cursor: pointer;
    }
}