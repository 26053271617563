.food {
    align-items: center;
    flex: 0 0 50%;
    min-width: 25rem;
    margin: 2vh 0;
    justify-content: space-evenly;

    .food-img-wrapper {
        width: 5rem;
        height: 5rem;
        border-radius: 100%;
        padding: 0.20rem;
        .food-img {
            background-color: white;
            border-radius: 100%;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .food-info-wrapper {
        width: 40%;
        .food-name {
            text-transform: uppercase;
            margin-bottom: 0.75vh;
        }
        .food-category {
            font-size: 15px;
        }
        .food-desc {
            font-size: 14px;
        }
    }
}
