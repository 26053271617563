#about-us {
    margin-top: 3%;

    align-items: center;

    #header-abouts-us {
        align-items: center;
        margin-bottom: 3%;

        #header-text {
            width: 50%;

            #title {
                font-size: 40px;
            }
            #sub-title {
                color: var(--light-gray);
                line-height: 2rem;
            }
        }
        #header-img {
            width: 50%;
            align-items: center;
            img {
                width: 60%;
            }
        }
    }

    #cards-container {
        width: 90%;
        position: relative;
        margin-bottom: 10%;

        #cards-top {
            width: 100%;
            justify-content: space-between;
            margin-bottom: 4%;
            .card {
                width: 48%;
            }
            .card-text-container {
                position: absolute;
                width: 40%;
            }
        }
        #cards-bottom {
            justify-content: space-between;
            .card {
                width: 48%;
                border-radius: 10px;
                padding-bottom: 2%;
                &:nth-child(1) {
                    background-color: var(--light-red);
                }
                &:nth-child(2) {
                    background-color: var(--yellow);
                }
            }
            .card-text-container {
                width: 80%;
            }
        }
        .card-text-container {
            margin: 2vw 0vw 0vw 2vw;
        }
        .card-title {
            font-size: 1.75vw;
        }
        .card-description {
            line-height: 2.5vw;
        }
    }

    #health-intelligence {
        width: 90%;
        margin-bottom: 10%;

        #left-side {
            width: 50%;
            img:first-child {
                width: 30%;
                margin-bottom: 10%;
            }
            img:nth-child(2) {
                width: 70%;
                margin-bottom: 5%;
            }
            img {
                align-self: center;
            }
            #words-list {
                width: 70%;
                align-self: center;
                margin-left: 3vw;
                p {
                    font-size: 1.5vw;
                    color: #555555;
                    opacity: 0.2;
                    font-family: Montserrat-bold;
                }
            }
        }
        #right-side {
            width: 50%;
            justify-content: space-between;
            #header-text {
                color: var(--purple);
                font-size: 1.5vw;
            }
            #title {
                font-size: 3vw;
                color: var(--gray);
            }
            #description {
                line-height: 2.5vw;
                color: var(--light-gray);
            }
            img {
                width: 80%;
                align-self: center;
            }
        }
    }

    #maison-medicale-container {
        width: 90%;
        align-items: center;
        margin-bottom: 10%;

        #header-text {
            text-align: center;
            width: 60%;
            margin-bottom: 5%;
            #title {
                font-size: 3vw;
            }
            #description {
                line-height: 2vw;
                color: var(--light-gray);
            }
        }

        .circle {
            border: solid 1px var(--light-gray2);
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            img {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 10vw;
            }
            img:nth-child(1) {
                margin: 10% 0% 0% 10%;
            }
            img:nth-child(2) {
                margin: 30% 0% 0% 60%;                
            }
            img:nth-child(3) {
                margin: 70% 0% 0% 10%;                
            }
            img:nth-child(4) {
                margin: 80% 0% 0% 80%;                
            }
        }
        #circle-large {
            width: 30vw;
            height: 30vw;
            position: relative;
        }
        #circle-medium {
            width: 20vw;
            height: 20vw;
        }
        #circle-small {
            width: 10vw;
            height: 10vw;
        }
    }
}
