#current-prescription {
    // min-width: 20rem;
    #prescription-wrapper {
        background-color: var(--light-gray2);
        padding: 1.5vh 0.5rem 1.5vh 0.5rem;
        justify-content: space-between;
        align-items: center;
        margin: 2vh 0vw 2vh 0vw;
        #prescription-info {
            text-transform: uppercase;
            font-size: 14px;
        }
    }
}
