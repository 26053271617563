#claim-kit {
    .input {
        border: 0;
        border-bottom: 1px solid var(--gray);
        font-size: 16px;
        font-family: Montserrat;
        color: var(--gray);
        padding: 1vh 2rem 1vh 0.25rem;
        background-color: transparent;
    }

    .content {
        width: 100%;
        height: 100%;
        #button-next-container {
            align-items: center;
            margin-top: 2vh;
            #button-next {
                // background-color: var(--purple);
                color: white;

                font-weight: 600;
                padding: 2vh 2vw 2vh 2vw;
                border-radius: 5px;
            }
            .button-disabled {
                opacity: 0.7;
                pointer-events: none;
            }
            .button-enabled {
                &:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }
            }
        }

        #go-back-container {
            width: 100%;
            box-sizing: border-box;
            align-items: center;
            padding: 3vh 0.5vw 0vh 6vw;

            &>svg {
                margin-right: 1vw;
                &:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }
            }
            #steps-info {
                align-self: center;
                margin: 0px;
                font-weight: 600;
                color: var(--gray);
                font-size: 18px;
            }
        }
    }
}
