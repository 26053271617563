#doctor-special-information {
    #title-wrapper {
        justify-content: space-between;
        align-items: center;
    }

    .special-information-content {
        margin: 3vh 0vw 3vh 0vw;
        padding: 1vh 1vw 1vh 1vw;

        #special-information-placeholder {
            padding-top: 20px;
            padding-left: 10px;
            width: 100%;
            color: black;
            font-size: 15px;
            border: solid 1px var(--dashboard-main-color);
            min-height: 20vh;
            border-radius: 15px;
            resize: none;
        }
    }
}
