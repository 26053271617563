#current-test {
    align-items: center;
}

#title-wrapper {
    width: 100%;

    &>div {
        margin: 1vh 0 3vh 0;
    }

    .sub-title {
        color: var(--dashboard-main-color);
    }
    #title-line-separator {
        border: solid 1px;
        flex-grow: 1;
        height: 0px;
        margin: 0vh 1rem 0vh 1rem;
    }
}

#current-test-content-wrapper {
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;

    #stepper-wrapper {
        width: 60%;
        min-width: 20rem;
        margin-bottom: 2vh;
        .step {
            border-radius: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        .step-label {
            color: black;
            font-size: 14px;
            text-transform: uppercase;
            // white-space: nowrap;
        }
        .step-not-done {
            background-color: white;
            color: var(--dashboard-main-color);
            border: solid 2px white;
            font-size: 20px;
        }
        .step-done {
            border: solid 3px var(--dashboard-main-color);
            color: var(--dashboard-main-color);
        }
        .MuiStepConnector-line {
            border: solid 2px var(--dashboard-main-color);
        }
    }
    
    #doctor-info-wrapper {
        width: 40%;
        min-width: 20rem;
        margin-bottom: 2vh;
        #video-meeting-wrapper {
            width: 60%;
            align-self: center;
            align-items: center;
            #video-status {
                align-items: center;
                justify-content: center;
                background-color: var(--light-gray);
                text-transform: uppercase;
                border-radius: 20px;
                margin-bottom: 1vh;
                width: 100%;
                span {
                    padding: 0.75vh 0rem 0.5vh 0rem;
                    color: white;
                    font-size: 13px;
                }
            }
            #video-recommendations {
                text-align: center;
                font-size: 12px;
            }
        }
    }
}
