#download-app {
    align-items: center;
    margin-bottom: 12vh;
    #download-app-wrapper {
        width: 90%;
        align-items: center;
        #title {
            text-align: center;
            margin: 5vh 0vw 2vh 0vw;
            color: var(--dashboard-main-color);
            font-size: 28px;
            @media screen and (max-width: 1000px) {
                font-size: 22px;
            }
        }
        #app-overview {
            width: 26rem;
            margin-bottom: 2vh;
        }
        #app-coming-wrapper {
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            span {
                text-align: center;
                margin: 1vh 0vw 1vh 0vw;
                color: var(--dashboard-main-color);
                margin-right: 0.5rem;
                font-size: 18px;
            }
        }
    }
}
