#banner-contact-us {
  width: 100%;
  height: 60vh;
  background-color: var(--yellow);
  color: white;
  #contact-us-right {
    width: 50%;
    #img-team-working {
      height: 100%;
      width: 100%;
    }
  }
  #contact-us-left {
    width: 50%;
    align-items: center;
    justify-content: center;
    #contact-us-left-content {
      width: 30vw;
      h1 {
        width: 80%;
        margin: 0rem 0rem 3rem 0rem;
        font-size: 31px;
      }
      p {
        margin-bottom: 5vh;
      }
    }
  }
}