@import '/src/index.scss';

.doctor-result-progressbar-wrapper {
    @extend .flex-column;

    box-sizing: border-box;
    margin-right: 10px;

    &>svg {
        width: 70px;
        aspect-ratio: 1/1;
    }
}

.doctor-result-wrapper {
    @extend .flex-row-start;

    margin: 2vh 0vw 2vh 0vw;
    width: 47%;
    min-width: 25rem;

    &-detail {
        @extend .flex-column-start;
        
        &>span {
            flex-grow: 1;
        }
    }
}