.homeBannerWrapper {
    position: relative;
    z-index: var(--content-index);

    &-img {
        position: relative;
        width: 100%;
        height: 35vw;
        max-height: 70vh;
        min-height: 30vh;
        object-fit: cover;
        object-position: 0 0;
        pointer-events: none;
    }

    &-content {
        position: absolute;
        height: 100%;
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);

        &-logo {
            height: 70%;
        }

        &-title {
            position: relative;
            padding: 0 0 10vh 6vw;
            min-width: 572px;

            &-sub {
                position: relative;
                left: 0;
                font-size: 2.3em;
                margin: 0;
                width: 70%;
                line-height: 1.3em;
            }

            &-main {
                position: absolute;
                width: max-content;
                font-size: 6em;
                margin: 0;
                animation: fadeIn 0.8s cubic-bezier(0.77, 0.2, 0.05, 1) forwards;

                &-not {
                    position: absolute;
                    width: max-content;
                    font-size: 6em;
                    margin: 0;
                    animation: fadeOut 0.5s cubic-bezier(0.77, 0.2, 0.05, 1) forwards;
                }
            }
        }
    }

    hr {
        width: 60%;
        height: 20px;
        border: 0;
        background: var(--gradient);
        position: absolute;
        margin: 0;

        left: 50%;
        bottom: 0;
        transform: translate(-50%, 45%);
    }

    @media screen and (max-width: 1482px) {
        &-content {
            width: 80vw;
        }
    }

    @media screen and (max-width: 1125px) {
        &-content {
            width: 90vw;
            justify-content: flex-start;

            &-logo {
                margin-right: 2vw;
            }

            &-title {
                width: 90vw;
                min-width: auto;
                &-sub {
                    font-size: calc(12px + 1.5vw);
                }

                &-main {
                    font-size: calc(28px + 1.5vw);

                    &-not {
                        font-size: calc(28px + 1.5vw);
                    }
                }
            }
        }
    }

    @media screen and (max-width: 740px) {
        &-img {
            object-position: 50% 0;
        }

        &-content {
            &-title {
                padding: 0 0 10vh 2vw;
            }
        }

        hr {
            height: 10px;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        margin-top: 2vh;
    }

    to {
        opacity: 1;
        margin-top: 0vh;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
