#performance-header {
    min-height: 55vh;
    position: relative;
    margin-bottom: 12vh;
    #performance-header-wrapper {
        flex-grow: 1;
        margin-left: 8vw;
        justify-content: center;
        #logo-performance {
            width: 25vw;
            min-width: 15rem;
            margin-bottom: 4vh;
            z-index: 1;
        }
        #header-text {
            text-transform: uppercase;
            color: white;
            width: 40%;
            font-size: 26px;
            margin: 0px;
            z-index: 1;
            @media screen and (max-width: 1000px) {
                font-size: 20px;
                width: 60%;
            }
            @media screen and (max-width: 500px) {
                width: 90%;
            }
        }
        #header-image-background {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0px;
            left: 0px;
            object-fit: cover;
        }
        #blue-band {
            position: absolute;
            bottom: -7px;
            width: 80%;
            opacity: 0.7;
            z-index: 1;
            height: 2vh;
            background-color: var(--blue-performance);
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
