#sub-title {
    color: var(--dashboard-main-color);
}

#results-wrapper {
    margin: 2vh 0vw 1vh 0vw;
    flex-wrap: wrap;
    justify-content: space-between;

    .result-wrapper {
        padding: 1vh;
        margin: 2vh 0vw 2vh 0vw;
        width: 47%;
        min-width: 25rem;

        background-color: white;

        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 1vw;

        border-radius: 15px;

        transition: all .1s ease-in-out;

        &:hover {
            transform: scale(1.02);
            box-shadow: -5px 5px 20px 1px rgba(0, 0, 0, 0.12);
        }

        .result-info-wrapper {
            flex-grow: 1;

            .result-info-label {
                text-transform: uppercase;
                margin: 0px;
            }

            .result-info-desc {
                margin: 0px;
                width: 80%;
            }
        }

        .result-circular-wrapper {
            width: 5rem;
            height: 5rem;
            aspect-ratio: 1/1;
            justify-content: center;

            .CircularProgressbar-trail {
                stroke-width: 2px;
            }
        }
        
        .icon-show-details {
            width: 2rem;
            height: 2rem;
        }
    }
}

.result-caption-wrapper {
    width: 100%;
    // background-color: rebeccapurple;
    justify-content: space-between;
}

.result-caption {
    width: 30%;
    &-progressbar {
        width: 10%;
        height: auto;
        min-width: 2rem;
        min-height: 2rem;
        margin-right: 1vw;
    }

    &-detail {
        max-width: 85%;

        &>p {
            margin: 5px 0;
        }
    }
}

#scale-list-wrapper {
    width: 90%;
    align-self: center;
    justify-content: center;
    margin: 2vh 0vw 5vh 0vw;
    .scale-wrapper {
        width: 4rem;
        text-transform: uppercase;
        margin-right: 3vw;
        font-size: 15px;
        .CircularProgressbar-text {
            // dominant-baseline: middle;
            text-anchor: middle;
        }
    }
}
