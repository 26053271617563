$sectionBackground: #ffffff;

#home {
    position: relative;

    .titleWrapper {
        z-index: var(--content-index);
        margin: 11vh 0 15vh 0;
        position: relative;
        padding: 2vh 2vw;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        &-btn {
            margin: 4vh 0;
        
            :first-child {
                background-color: $sectionBackground;
            }
    
            &:hover {
                cursor: pointer;
        
                :first-child {
                    background-color: rgba($color: #000000, $alpha: 0.0);
        
                    :first-child {
                        background: $sectionBackground;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }

        &>h2 {
            max-width: 780px;
            font-weight: lighter;
            font-size: 2.2em;
            line-height: 1.3em;
        }

        $arrowDimensions: 60px;
        .arrowDown_icon {
            width: $arrowDimensions;
            height: $arrowDimensions;
        }
    }

    #box-img {
        z-index: var(--background-index);
        width: 70%;
        position: fixed;
        top: 45vh;
        align-self: center;
        opacity: 0.9;
    }

    @media screen and (max-width: 740px) {
        .titleWrapper {
            margin: 5vh 0 4vh 0;
            &>h2 {
                font-size: 1.4em;
            }
        }

        #box-img {
            display: none;
        }
    }
}
