#content-wrapper {

}

#doctor-profile {
    margin-bottom: 20vh;
    
    h2 {
        font-size: 20px;
    }
}