$gray: #b8b8b8;

.icon-container {
    &:hover {
        cursor: pointer;
    }
}

.company-profile {
    &-data-wrapper {
        &-title {
            margin: 0;
            color: $gray;
        }

        &-content {
            margin: 0;
        }
    }

    &-infos {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        padding: 0 5vw;
    
        &-pic {
            width: 140px;
            height: 140px;
        }
    
        &-group {
            margin: 5px 20px;
            max-width: 300px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            &:first-child {
                width: 10vw;
            }
    
            &:last-child {
                width: 20vw;
            }
    
            &-elem {
                display: flex;
                flex-direction: column;
                margin: 15px 5px;
            }

            &-elem2 {
                &>div {
                    margin-left: 10px;
                }
                margin: 15px 5px;
            }
        }
    }
}