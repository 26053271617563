.secureDataWrapper {
    background-color: white;
    position: relative;
    padding: 10vh 5vw;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    z-index: 1;

    .secureDataWrapped {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: fit-content;

        &>div {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            text-align: left;
            justify-content: center;
            align-items: flex-start;
    
            width: max-content;

            :last-of-type {
                margin-right: 10px;
            }
        }
    
        .secureImg {
            &__empty {
                padding: 10px;
                animation: lockerFadeIn 0.2s cubic-bezier(0.77, 0.2, 0.05, 1) forwards !important;
            }
    
            &__full {
                padding: 10px;
                animation: lockerFadeOut 0.2s cubic-bezier(0.77, 0.2, 0.05, 1) forwards !important;
            }
        }
    
        .secureSubtitle {
            margin: 0;
            font-weight: lighter;
            font-size: 16px;
            line-height: 1.3em;
        }
    
        .secureTitle {
            font-weight: lighter !important;
            margin: 0;
            line-height: 1.7em;
        }
    
        &:hover {
            cursor: pointer;
            .secureImg {
                &__empty {
                    animation: lockerFadeOut 0.3s cubic-bezier(0.77, 0.2, 0.05, 1) forwards !important;
                }
        
                &__full {
                    animation: lockerFadeIn 0.3s cubic-bezier(0.77, 0.2, 0.05, 1) forwards !important;
                }
            }
        }
    }

    @media screen and (max-width: 740px) {
        padding: 5vh 5vw;
    }
}

@keyframes lockerFadeIn {
    from {
        opacity: 0;
        position: relative;
        display: block;
    }
    
    to {
        opacity: 1;
        position: relative;
        display: block;
    }
}

@keyframes lockerFadeOut {
    from {
        opacity: 1;
        position: absolute;
        display: none;
    }
    
    to {
        opacity: 0;
        position: absolute;
        display: none;
    }
}