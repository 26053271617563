#isOk {
    border-radius: 25px;
    padding: 0.5vh 1rem 0.5vh 1rem;
    white-space: nowrap;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    background-color: var(--dashboard-main-color);
}

#notOk {
    border-radius: 25px;
    padding: 0.5vh 1rem 0.5vh 1rem;
    white-space: nowrap;
    font-size: 16px;
    color: var(--dashboard-main-color);
    text-transform: uppercase;
    background-color: #fff;
    border: 2px solid var(--dashboard-main-color);
}
