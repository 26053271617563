#doctor-patients{
    width: 90%;
    margin: 0 auto 20vh auto;

    .patient-name{
        &:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    }
    .patient{
        margin-bottom: 1vh;
    }

    #mes-patients {
        list-style: none;
    }

}

#content-wrapper {
    width: 90%;
    margin: auto;
}

#liste-des-patients {
    font-size: 16px;
}

#patients-docteur {

    #search-patients {
        justify-content: right;
        
        #search-patients-bar {
            color: black;
            margin: 0 0 0 0;
            padding: 2vh .5vw;
            width: 20%;
            min-width: 200px;
            max-width: 400px;
            border: 1px solid var(--light-gray2);
            border-radius: 0;
            background-color: #fff;
        }
    }
    
    h2 {
        font-size: 20px;
    }
}