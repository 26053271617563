#patient-contact-wrapper {
    width: 100%;
    align-items: flex-start;

    #patient-contact-info-wrapper {
        margin: 2vh 0vw 2vh 0vw;

        div:first-child {
            margin-bottom: 2vh;
        }
    }
}
