@import '/src/index.scss';

input[type="range"] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 13px;
    cursor: grab;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: var(--dashboard-main-color);
    border-radius: 50px;
    border: 0px solid #000000;
}

input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 1px #000000;
    border: 1px solid #000;
    height: 25px;
    width: 25px;
    border-radius: 50px;
    background: var(--dashboard-main-color);
    cursor: grab;
    -webkit-appearance: none;
    margin-top: -6.5px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
    background: var(--dashboard-main-color);
}

input[type="range"]::-moz-range-track {
    width: 100%;
    height: 13px;
    cursor: grab;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: var(--dashboard-main-color);
    border-radius: 50px;
    border: 0px solid #000000;
}

input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 1px #000000;
    border: 1px solid #000;
    height: 25px;
    width: 25px;
    border-radius: 50px;
    background: var(--dashboard-main-color);
    cursor: grab;
}

input[type="range"]::-ms-track {
    width: 100%;
    height: 13px;
    cursor: grab;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type="range"]::-ms-fill-lower {
    background: var(--dashboard-main-color);
    border: 0px solid #000000;
    border-radius: 100px;
    box-shadow: 0px 0px 0px #000000;
}

input[type="range"]::-ms-fill-upper {
    background: var(--dashboard-main-color);
    border: 0px solid #000000;
    border-radius: 100px;
    box-shadow: 0px 0px 0px #000000;
}

input[type="range"]::-ms-thumb {
    box-shadow: 0px 0px 1px #000000;
    border: 1px solid #000;
    height: 25px;
    width: 25px;
    border-radius: 50px;
    background: var(--dashboard-main-color);
    cursor: grab;
}

input[type="range"]:focus::-ms-fill-lower {
    background: var(--dashboard-main-color);
}

input[type="range"]:focus::-ms-fill-upper {
    background: var(--dashboard-main-color);
}

input[type="range"]:focus {
    outline: none;
    cursor: grabbing;
}