#default-button {
    font-size: 18px;
    padding: 0.75rem 3rem 0.75rem 3rem;
    border-radius: 5px;
    color: white;
    border: none;
    font-family: Montserrat;
    @media screen and (max-width: 500px) {
        padding: 0.5rem 2.5rem 0.5rem 2.5rem;
        font-size: 15px;
    }
}
.cliquable {
    &:hover {
        opacity: 0.9;
        cursor: pointer;
    }
}

.disabled {
    opacity: 0.6;
    &:hover {
        cursor: not-allowed;
    }
}
