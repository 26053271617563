#next-appointment-preview {
    min-width: 15rem;
    #next-appointment-wrapper {
        align-items: center;
        margin: 2vh 0vw 3vh 0vw;
        #doctor-image-wrapper {
            padding: 0.2rem;
            border-radius: 50%;
            margin-right: 2vw;
            width: 5rem;
            height: 5rem;
            min-width: 5rem;
            min-height: 5rem;
            #doctor-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
            }
        }
        #appointment-date {
            margin: 0vh 0vw 0vh 0vw;
        }

        #doctor-name {
            margin: 0vh 0vw 1vh 0vw;
        }
    }
    #button-wrapper {
        align-self: center;
    }
}
