#private-layout {
    min-height: 100vh;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: relative;
    width: 100vw;

    #layout-right {
        min-height: 100vh;
        width: calc(100% - var(--sidebar-width));
        background-color: var(--background-dashboard-color);

        box-sizing: border-box;
        padding: 2vh 4vw;

        &:has(#claim-kit) {
            padding: 0;
        }

        @media screen and (max-width: 900px) {
            width: 100%;
            left: 0px;
        }

        #content {
            height: 100%;
            z-index: 0;
        }
    }

    #dashboard-background-logo {
        z-index: 0;
        position: fixed;
        right: 0;
        width: 15rem;
        top: 50%;
    }
}
