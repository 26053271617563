#scroll-to-top-button{
    #icon-arrow-top {
        width: 2.5rem;
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: var(--foreground-index);
        margin: 0px 5vw 8vh 0px;

        &:hover {
            cursor: pointer;
        }
    }
}