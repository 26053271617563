#information-photo {
    width: 140px;
    height: 140px;
}

#information-personnel {
    .col-data  {
        margin: 0 5px;
        padding: 5px 0;

        div {
            &:not(:first-child) {
                margin-top: 1em;
            }
        }
    }
}

#information {
    width: 55%;
    
    h2 {
        margin: 0;
    }

    #dashboard-container {
        min-height: 220px;
    }

    .icon-container {
        display: flex;
        align-items: center;
        
        .action {
            cursor: pointer;
        }
    }
}