@import "/src/index.scss";

#login {
  align-items: center;
  justify-content: center;

  input {
    padding-right: 0px;
  }

  #login-no-account {
    @extend .flex-column;

    p {
      color: var(--gray);
    }

    &>p {
      margin-bottom: 0;
    }

    &>div {
      @extend .flex-row;
      @extend .flex-wrap;
      @extend .full-width;

      &>div {
        @extend .flex-column;

        margin: 1vh 0;

        min-width: 205px;
        width: 50%;

        &>p {
          @extend .text-uppercase;
          @extend .MavenPro-bold;

          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
}

#wrapper {
  width: 50%;
  max-width: 50rem;
  min-width: 40rem;
  border-radius: 20px;
  border: solid 1px var(--light-gray2);
  padding: 2rem;
  margin: 3vw;

  @media screen and (max-width: 500px) {
    max-width: 0rem !important;
    #login-sub-title {
      font-size: 0.8em !important;
    }
    #login-title {
      font-size: 1.25em !important;
    }

  }
  @media screen and (max-width: 900px) {
    min-width: 70vw;
    #login-title {
      font-size: 1.5em;
    }
    #login-sub-title {
      font-size: 1em;
      margin-bottom: 0px;
    }
    #login-register-a,
    #login-register-p,
    #forgot-password-text {
      font-size: 0.75em !important;
    }
  }
}

#icon-back {
  width: 2rem;
  margin-bottom: 1vh;
  &:hover {
    cursor: pointer;
  }
}

#login-title {
  font-size: 42px;
  margin: 1vh 0vw 0vh 0vw;
  color: var(--gray);
}
#login-sub-title {
  font-size: 18px;
  color: var(--gray);
  margin: 2vh 0vw 2.5vh 0vw;
}

#login-form {
  margin-top: .5vh;
  #label-group {
    flex-wrap: wrap !important;
    width: 100%;
    justify-content: space-between;
  }
  .login-label {
    min-width: 10rem;
    max-width: 20rem;
    flex: 1;
    margin: 2vh 1rem 0vh 0vw;
    .login-label-p {
      font-size: 14px;
      color: var(--light-gray);
      margin-bottom: 2%;
    }
    .login-input {
      width: 100%;
    }
  }
  #login-error {
    font-size: 15px;
    color: var(--red);
    margin: 3vh 0px 0px 0px;
    font-weight: 600;
    visibility: hidden;
  }
  .display-login-error {
    visibility: visible !important;
  }
  #login-button {
    margin-top: 2vh;
    &>button {
      border-radius: 30px;
    }
  }
}

#login-register {
  margin-top: 5%;
}

#login-register-p {
  font-size: 14px;
  color: var(--light-gray);
}

#login-register-a {
  font-size: 14px;
  align-self: center;
  margin-left: 1%;
  color: var(--blue);
  text-decoration: none;
}

#login-register-a:hover {
  border-bottom: 1px solid;
  cursor: pointer;
}

#forgot-password-text {
  color: var(--blue);
  font-size: 14px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
