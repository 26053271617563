.orderSolution {
    &-section {
        width: 100%;

        display: flex;
        flex-direction: row;
        align-items: stretch;
        
        column-gap: 2vw;

        &:not(:last-of-type) {
            margin-bottom: 2vh;
        }

        &-title {
            width: 100%;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            &>p {
                color: var(--dashboard-main-color);
                font-size: 24px;
                font-weight: bold;
            }

            &>div {
                margin-left: .5vw;

                &>h2 {
                    color: var(--dashboard-main-color);
                }
            }
        }
    }

    &-img-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        row-gap: 1vh;

        min-width: 192px;

        &>img {
            min-width: 190px;
            max-width: 10vw;
            border-radius: 20px;

            &:only-child {
                max-width: 8vw;
            }
        }
    }
}

.detail {
    &-show {
        display: block;
    }

    &-hide {
        display: none;
    }
}

@media screen and (max-width: 670px) {
    .orderSolution {
        &-section {
            flex-wrap: wrap;

            &:nth-child(2), &:nth-child(4), &:nth-child(6) {
                flex-wrap: wrap-reverse;
            }
        }

        &-img-wrapper {
            flex-grow: 1;
        }
    }
}