%basePicContainer {
    overflow: hidden;
    position: relative;
    align-self: center;
    box-sizing: border-box;
    height: auto;
    width: 100%;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
    aspect-ratio: 1/1;
}

#profile-picture {
    width: 100%;
    height: 100%;
    border-radius: 100%;

    #profile-picture-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
        background-color: white;
    }

    #profile-pic-container {
        @extend %basePicContainer;

        &:hover {
            label {
                cursor: pointer;
            }
            img {
                filter: brightness(60%);
            }
            #no-picture-container {
                filter: brightness(50%);
            }
        }

        &-no-hover {
            @extend %basePicContainer;
        }
    }
    #no-picture-container {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        background-color: var(--light-gray3);
    }
    &>img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        border-radius: 100%;
    }

    #edit-picture-container {
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        align-items: center;
        text-align: center;

        span {
            font-size: 12px;
            color: white;
        }
        #icon-edit {
            margin-bottom: 0.75vh;
        }
    }
}
