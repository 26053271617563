#article {
    align-items: center;

    .flex-wrap {
        flex-wrap: wrap;
    }
    .flex-wrap-reverse {
        flex-wrap: wrap-reverse;
    }

    .article-wrapper {
        padding: 8vh 0vw 8vh 0vw;
        width: 90%;
        justify-content: center;

        .article-image {
            flex: 1 1 0;
            min-width: 20rem;
            margin: 2vh 1rem 2vh 1rem;
            object-fit: cover;
        }

        .text-wrapper {
            flex: 1 1 0;
            min-width: 20rem;
            margin: 2vh 1rem 2vh 1rem;
            align-items: flex-start;
            justify-content: space-between;
            .title {
                text-transform: uppercase;
                margin: 0px 0px 1vh 0px;
                @media screen and (max-width: 700px) {
                    font-size: 18px;
                }
            }
            .date {
                border-top: solid 1vh var(--blue-performance);
                text-transform: uppercase;
                padding: 1.5vh 3rem 0px 0px;
                margin-bottom: 2vh;
                align-self: flex-start;
            }
            .description {
                line-height: 2rem;
                margin-bottom: 3vh;
            }
            .button-read {
                color: var(--dark-gray);
                border: solid 2px var(--dark-gray);
                padding: 0.5rem 2rem 0.5rem 2rem;
                align-self: center;
                border-radius: 20px;
                margin-bottom: 2vh;
                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }
                span {
                    font-size: 12px;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.background-grey {
    background-color: var(--light-gray);
}
