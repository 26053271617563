#finish-steps {
    vertical-align: middle;
    margin: auto;
    position: absolute;
    top: 50%;
    width: 80%;
    background-color: #fff;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: 1600px) {
        #img {
            height: 100%;
            object-fit: cover;
        }
    
        #finish-steps-wrapper {
            position: absolute;
            font-weight: bold;
            right: 10%;
            top: 55%;
            transform: translateY(-50%);
            width: 30rem;
    
            #logo-wrapper {
                #welcome-on {
                    color: var(--dashboard-main-color);
                    font-size: 36px;
                    margin: 0vh 0vw 1vh 0vw;
                    text-transform: uppercase;
                }
            }
    
            #text-info {
                font-size: 18px;
                margin: 0px;
                margin-bottom: 7vh;
            }
    
            #button-register {
                align-self: center;
                border: solid 1px var(--dashboard-main-color);
                padding: 0.75vh 2rem 0.75vh 2rem;
                border-radius: 20px;
                align-items: center;
    
                #button-register-label {
                    text-transform: uppercase;
                    color: var(--dashboard-main-color);
                    font-size: 18px;
                }
    
                &:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }
            }
            .button-finish-step {
                cursor: pointer;
                background-color: var(--dashboard-main-color);
                width: 100px;
                border-radius: 25px;
                padding: 0 1vw;
                margin: auto;
    
                .data-finish-step {
                    text-align: center;
                    color: white;
                    padding: 1vh 0;
                }
    
                &:hover {
                    opacity: 0.7;
                    cursor: pointer;
                }
            }
        }
    }

    @media (max-width: 1600px) {
        #img {
            height: 30vw;
            object-fit: cover;
        }
    
        #finish-steps-wrapper {  
            padding: 1em 2em;
            #logo-wrapper {
                #welcome-on {
                    color: var(--dashboard-main-color);
                    font-size: 36px;
                    margin: 0vh 0vw 1vh 0vw;
                    text-transform: uppercase;
                }
            }
    
            #text-info {
                font-size: 18px;
                margin: 0px;
                margin-bottom: 7vh;
            }
    
            #button-register {
                align-self: center;
                border: solid 1px var(--dashboard-main-color);
                padding: 0.75vh 2rem 0.75vh 2rem;
                border-radius: 20px;
                align-items: center;
    
                #button-register-label {
                    text-transform: uppercase;
                    color: var(--dashboard-main-color);
                    font-size: 18px;
                }
    
                &:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }
            }
            .button-finish-step {
                cursor: pointer;
                background-color: var(--dashboard-main-color);
                width: 100px;
                border-radius: 25px;
                padding: 0 1vw;
                margin: auto;
    
                .data-finish-step {
                    text-align: center;
                    color: white;
                    padding: 1vh 0;
                }
    
                &:hover {
                    opacity: 0.7;
                    cursor: pointer;
                }
            }
        }
    }
}
