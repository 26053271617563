@import '/src/index.scss';

#form-new-prescription {
    align-items: center;
    #toggles {
        margin-bottom: 20px;
        .toggle {
            cursor: pointer;
            text-align: center;
            border: 1px solid var(--dashboard-main-color);
            padding: 0px 10px;
            border-radius: 25px;
            color: var(--dashboard-main-color);
        }
        .select {
            background-color: var(--dashboard-main-color);
            color: #fff;
        }
    }
    #content {
        width: 90%;
        #title-wrapper {
            margin: 10vh 0vw 2vh 0vw;
            align-self: flex-start;
            align-items: center;
            color: var(--dashboard-main-color);
            #title {
                margin: 0px;
                font-size: 24px;
                text-transform: uppercase;
            }
            #icon-go-back {
                margin-right: 0.75vw;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        #form-prescription-wrapper {
            #last-update-label {
                font-size: 12px;
                font-style: italic;
            }
            #form-prescription-header-wrapper {
                align-items: center;
                justify-content: space-between;
                #header-title {
                    font-size: 22px;
                }
            }
        }
        #form-medication-list-wrapper {
            #form-medication-wrapper {
                .form-medication {
                    margin-bottom: 3vh;
                    #button-remove {
                        &:hover {
                            cursor: pointer;
                        }
                    }
                    .medication-title {
                        text-transform: uppercase;
                        font-size: 18px;
                    }
                    .margin-bottom {
                        margin-bottom: 2vh;
                    }
                    input,
                    textarea {
                        font-size: 16px;
                    }
                    .form-input {
                        border: solid 2px var(--dashboard-main-color);
                        border-radius: 20px;
                        padding: 1vh 0.5rem 1vh 0.5rem;
                        margin: 0px;
                    }
                    .small-input {
                        text-align: center;
                        width: 3rem;
                    }
                    .form-textarea {
                        border: solid 2px var(--dashboard-main-color);
                        border-radius: 25px;
                        padding: 1.5vh 1rem 1.5vh 1rem;
                        height: 10vh;
                        margin-top: 1.5vh;
                    }
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    /* Firefox */
                    input[type="number"] {
                        -moz-appearance: textfield;
                    }
                    .label-input {
                        margin-right: 0.75rem;
                        font-size: 20px;
                    }
                    .input-wrapper {
                        align-items: center;
                    }
                    #duration-medication-per-week {
                        margin-left: 0.25rem;
                        font-size: 15px;
                    }
                }
                #no-prescription-wrapper {
                    justify-content: center;
                    margin: 2vh 0vw 2vh 0vw;
                    #warning-message {
                        text-align: center;
                        width: 60%;
                        min-width: 25rem;
                        font-size: 18px;
                        color: var(--light-gray);
                    }
                }
            }
        }
    }
}

.prescription-intakeSchedule {
    @extend .full-width;
    @extend .flex-column-start;

    margin: 2vh 0;

    &>table {
        margin-left: auto;
        margin-right: auto;
        table-layout: fixed;

        &>td {
            @extend .flex-column;
        }
    }
}

#button-new-medication-wrapper {
    justify-content: center;
}

#button-new-medication {
    @extend .flex-column;

    background-color: var(--dashboard-main-color);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    #libelle-button-new-medication {
        padding: 0.5vh 1.5rem 0.5vh 1.5rem;
        color: white;
    }
    &:hover {
        cursor: pointer;
    }
}
