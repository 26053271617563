@import '/src/index.scss';

#searchbar-wrapper {
    position: relative;
    input {
        border: none;
        background-color: var(--light-gray2);
        padding: 1vh 2rem 1vh 1rem;
    }
    #icon-search {
        position: absolute;
        right: 10px;
        top: 50%;
        width: 1rem;
        transform: translateY(-50%);
    }
}

#recommended-foods-header {
    margin: 3vh 0vw 2vh 0vw;
    #filter-food-category-wrapper {
        justify-content: center;
        .food-filter {
            text-transform: uppercase;
            font-size: 14px;
            margin: 0vh 1rem 0vh 1rem;
            border: solid 2px;
            padding: 0.25vh 0.75rem 0.25vh 0.75rem;
            border-radius: 20px;
            line-height: 20px;
            &:hover {
                cursor: pointer;
            }
        }
    }
    #header-desc-wrapper {
        margin: 4vh 0vw 2vh 0vw;
        
        #header-current-category {
            text-transform: uppercase;
        }
        #header-description {
            margin: 0px;
        }
    }
}

#foods-wrapper {
    @extend .flex-row;
    @extend .flex-wrap;
}