.employeeTable {
    border-collapse: separate;
    border-spacing: 0 5px;
    
    &-body {
        &-row {
            &:nth-child(odd) {
                background-color: var(--light-gray3);
            }

            &:nth-child(even) {
                background-color: var(--background-color);
            }

            td {
                padding: 10px;
                height: 100%;
                max-width: 160px;
            }
        }
    }
}

.employeeButton-wrapper {
    &-btn {
        position: relative;
        left: 0;
        border: 0;
        border-radius: 30px;
        padding: .5vh 2vw;
    }
}