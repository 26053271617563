.ToolTip {
    width: 30px;
    height: 30px;
    position: relative;
    &-icon {
        color: var(--dashboard-main-color);
        border-radius: 50%;
    }

    &-section {
        opacity: 0;
        z-index: 20;
        position: absolute;
        width: max-content;
        max-width: 500px;
        border-radius: 7px;
        padding: 10px 20px;
        border: 2px solid var(--dashboard-main-color);
        background-color: var(--background-color);
        transform: translate( -10%, -40% );
        box-shadow: 0 1px 3px -2px #9098A9;
        transition: all .2s ease-in-out;

        animation: fadeIn .2s linear forwards;

        &:hover {
            cursor: help;
        }
    }
}

@-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
  
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}