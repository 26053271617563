.CompanyKitGlobalWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.CompanyKitWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    flex: 1 1 auto;
}