#test-results {
    justify-content: center;
    align-items: center;
    #content-wrapper {
        #text-info-wrapper {
            align-self: center;
            margin-bottom: 3vh;
            span {
                line-height: 3vh;
            }
            .text-info {
                margin: 0px;
            }
            .text-grey {
                color: var(--light-gray);
            }
            .text-green {
                color: var(--dashboard-main-color);
            }
        }
    }
}
