#order-complement {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    flex-wrap: wrap;

    box-sizing: border-box;
    width: 100%;
    flex-grow: 1;

    padding: 5vh 8vw 5vh 8vw;

    row-gap: 4vh;
}

.left {
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: space-between;

    max-height: 100%;
}

.complement-category-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    column-gap: 1vw;
    row-gap: 1vh;

    width: 100%;

    &>p {
        color: var(--dashboard-main-color);
        border: 2px solid var(--dashboard-main-color);
        border-radius: 10px;
        padding: 10px 15px;

        transition: transform .1s ease-in-out;

        &:hover {
            cursor: pointer;
            transform: scale(1.05);
        }
    }

    .selected {
        background-color: var(--dashboard-main-color);
        color: white;
    }
}

.complements-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    align-items: stretch;

    width: 80%;
    max-width: 60vw;

    column-gap: 4vh;
    row-gap: 4vw;

    margin-right: 3vw;

    &-box {
        display: flex;
        flex-direction: column;

        box-sizing: border-box;
        position: relative;
        border-radius: 15px;
        border: 1px solid var(--light-gray2);
        min-width: 240px;
        width: 12vw;
        max-width: 480px;
        overflow: hidden;
        // height: fit-content;
        box-shadow: -5px 5px 20px 1px rgba(0, 0, 0, 0.12);

        transition: all .1s ease-in-out;

        &-view-icon {
            position: absolute;
            top: 10px;
            right: 10px;
        }

        &>img {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            width: 100%;
            height: auto;
            aspect-ratio: 3/2;
            object-fit: cover;
        }

        &-info {
            box-sizing: border-box;
            padding: .5vh 1.5vw;

            &-desc {
                display: -webkit-box;
                text-align: justify;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                // white-space: nowrap;
            }
        }
    }
}

.highlighted {
    outline: 5px solid var(--dashboard-main-color) !important;
    border-width: 0px;
}

.complements-checkout {
    min-width: 300px;
    width: 20%;
    top: 5vh;
    position: sticky;
    height: fit-content;
}

.icon-invert-container {
    position: relative;
    width: 32px;
    height: 32px;
    cursor: pointer;

    &:hover .iconCheck {
        opacity: 0;
        transform: translateY(-20px);
    }

    &:hover .iconTrash {
        opacity: 1;
        transform: translateY(0);
    }
}

.co-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    transition: all .2s ease-in-out;
}

.iconCheck {
    opacity: 1;
}

.iconTrash {
    opacity: 0;
    transform: translateY(20px);
}

.footerSection {
    flex-wrap: wrap;
    row-gap: 4vh;

    background-color: var(--light-gray2);
    box-sizing: border-box;
    padding: 5vh 8vw 5vh 8vw;
    column-gap: 6vw;

    &>div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        max-width: 250px;
        flex-grow: 1;

        align-items: flex-start;

        &>img {
            width: 40px;
            border-radius: 50%;
            margin-right: 10px;
        }

        &>svg {
            width: 36px;
            height: 36px;
            margin-right: 10px;
        }

        &>div {
            &>h4 {
                margin: 0;
                text-transform: uppercase;
                color: var(--dashboard-main-color);
            }

            &>p {
                margin: 0;
                white-space: initial;
            }

            &>a {
                &>p {
                    margin: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 1578px) {
    .complements-checkout {
        width: 100%;
    }
}