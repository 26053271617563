#order-not-found {
    width: 100%;
    align-items: center;
    margin-top: 5%;

    #order-not-found-content {
        width: 50%;
        justify-content: space-between;
        align-items: center;
        border: solid 1px var(--purple);
        padding: 2vw;
        border-radius: 5px;

        #error-message {
            width: 90%;
            font-weight: 600;
            color: var(--purple);
        }

        #icon-back {
            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }
    }

    #image-not-found-container {
        width: 100%;
        align-items: center;
        margin-top: 5vh;
        img {
            width: 20%;
        }
    }
}
