#information {
    width: 100%;

    #content-patient-information {
        margin: 2vh 0vw 2vh 0vw;
        align-items: center;
        justify-content: space-around;
        #patient-information-photo {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2px;
            width: 4rem;
            height: 4rem;
            margin-right: 1rem;
            border-radius: 100%;

            img {
                border-radius: 100%;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            #icon-no-profile-pic {
                width: 100%;
                height: 100%;
            }
        }
        #information-personnel {
            flex-grow: 1;
            margin: auto;

            .col-data {
                margin: 0 5px;
                padding: 5px 0;

                div {
                    &:not(:first-child) {
                        margin-top: 1em;
                    }
                }
            }
        }
    }
}
