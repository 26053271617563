#address-information {

    h2 {
        color: var(--dashboard-main-color);
        font-size: 18px;
        margin: 0;
        padding: 0;
    }

    #title-wrapper {
        justify-content: space-between;
        align-items: center;
    }

    .icon-container {
        display: flex;
        align-items: center;

        .action {
            cursor: pointer;
        }
    }
    
    #address-information-wrapper {
        margin: 3vh 0vw 3vh 0vw;
        #row-list-information {
            flex-wrap: wrap;
            margin: 2vh 0vw 0vh 0vw;
            .information-wrapper {
                margin: 0vh 8% 1vh 0vw;
            }
        }
    }
    
}

#information-specifique-wrapper {
    margin: 3vh 0 3vh 0;

    #row-list-specifique {
        flex-wrap: wrap;
        margin: 2vh 0vw 0vh 0vw;
        
        .information-wrapper {
            margin: 0vh 6% 1vh 0vw;
        }
    }
}

#modalEditSpecificInfo {

    #multipleSelect {
        display: block;
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .css-2m9kme-MuiFormControl-root {
        margin-top: 20px;
        width: 100%;
        border-radius: 4px;
        border: 1px solid var(--dashboard-main-color);
        margin: 0;
    }
}