#auth-layout-header {
    box-sizing: border-box;
    padding: 1vh 3vw;

    &-logo {
        width: 12vw;
        min-width: 240px;
        height: auto;
    }

    &>nav {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
}

#current-language {
    margin-right: 3vw;
    position: relative;
    height: auto;
    width: 2.5vw;
    min-width: 2rem;
    aspect-ratio: 3/2;
    .flag-current-language {
        width: 100%;
        height: 100%;
        object-fit: cover;
        &:hover {
            cursor: pointer;
        }
    }
    #dropdown-languages {
        right: 0px;
        top: 150%;
        position: absolute;
        background-color: var(--light-gray3);
        .dropdown-language {
            align-items: center;
            padding: 1rem;
            .label-language {
                margin-left: 0.5vw;
                font-size: 14px;
            }
            .image-language {
                height: auto;
                width: 2.5vw;
                min-width: 2rem;
                aspect-ratio: 3/2;
                object-fit: cover;
            }
            &:hover {
                background-color: var(--light-gray2);
                cursor: pointer;
            }
        }
        #dropdown-language-selected {
            background-color: var(--light-gray2);
        }
    }
}