@import "/src/index.scss";

#kit-register {
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-height: 600px;
    overflow-y: auto;

    #informations-wrapper {
        position: relative;
        background-color: white;
        width: 80vw;
        height: 80vh;
        min-height: 460px;
        align-items: center;
        justify-content: space-around;

        #icon-arrow {
            position: absolute;
            top: 10vh;
            left: 5vw;
            width: 2rem;
            @media screen and (max-width: 900px) {
                right: 7vw !important;
                left: auto;
            }
        }

        #form-wrapper {
            @extend .flex-column;
            @extend .full-width;

            padding: 10vh 10vw;

            &>:not(:last-child) {
                margin-bottom: 10px;
            }

            #title {
                color: var(--dashboard-main-color);
                font-size: 40px;
                margin: 0px 0px 2vh 0px;
            }

            #form-description {
                font-size: 20px;
                margin: 0px;
                width: 80%;
            }
        }
    }

    #kit-register-wrapper {
        position: relative;
        background-color: white;
        width: 80vw;
        height: 80vh;
        min-height: 460px;
        align-items: center;
        justify-content: space-around;

        #image-wrapper {
            justify-content: center;
            width: 100%;
            height: 100%;
            #kit-image {
                width: 100%;
                object-fit: cover;
            }
            @media screen and (max-width: 900px) {
                display: none;
            }
        }
        #icon-arrow {
            position: absolute;
            top: 10vh;
            left: 5vw;
            width: 2rem;
            @media screen and (max-width: 900px) {
                right: 7vw !important;
                left: auto;
            }
        }

        #form-wrapper {
            @extend .flex-column;

            margin: 1rem;
            width: 40rem;
            height: 100%;
            
            justify-content: space-around;
            #title {
                color: var(--dashboard-main-color);
                font-size: 40px;
                margin: 0px 0px 2vh 0px;
            }
            #form-description {
                // color: var(--gray);
                font-size: 20px;
                margin: 0px;
                width: 80%;
            }
            #form {
                align-items: flex-start;
                #input-label {
                    font-size: 18px;
                }
                #input-code {
                    padding: 0.5rem 1rem 0.5rem 1rem;
                    border: none;
                    border: solid 1px var(--dashboard-main-color);
                    font-size: 16px;
                    border-radius: 10px;
                    margin: 2vh 0vw 5vh 0vw;
                }
                #button-register {
                    border: none;
                    cursor: pointer;
                    padding: 0.75vh 2rem 0.75vh 2rem;
                    border-radius: 20px;
                    span {
                        color: white;
                        font-size: 16px;
                        text-transform: uppercase;
                    }
                }
            }
            #help-find-code-wrapper {
                #help-find-code-title {
                    text-decoration: underline;
                    margin-bottom: 0.5vh;
                    font-size: 18px;
                    color: var(--light-gray);
                }
                #help-find-code-desc {
                    font-size: 16px;
                    color: var(--light-gray);
                }
            }
        }
    }
}

.form-wrapper-row {
    @extend .flex-row;
    @extend .flex-wrap;
    @extend .full-width;
    @extend .space-between;

    &-field {
        @extend .flex-column-start;

        width: 45%;
    }
}

.customContainerWrapper {
    box-sizing: border-box;
    margin: 10vh 5vw 10vh 5vw;
    width: 80vw !important;

    #image-wrapper {
        justify-content: center;
        width: 100%;
        height: 100%;
        #kit-image {
            width: 100%;
            object-fit: cover;
        }
    }

    #form-wrapper {
        @extend .flex-column;

        margin: 1rem;
        width: 40rem;
        height: 100%;
        
        justify-content: space-around;
        #title {
            color: var(--dashboard-main-color);
            font-size: 40px;
            margin: 0px 0px 2vh 0px;
        }
        #form-description {
            // color: var(--gray);
            font-size: 20px;
            margin: 0px;
            width: 80%;
        }
        #form {
            align-items: flex-start;
            #input-label {
                font-size: 18px;
            }
            #input-code {
                padding: 0.5rem 1rem 0.5rem 1rem;
                border: none;
                border: solid 1px var(--dashboard-main-color);
                font-size: 16px;
                border-radius: 10px;
                margin: 2vh 0vw 5vh 0vw;
            }
            #button-register {
                border: none;
                cursor: pointer;
                padding: 0.75vh 2rem 0.75vh 2rem;
                border-radius: 20px;
                span {
                    color: white;
                    font-size: 16px;
                    text-transform: uppercase;
                }
            }
        }
        #help-find-code-wrapper {
            #help-find-code-title {
                text-decoration: underline;
                margin-bottom: 0.5vh;
                font-size: 18px;
                color: var(--light-gray);
            }
            #help-find-code-desc {
                font-size: 16px;
                color: var(--light-gray);
            }
        }
    }
}

@media only screen and (max-width: 530px) {
    .customContainerWrapper {
        box-sizing: border-box;
        margin: 0;
        width: 100% !important;

        padding-left: 0;
        padding-right: 0;

        #icon-arrow {
            margin-left: 2rem;
        }
    }
}

@media only screen and (max-width: 900px) {
    .customContainerWrapper {

        #image-wrapper {
            display: none;
        }

        #form-wrapper {
            width: auto;
            margin-left: 0;
            margin-right: 0;
        }
    }
}