#our-team {
    position: relative;
}

#persons-container {
    margin-top: 5vh;
    margin-bottom: 10vh;
    width: 80%;
    height: auto;
    align-self: center;
    align-items: flex-start;
}

#filter-person {
    width: 100%;
    margin-bottom: 10vh;
    align-items: center;

    @media screen and (max-width: 740px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

#filterWrapper {
    max-width: 250px;
    position: relative;
    background: var(--gray);
    padding: 2px;

    border-radius: 30px;
    margin: 1vh 1vw 1vh 1vw;
    overflow: hidden;
    white-space: nowrap;
    transition: all 100ms linear;

    &__selected {
        max-width: 250px;
        position: relative;
        background: var(--gradient);
        padding: 2px;
        margin: 15px;

        border-radius: 30px;
        margin: 1vh 1vw 1vh 1vw;
        overflow: hidden;
        white-space: nowrap;
        transition: all 100ms linear;

        #filterWrapped {
            background-color: var(--background-color);
            padding: 2rem;
            border-radius: 30px;

            text-align: center;
            align-items: center;
            padding: 0.5rem 2rem 0.5rem 2rem;
            color: var(--gradient);
            font-size: 12px;
            transition: all 0.1s linear;

            &:hover {
                cursor: pointer;
            }
        }
    }

    #filterWrapped {
        background-color: var(--background-color);
        padding: 2rem;
        border-radius: 30px;

        text-align: center;
        align-items: center;
        padding: 0.5rem 2rem 0.5rem 2rem;
        color: var(--gray);
        font-size: 12px;
        transition: all 0.1s linear;
    }

    &:hover {
        cursor: pointer;
    }
}

.leftMembers {
    display: flex;
    visibility: visible;
    width: 100%;
    align-self: center;
    justify-content: left;
    flex-wrap: wrap;

    transition: all 0.1s ease-in-out;
}

.centerMembers {
    display: none;
    visibility: collapse;
    width: 100%;
    align-self: center;
    justify-content: center;
    flex-wrap: wrap;

    transition: all 0.1s ease-in-out;
}

.rightMembers {
    display: flex;
    visibility: visible;
    width: 100%;
    align-self: center;
    justify-content: right;
    flex-wrap: wrap;

    transition: all 0.1s ease-in-out;
}

.team-members {
    .person {
        display: block;
        width: 15vw;
        min-width: 15rem;
        margin: 0px 4% 4vh 4%;
        align-items: flex-start;

        #imgWrapper {
            width: 10vw;
            height: 10vw;
            min-width: 8rem;
            min-height: 8rem;

            position: relative;
            left: 50%;
            transform: translateX(-50%);
            background: var(--gradient);
            padding: 4px;

            border-radius: 100%;
            overflow: hidden;
            white-space: nowrap;

            margin-bottom: 2vh;
        }

        .person-img {
            width: 10vw;
            height: 10vw;
            border-radius: 100%;
            object-fit: cover;
            min-width: 8rem;
            min-height: 8rem;
        }

        .person-name {
            font-size: 19px;
            font-weight: lighter !important;
            color: var(--gray);
            margin: 0px 0px 2vh 0px;
        }
        .person-specialties {
            font-size: 1em;
            color: var(--gray);
            font-style: italic;
            padding: 0;
            margin: 0;

            p:last-of-type {
                margin: 0px 0px 2vh 0px;
            }
        }
        .person-desc {
            font-size: 11px;
            color: var(--gray);
            text-align: left;
            text-align-last: left;
            margin: 0px;
            line-height: 2.3vh;
            width: 100%;
        }
    }
}

#description {
    position: relative;
    background-color: #efeeee;
    margin: 2vh 0 5vh 0;
    padding: 2vh 5vw;
    min-height: 150px;
    align-items: center;
    justify-content: center;

    #descriptionImg {
        width: 650px;
        margin: 15px;
    }
}

@media screen and (max-width: 730px) {
    #description {
        #descriptionImg {
            width: 100%;
            margin: 15px;
        }
    }
}

@media screen and (max-width: 1256px) {
    .leftMembers {
        display: none;
        visibility: collapse;
    }

    .centerMembers {
        display: flex;
        visibility: visible;
    }

    .rightMembers {
        display: none;
        visibility: collapse;
    }
}
