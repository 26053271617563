#video-meeting {
    height: 100vh;
    width: 100%;
    align-items: center;
    iframe {
        width: 90%;
        // height: 100%;
        flex-grow: 1;
        border-radius: 5px;
        border: none;
        margin-bottom: 3vh;
    }
    #video-meeting-header {
        width: 90%;
        margin: 2vh 0vw 2vh 0vw;
        #title {
            margin: 0vh 0vw 0.5vh 0vw;
        }
        .meeting-info {
            color: var(--dashboard-main-color);
            text-transform: uppercase;
        }
        #spacer {
            border: solid 1px;
            flex-grow: 1;
            height: 0vh;
            margin: 0vh 1rem 0vh 1rem;
        }
    }
    #documents-wrapper {
        width: 90%;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 1.5vh;
        .document-wrapper {
            align-items: center;
            margin: 0.5rem;
            background-color: var(--dashboard-main-color);
            padding: 1vh 1.5vw 1vh 1.5vw;
            color: white;
            border-radius: 20px;
            .document-label {
                margin-right: 0.5rem;
                font-size: 16px;
                text-align: center;
            }
            &:hover {
                opacity: 0.8;
                cursor: pointer;
            }
        }
    }
}

#error-wrapper {
    justify-content: center;
    align-items: center;
    height: 100vh;
    #message-wrapper {
        margin-bottom: 3vh;
        width: 70%;
        .error-message {
            font-size: 24px;
            text-align: center;
            margin: 0px;
        }
    }
    #button-back {
        background-color: var(--dashboard-main-color);
        padding: 1vh 2rem 1vh 2rem;
        border-radius: 20px;
        #label-button {
            color: white;
            font-size: 20px;
        }
        &:hover {
            cursor: pointer;
        }
    }
}
