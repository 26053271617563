#dashboard-information-wrapper {
    .label-information {
        text-transform: uppercase;
        font-family: "MavenPro-bold";
        margin: 0vh 0vw 0.25vh 0vw;
        text-align: center;
    }
    .information {
        color: var(--gray);
        font-family: "Montserrat";
        margin: 0vh 0vw 0vh 0vw;
    }
}
