#videos-tests {
    align-items: center;
    margin-bottom: 12vh;
    #title-wrapper {
        box-sizing: border-box;
        padding: 0vh 2rem 0vh 2rem;
        align-items: center;
        position: relative;
        margin-bottom: 3vh;
        #title {
            font-size: 36px;
            margin: 0rem 0vw 1rem 0vw;
        }
        #sub-title {
            text-transform: uppercase;
            bottom: 0px;
            right: 0px;
        }
    }

    #videos-tests-list-wrapper {
        .video-test-container {
            width: 60vw;
            min-width: 20rem;
            margin-bottom: 3vh;
        }
        .video-test-wrapper {
            justify-content: space-between;
            align-items: center;
            width: 100%;
            background-color: var(--light-gray2);
            .icon {
                cursor: pointer;
                margin-right: 2rem;
            }
            .test-title {
                margin-left: 2rem;
                text-transform: uppercase;
                @media screen and (max-width: 600px) {
                    font-size: 14px;
                }
            }
        }
        .test-detail-wrapper {
            width: 90%;
            align-self: center;
            justify-content: center;
            align-items: flex-start;
            margin: 2vh 0vw 2vh 0vw;
            flex-wrap: wrap;
            gap: 1rem;
            .test-description {
                margin: 0px;
                width: 50%;
                min-width: 15rem;
                @media screen and (max-width: 1000px) {
                    width: 80%;
                }
                @media screen and (max-width: 600px) {
                    font-size: 14px;
                }
            }
            .video-wrapper {
                flex-grow: 1;
                height: 35vh;
            }
        }
    }
}
