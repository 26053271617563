#step-solution {
    align-items: center;
    background-color: var(--light-gray);
    margin-bottom: 12vh;
    padding: 3vh 0vw 6vh 0vw;
    #step-solution-wrapper {
        width: 90%;
        align-items: center;
        #step-solution-content-wrapper {
            width: 100%;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            #foot-image {
                width: 20rem;
                margin: 0vh 2rem 0vh 2rem;
            }
            #text-wrapper {
                width: 40%;
                min-width: 20rem;
                align-items: flex-start;

                .solution-step {
                    position: relative;
                    padding-bottom: 4vh;
                    align-items: flex-start;
                    .solution-text {
                        width: 100%;
                        margin-top: 5px;
                    }
                    .index-wrapper {
                        margin-right: 1rem;
                        border-radius: 50%;
                        justify-content: center;
                        align-items: center;
                        background-color: var(--blue-performance);
                        max-width: 2rem;
                        max-height: 2rem;
                        min-width: 2rem;
                        min-height: 2rem;
                        .index {
                            color: white;
                            z-index: 1;
                        }
                        .spacer {
                            position: absolute;
                            height: 100%;
                            background-color: var(--blue-performance);
                            width: 2px;
                            top: 0px;
                        }
                    }
                }
            }
        }
    }
}
