#contact-us {
    margin: 8vh 0vw 15vh 0vw;
    #content {
        margin: 0px;
        align-items: center;
        width: 100%;

        #form-container {
            width: 50%;
            min-width: 30rem;
            #success-container {
                border-radius: 20px;
                padding: 2rem;
                margin-bottom: 10vh;
                align-items: center;
                text-align: center;
                width: 100%;
                #title {
                    font-size: 42px;
                    margin: 0vw 0vw 1vh 0vw;
                    color: var(--dark-gray);
                }
                #success-message {
                    font-size: 16px;
                    margin-right: 1vw;
                    width: 80%;
                }
            }
        }

        .sub-title {
            margin: 0px;
            color: var(--light-gray);
            font-size: 16px;
        }
        .title-container {
            margin-bottom: 5vh;
        }

        #contact-info-container {
            width: 60%;
            flex-wrap: wrap;
            justify-content: center;
            .contact-info {
                align-items: center;
                width: 20%;
                min-width: 10rem;
                margin: 0vh 1rem 3vh 1rem;
                // border: solid 1px red;
                .icon-contact-info {
                    padding: 1rem;
                    border-radius: 100%;
                    margin-bottom: 3vh;
                }
                .text-contact-info {
                    text-align: center;
                    font-size: 14px;
                }
            }
        }
    }
}
