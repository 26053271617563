#contact-information-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    row-gap: 1vh;

    flex-grow: 1;
    justify-content: space-evenly;

    &>div {
        width: 100%;
        column-gap: max(1vw, 20px);

        &>:nth-child(2) {
            flex-grow: 1;
        }
    }
}
