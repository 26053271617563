#food-advice-preview {
    #sub-title {
        color: var(--dashboard-main-color);
    }
    #container-title-wrapper {
        align-items: flex-start;
        justify-content: space-between;
    }
    #food-list {
        justify-content: space-around;
        margin: 3vh 0vw 3vh 0vw;
        flex-wrap: wrap;
        .food-wrapper {
            margin: 1vh 0.5rem 1vh 0.5rem;
        }
        .food-category {
            color: var(--light-gray);
            font-size: 18px;
            margin: 0vh 0vw 1vh 0vw;
            @media screen and (max-width: 600px) {
                font-size: 16px;
            }
        }
        .food-name {
            font-size: 20px;
            @media screen and (max-width: 600px) {
                font-size: 18px;
            }
        }
        .food-image-wrapper {
            padding: 0.15rem;
            border-radius: 100%;
            min-width: 5rem;
            min-height: 5rem;
            margin: 0vh 0vw 1vh 0vw;
            width: 6vw;
            height: 6vw;
            @media screen and (max-width: 600px) {
                min-width: 3.5rem;
                min-height: 3.5rem;
            }
            .food-image {
                width: 100%;
                height: 100%;
                background-color: white;
                border-radius: 100%;
                object-fit: cover;
            }
        }
    }
}
