$allocate-main: var(--dashboard-main-color);
$gray-color: #808080;

.allocate-form {
    padding: 0 2vw;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    &-fields {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
    }

    &-validate {
        background-color: $allocate-main;
        color: white;
        border: 0;
        margin-top: 10px;
        padding: 10px 25px;
        border-radius: 30px;
        width: fit-content;
        font-size: 14px;
        transition: all .3s ease-in-out;

        &:hover {
            cursor: pointer;
            transform: scale(1.05);
        }

        &:disabled {
            background-color: $gray-color;

            &:hover {
                cursor: not-allowed;
                transform: scale(1);
            }
        }
    }

    &-sub {
        font-size: 12px;
    }
}