@import '/src/index.scss';

.checkbox-wrapper {
    padding: 10px 0;

    &>label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
    }

    input[type="checkbox"] {
        /* removing default appearance */
        -webkit-appearance: none;
        appearance: none;
        /* creating a custom design */
        border: 0.2em solid var(--dashboard-main-color);
        margin-right: 10px;
        width: 1.6em;
        height: 1.6em;
        border-radius: 5px;
        outline: none;
        aspect-ratio: 1/1;
        cursor: pointer;

        &.checked {
            background-color: var(--dashboard-main-color);
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;

            &::before {
                content: "\2713";
                font-size: 1.5em;
                color: #fff;
                position: absolute;
                top: -40%;
            }
        }

        &.not-checked {
            background-color: var(--red);
            border-color: var(--red);
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;

            &::before {
                content: "\2716";
                font-size: 1.5em;
                color: #fff;
                position: absolute;
                top: -40%;
            }
        }

        &:disabled {
            border-color: #c0c0c0;
            background-color: #c0c0c0;

            & + span {
                color: #c0c0c0;
            }
        }

        &:focus {
            box-shadow: 0 0 2px var(--dashboard-main-color);
        }
    }

    &-no-label {
        @extend .flex-column;

        padding: 10px 0;

        input[type="checkbox"] {
            /* removing default appearance */
            -webkit-appearance: none;
            appearance: none;
            /* creating a custom design */
            border: 0.2em solid var(--dashboard-main-color);
            width: 1.6em;
            height: 1.6em;
            border-radius: 5px;
            outline: none;
            cursor: pointer;

            &.checked {
                background-color: var(--dashboard-main-color);
                position: relative;

                &::before {
                    content: "\2713";
                    font-size: 1.5em;
                    color: #fff;
                    position: absolute;
                    top: -40%;
                }
            }

            &.not-checked {
                background-color: var(--red);
                border-color: var(--red);
                position: relative;

                &::before {
                    content: "\2716";
                    font-size: 1.5em;
                    color: #fff;
                    position: absolute;
                    top: -40%;
                }
            }

            &:disabled {
                border-color: #c0c0c0;
                background-color: #c0c0c0;

                & + span {
                    color: #c0c0c0;
                }
            }

            &:focus {
                box-shadow: 0 0 2px var(--dashboard-main-color);
            }
        }
    }
}