#product-detail {
    align-items: center;

    #banner-black {
        width: 100%;
        background-color: #191919;
        margin-top: 5%;
        overflow: hidden;

        #banner-black-text {
            margin-left: 15%;
            width: 80%;
        }

        h1 {
            color: #FFFFFF;
            font-size: 32px;
            margin-top: 10%;
            width: 60%;
        }

        p {
            color: var(--light-gray);
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 10%;
            width: 60%;
        }
    }

    .banner-left {
        width: 30%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .banner-right {
        width: 70%;
        position: relative;
        overflow: hidden;
        border-top-left-radius: 50px 50%;
        border-bottom-left-radius: 50px 50%;

        img {
            position: absolute;
            top: -9999px;
            left: -9999px;
            right: -9999px;
            bottom: -9999px;
            margin: auto;
        }
    }

    #testing-container {
        margin-top: 5%;
        justify-content: space-evenly;
        padding: 0rem 0rem 5rem 0rem;

        #text-testing-container {
            width: 40%;
            justify-content: center;
            #testing-title {
                font-size: 31px;
                color:var(--gray);
            }
            #testing-desc {
                color: var(--light-gray);
                line-height: 2rem;
                font-size: 16px;
            }
        }
    }

    #supplement-container {
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 5%;
    }
    #image-supplement-container{
        width: 40%;
    }
    #text-supplement-container {
        width: 40%;
        #supplement-description {
            color: var(--light-gray);
            line-height: 2rem;
        }
        #supplement-title {
            font-size: 31px;
            color: var(--gray);
        }
    }

    #health-service {
        align-items: center;
        background: var(--light-gray3);
        padding-top: 3%;
        padding-bottom: 3%;
        #header-container {
            align-items: center;
            margin-bottom: 5%;
            text-align: center;
            #health-service-title {
                font-size: 32px;
                color:var(--gray);
            }
        }
        #list-services {
            width: 90%;
            justify-content: space-around;
            .service {
                margin: 0px 3vw 0px 3vw;
                .icon-service {
                    margin-bottom: 10%;
                }
                .service-number {
                    font-size: 18px;
                    margin: 0px 0px 5% 0px;
                    color: var(--gray);
                }
                .service-title {
                    font-size: 18px;
                    margin: 0px 0px 7% 0px;
                    color: var(--gray);
                }
                .service-description {
                    margin: 0px;
                    color: var(--light-gray);
                    line-height: 2rem;
                }
            }
        }
    }
}
