#claim-kit-confirm-phone {
    align-items: center;
    flex-grow: 1;
    justify-content: center;

    #phone-container {
        background-color: white;
        border-radius: 3px;
        width: 80%;
        border: solid 1px var(--light-gray2);
        img {
            width: 80%;
            object-fit: cover;
            height: 100%;
        }
    }

    #confirmation-container {
        justify-content: space-evenly;
        align-items: center;
        align-self: center;
        background-color: white;
        width: 80%;
        height: 50vh;
        border: 1px solid #c4c4c4;
        #text-container {
            align-items: center;
            #title {
                font-size: 26px;
                font-weight: 600;
                width: 60%;
                text-align: center;
            }
        }
    }

    #steps-container {
        margin: 1% 0% 1% 0%;
    }
    #form-container {
        justify-content: center;
        align-items: flex-start;
        margin-left: 3vw;
        margin-bottom: 3vh;
        #title-container {
            margin-bottom: 5%;
            width: 90%;
            #title {
                font-family: Montserrat-Bold;
                color: var(--dashboard-main-color);
                font-size: 40px;
                margin: 2vh 0vw 0vh 0vw;
            }
            #sub-title {
                color: var(--light-gray);
            }
        }
        #send-back-text {
            color: var(--dashboard-main-color);
            &:hover {
                cursor: pointer;
            }
        }
        #send-back-text-disable {
            color: var(--light-gray);
        }
        // #input-container {
        //     width: 40%;
        //     input {
        //         border: none;
        //         border-bottom: solid 1px black;
        //         padding: 2%;
        //         font-size: 18px;
        //         font-family: Montserrat;
        //     }
        //     ::placeholder {
        //         /* Chrome, Firefox, Opera, Safari 10.1+ */
        //         color: var(--light-gray);
        //         opacity: 1; /* Firefox */
        //     }
        //     :-ms-input-placeholder {
        //         /* Internet Explorer 10-11 */
        //         color: var(--light-gray);
        //     }
        //     ::-ms-input-placeholder {
        //         /* Microsoft Edge */
        //         color: var(--light-gray);
        //     }
        //     /* Chrome, Safari, Edge, Opera */
        //     input::-webkit-outer-spin-button,
        //     input::-webkit-inner-spin-button {
        //         -webkit-appearance: none;
        //     }
        //     /* Firefox */
        //     input[type="number"] {
        //         -moz-appearance: textfield;
        //     }
        //     #input-label {
        //         margin-bottom: 2%;
        //         color: #000;
        //     }
        //     margin-bottom: 8%;
        // }
    }
}
