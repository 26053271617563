@import "/src/index.scss";

%defaultWebButtonSection {
    padding: 2vh 4vw;
    z-index: 2;

    @extend .flex-column;

    &-button {
        margin: 6vh 0;
    }
}

.webButtonSection {
    &-white {
        $webButtonSectionColor: #ffffff;
        @extend %defaultWebButtonSection;

        background-color: $webButtonSectionColor !important;

        &-button {    
            :first-child {
                background-color: $webButtonSectionColor;
            }
    
            &:hover {
                cursor: pointer;
        
                :first-child {
                    background-color: rgba($color: #000000, $alpha: 0.0);
        
                    :first-child {
                        background: $webButtonSectionColor;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
    }

    &-gray {
        $webButtonSectionColor: #ebf1f3;
        @extend %defaultWebButtonSection;

        background-color: $webButtonSectionColor !important;

        &-button {    
            :first-child {
                background-color: $webButtonSectionColor;
            }
    
            &:hover {
                cursor: pointer;
        
                :first-child {
                    background-color: rgba($color: #000000, $alpha: 0.0);
        
                    :first-child {
                        background: $webButtonSectionColor;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }   
    }
}

// .createAccountWrapper {
//     background-color: $sectionBackground !important;
//     padding: 2vh 4vw;

//     z-index: 2;

//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;

//     &-button {
//         margin: 4vh 0;

//         :first-child {
//             background-color: $sectionBackground;
//         }

//         &:hover {
//             cursor: pointer;
    
//             :first-child {
//                 background-color: rgba($color: #000000, $alpha: 0.0);
    
//                 :first-child {
//                     background: $sectionBackground;
//                     -webkit-background-clip: text;
//                     -webkit-text-fill-color: transparent;
//                 }
//             }
//         }
//     }
// }