#test-in-progress {
    #test-info-wrapper {
        justify-content: space-between;
        align-items: center;
        .test-in-progress-header-info {
            color: var(--dashboard-main-color);
            text-transform: uppercase;
        }
        #spacer {
            width: 2rem;
            height: 0px;
            border: solid 0.5px;
            flex-grow: 1;
            margin: 0vh 1vw 0vh 1vw;
        }
    }
    #progress-bar-container {
        width: 100%;
        padding-bottom: 1vh;
        margin-top: 5vh;

        .indexedStep {
            color: white;
            width: 20px;
            height: 20px;
            font-size: 12px;
            background-color: var(--light-gray);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        span {
            text-align: center;
        }
        .step {
            border-radius: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        .step-label {
            font-size: 16px;
        }
        .step-not-done {
            background-color: white;
            color: var(--dashboard-main-color);
            border: solid 2px white;
            font-size: 20px;
        }
        .step-done {
            border: solid 2px var(--dashboard-main-color);
            color: var(--dashboard-main-color);
        }
        .MuiStepConnector-line {
            border: solid 1px var(--dashboard-main-color);
        }
    }
}
