#patient-detail {
    margin-top: 9vh;
    margin-bottom: 10vh;

    h2 {
        font-size: 20px;
    }

    #patient-detail-wrapper {
        margin-top: 2.5vh;
    }

    #prochain-rdv {
        width: 100%;
        margin: auto;
        padding: 0;
    }
}
