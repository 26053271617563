#claim-kit-form-user-address {
    flex-grow: 1;
    align-items: center;
    justify-content: center;

    #claim-kit-form-user-address-wrapper {
        width: 80%;
        background-color: var(--background-color);
        padding: 2rem;
        box-shadow: -5px 5px 20px 1px rgba(0, 0, 0, 0.12);

        .row-inputs {
            width: 100%;
            justify-content: space-between;
            .input-container {
                width: 45%;
            }
        }
        .input-container {
            margin-bottom: 4vh;
        }
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: "";
    }

    .icon-check {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
    }

    .checkbox {
        margin-right: 0.5vw;
    }

    #title {
        font-size: 20px;
        margin: 0px 0px 3vh 0px;
    }

    .label-input {
        font-family: MavenPro-Bold;
        margin-bottom: 0.5vh;
    }

    #button-next {
        background-color: var(--dashboard-main-color);
    }
}
