@import '/src/index.scss';

#search-patient {
    @extend .full-width;

    #input-search-patient {
        width: 100%;
        margin: 5vh 0;
        padding: 2vh 0 2vh 1vw;
        left: -1vw;
        border-radius: 25px;
        border: 1px var(--dashboard-main-color) solid;
    }

    #table-new-message {
        text-align: center;
        width: 100%;
        border-collapse: collapse;

        .table-head {
            color: var(--dashboard-main-color);
        }

        .data-table {
            cursor: pointer;
            // display: block;
            padding-bottom: 1em;
            background-color: var(--light-gray2);
            border-bottom: 3px solid #fff;
            border-top: 5px solid #fff;

            th, td {
                padding: 1vh 0;
            }
        }
    }

    #wrapper-list-patient {
        max-height: 30vh;
        overflow: auto;
    }
}

#send-new-message {
    #no-patient {
        margin: auto;
    }
    
    #resume-patient {
        margin: 5vh auto;
        width: 90%;
        border: 1px solid black;
        border-radius: 25px;
        padding: 2vh 2vw;
        p {
            margin: 0;
        }
    }

    #button-send-message {
        position: absolute;
    }
}