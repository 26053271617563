#step-experts {
    margin-bottom: 12vh;
    align-items: center;
    #step-experts-wrapper {
        width: 90%;
        align-items: center;
        #step-experts-content-wrapper {
            justify-content: center;
            align-items: center;
            margin-bottom: 6vh;
            flex-wrap: wrap;

            #tennis-image {
                width: 35vw;
                min-width: 20rem;
                margin: 2vh 1rem 2vh 1rem;
            }
            #text-wrapper {
                width: 30%;
                min-width: 20rem;
                p {
                    margin: 0px;
                }
            }
        }
        #button-our-team {
            border: solid 2px var(--dark-gray);
            padding: 0.5rem 2rem 0.5rem 2rem;
            border-radius: 20px;
            &:hover {
                cursor: pointer;
            }
            span {
                color: var(--dark-gray);
                font-size: 12px;
                text-transform: uppercase;
            }
        }
    }
}
