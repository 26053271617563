#reviews-carousel {
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;

    padding: 6vh 0;

    z-index: 2;

    background-color: #ebf1f3 !important;

    .carousel-content {
        width: 60vw;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center;

        .slick-track {
            display: flex !important;
            align-items: center;
        }

        .slick-slide {
            height: auto;
        }

        .carousel-item-review {
            display: flex !important;
            align-items: center;
            position: relative;
            align-items: center;
            justify-content: center;
            padding: 5vh 0vw 5vh 0vw;
            justify-content: space-between;

            .carousel-item-wrapper {
                display: flex !important;
                justify-content: center;
                width: 80%;
                align-self: center;
            }
            .carousel-item-text {
                line-height: 1.8rem;
                font-size: 24px;
                width: 100%;
                text-align: center;
                font-style: italic;
                margin-bottom: 4vh;
            }
            .carousel-item-created-by{
                font-size: 14px;
                text-align: end;
            }
            .quote {
                $width: 40px;
                &-left {
                    position: relative;
                    width: $width;
                    top: 0;
                    align-self: flex-start;
                }

                &-right {
                    position: relative;
                    width: $width;
                    bottom: 5vh;
                    align-self: flex-end;
                }
            }
        }

        .slick-prev {
            left: -3vw !important;
        }
        .slick {
            width: 3rem;
            height: 3rem;
        }
        .slick-next {
            right: -3vw !important;
        }
    }

    @media screen and (max-width: 1316px) {
        .delimiter {
            width: 55%;
        }

        .carousel-content {
            width: 80vw;
        }
    }
}
