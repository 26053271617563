$sectionBackground: #ffffff;

.solutionWrapper {
    min-height: 460px;
    position: relative;
    background-color: $sectionBackground;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    .solutionContent {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3vh 0 0 0;

        &-title {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            $bubbleSize: 60px;
            
            .numeroWrapper {
                position: relative;
                width: $bubbleSize !important;
                height: $bubbleSize !important;
                background: var(--gradient);

                padding: 2px;

                display: flex;
                justify-content: center;
                align-items: center;

                border-radius: 100%;
                transition: all 100ms linear;

                .numeroWrapped {
                    width: $bubbleSize;
                    height: $bubbleSize;
                    background-color: $sectionBackground;
                    border-radius: 100%;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    text-align: center;
                    align-items: center;
                    color: var(--gradient);
                    font-size: 30px;
                    transition: all 0.1s linear;
                }
            }

            &>p {
                color: var(--gray);
                font-size: 24px;
                margin: 0 0 0 10px;
                width: auto;
            }
        }

        &-desc {
            margin: 0;
            color: var(--gray);
            font-size: 20px;
            line-height: 1.5em;

            .gradient-list {
                $bullet-size: 10px;
            }

            .profit-list {
                padding: 2vh 0 0 2vw;

                &-item {
                    &:not(:last-child) {
                        margin-bottom: 1vh;
                    }
                }
            }
        }

        &-button {
            margin: 4vh 0;
            :first-child {
                background-color: $sectionBackground;
            }
    
            &:hover {
                cursor: pointer;
        
                :first-child {
                    background-color: rgba($color: #000000, $alpha: 0.0);
        
                    :first-child {
                        background: $sectionBackground;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
    }

    .solutionImg {
        position: relative;
        height: 100%;
        width: 40%;
        margin-top: auto;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: nowrap;

        &>hr {
            position: absolute;
            width: 70%;
            height: 20px;
            border: 0;
            bottom: 0;
            background: var(--gradient);
            z-index: 2 !important;
            margin: 0;
        }
    }

    @media screen and (max-width: 1042px) {
        .solutionContent {
            width: 70%;
        }

        .solutionImg {
            position: absolute;
            display: none;
        }
    }
}