#claim-kit {
    height: 100vh;
    position: relative;
    #img {
        height: 100%;
        object-fit: cover;
    }
    #claim-kit-content-wrapper {
        position: absolute;
        left: 12%;
        top: 50%;
        transform: translateY(-50%);
        width: 30rem;
        #logo-wrapper {
            margin-bottom: 8vh;
            #welcome-on {
                color: var(--dashboard-main-color);
                font-size: 36px;
                margin: 0vh 0vw 1vh 0vw;
                text-transform: uppercase;
            }
            #bodycheckup-logo {
                width: 22rem;
            }
        }
        #text-info {
            font-size: 18px;
            margin: 0px;
            margin-bottom: 15vh;
        }
        #button-register {
            align-self: center;
            border: solid 1px var(--dashboard-main-color);
            padding: 0.75vh 2rem 0.75vh 2rem;
            border-radius: 20px;
            align-items: center;
            #button-register-label {
                text-transform: uppercase;
                color: var(--dashboard-main-color);
                font-size: 18px;
            }
            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }
    }
}
