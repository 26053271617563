.error-container-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    border: 1px var(--red) solid;
    border-radius: 5px;
    padding: 10px 20px;
    background-color: var(--background-color);
    width: fit-content;

    &-text {
        color: var(--red);
        margin: 0;
    }

    &>svg {
        position: absolute;
        border-radius: 50%;
        color: var(--red);
        background-color: var(--background-color);

        right: -5%;
        top: -25%;
        transition: all 0.2s ease-in-out;

        &:hover {
            cursor: pointer;
            transform: scale(1.01);
        }
    }
}