$sectionBackground: #ebf1f3;

.orderBox2Wrapper {
    background-color: $sectionBackground !important;
    padding: 2vh 4vw;

    z-index: 2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-button {
        margin: 4vh 0;

        :first-child {
            background-color: $sectionBackground;
        }

        &:hover {
            cursor: pointer;
    
            :first-child {
                background-color: rgba($color: #000000, $alpha: 0.0);
    
                :first-child {
                    background: $sectionBackground;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
}