#go-back {
    color: var(--dashboard-main-color);
    align-items: center;

    h2 {
        font-size: 20px;
        margin: 0px 0px 0px 1rem;
        padding: 0;
        font-family: 'MavenPro-Black';
    }

    .icon {
        margin-right: 1.5vw;
        cursor: pointer;
    }
}
