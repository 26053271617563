.step-header {
    align-items: center;
    position: relative;
    margin-bottom: 6vh;
    .step-title-wrapper {
        .step-title {
            color: var(--blue-performance);
            font-size: 28px;
            @media screen and (max-width: 500px) {
                font-size: 16px;
            }
        }
        .step-sub-title {
            align-self: flex-end;
            font-size: 18px;
            position: absolute;
            bottom: -1vh;
            right: -5vw;
            @media screen and (max-width: 500px) {
                font-size: 13px;
            }
        }
    }
    .step-index-wrapper {
        border-radius: 100%;
        border: solid 2px var(--blue-performance);
        width: 3.5rem;
        height: 3.5rem;
        justify-content: center;
        align-items: center;
        margin-right: 1vw;
        @media screen and (max-width: 500px) {
            width: 2.15rem;
            height: 2.15rem;
        }
        .mission-index {
            margin-right: 0.5rem;
            color: var(--blue-performance);
            font-size: 30px;
            @media screen and (max-width: 500px) {
                font-size: 18px;
            }
        }
    }
}
