@import '/src/index.scss';

.defaultContainer {
    @extend .flex-column;
    @extend .shadow;

    background-color: white;
    padding: 2rem;
    position: relative;
    flex: 1 1 20px;
    margin-bottom: 8vh;
    min-width: 400px;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &-header {
        @extend .full-width;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 200px;

        &>img {
            width: 100%;
            max-height: 200px;
            position: relative;
            
            overflow: hidden;
            top: 0;
            left: 0;

            object-fit: cover;
            object-position: 50% 25%;
        }

        &>h2 {
            margin: 0;
            position: absolute;
            top: 50%;
            right: 25%;
            transform: translateX(50%) translateY(-45%);
            color: var(--dashboard-main-color);
            font-size: 2.5em;
            hyphens: none;
        }

        &>hr {
            position: absolute;
            height: 16px;
            border: 0;
            background: var(--gradient);
            bottom: 0;
            margin-left: 50%;
            transform: translate(-50%, 100%);
            width: 25%;
        }
    }

    &-title {
        @extend .full-width;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        h1, h2, h3, h4, h5, h6 {
            font-size: 20px;
        }

        * {
            margin: 0;
        }

        &:only-child {
            @extend .flex-column;
            @extend .full-height;
        }

        &:not(:only-child) {
            @extend .flex-row-start;
            @extend .full-width;
            @extend .space-between;
        }
    }

    &-body {
        @extend .full-width;

        flex: 1 1 0px;
        padding: 2rem 0 0 0;

        &:only-child {
            @extend .flex-column;
            @extend .full-height;
        }

        &:not(:only-child) {
            @extend .flex-column;
        }

        &>p {
            margin: 15px 0;
            text-align: center;
        }
    }

    &-footer {
        @extend .full-width;

        padding: 2rem 0 0 0;

        &:only-child {
            @extend .flex-column;
            @extend .full-height;
        }

        &:not(:only-child) {
            @extend .flex-column;
        }
    }

    &-separator {
        background: var(--dashboard-main-color);
        width: 15vw;
        height: 1.5vh;
        position: absolute;
        bottom: -0.75vh;
        left: 50%;
        transform: translateX(-50%);
    }
}

.space-header {
    padding-top: 220px
}