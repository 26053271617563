.scienceScenariosWrapper {
    background: var(--gradient);
    padding: 6vh 0;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    color: white;


    transition: all 0.1s ease-in-out;

    &-title {
        font-size: 50px;
        text-align: center;

        &>p {
            margin: 0;
        }
    }

    &-caseList {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;

        padding: 0 10vw;
        margin-bottom: 4vh;

        &-case {
            width: 70%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            &:nth-child(odd) {
                transform: translateX(-10%);
            }
    
            &:nth-child(even) {
                transform: translateX(10%);
            }

            &-number {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 89px !important;
                height: 117px !important;

                font-size: 64px;

                &-img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }

                &>p {
                    margin: 0;
                    text-align: center;
                    
                    transform: translateX(40%);
                }
            }

            &>p {
                width: 85%;
                margin-left: 1vw;
                font-size: 20px;
            }
        }
    }

    &-halfLogo {
        position: absolute;
        bottom: 0;
        
        width: 18vw;
        height: auto;
        max-width: 258px;
        min-width: 158px;
        
        right: 25%;
    }

    @media screen and (max-width: 1149px) {
        &-caseList {
            padding: 0 1vw;

            &-case {
                align-items: flex-start;
                width: 80%;

                &-number {
                    margin-top: 1vh;
                }
            }
        }
    }

    @media screen and (max-width: 830px) {
        &-caseList {
            &-case {
                width: 95%;
                transform: translateX(0) !important;
            }
        }
    }
}