#cookie-policy {
    align-items: center;
    padding: 5vh 0px 10vh 0px;
    .title {
        font-size: 28px;
        color: var(--dark-gray);
        font-size: 36px;
    }
    .sub-title {
        font-size: 20px;
    }
    .text-wrapper {
        width: 70%;
    }
    #types-cookies-container {
        width: 70%;
        #types-cookies-header-wrapper {
            width: 100%;
            margin: 4vh 0px 1.5vh 0px;
            justify-content: space-between;
            .types-cookies-header-item {
                align-items: center;
                padding: 2vh 0px 2vh 0px;
                color: white;
                font-size: 18px;
                border-radius: 30px 30px 0px 0px;
            }
        }
        .cookie-category {
            width: 23%;
        }
        .cookie-objective {
            width: 23%;
        }
        .cookie-description {
            width: 50%;
        }

        .cookie-category-header-background {
            background-color: var(--blue);
        }
        .cookie-objective-header-background {
            background-color: #35d0a5;
        }
        .cookie-description-header-background {
            background-color: var(--light-green);
        }

        .cookie-type-wrapper {
            justify-content: space-between;
            margin-bottom: 0.5vh;
            text-align: center;
            .cookie-category-wrapper {
                justify-content: center;
                align-items: center;
                background-color: rgba(0, 153, 255, 0.2);
            }
            .cookie-objective-wrapper {
                justify-content: center;
                align-items: center;
                background-color: rgba(53, 208, 165, 0.2);
            }
            .cookie-desc-wrapper {
                justify-content: center;
                align-items: center;
                background-color: rgba(102, 255, 0, 0.2);
                .description-desc {
                    padding: 1.5rem;
                }
            }
        }
    }
}
