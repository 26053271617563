#prescription-list {
    #prescription-list-wrapper {
        margin: 2vh 0vw 2vh 0vw;
        flex-wrap: wrap;
        justify-content: space-around;
        // align-items: center;
        .prescription-wrapper {
            min-width: 18rem;
            // flex-grow: 1;
            width: 48%;
            margin: 1.25vh 0vw 1.25vh 0vw;
        }
    }
}
