@import "/src/index.scss";

#profile-add-actions {
    @extend .flex-row;
    @extend .flex-wrap;
    @extend .full-width;

    position: relative;
    z-index: 1;
    margin-bottom: 6vh;

    .action-wrapper {
        width: 45%;
        padding: 1vw;
        margin: 0vh 0vw 2vh 0vw;

        .action {
            text-align: center;
            font-size: 18px;
            color: var(--dashboard-main-color);
            margin: 0vh 0vw 0vh 0vw;
            &:hover {
                cursor: pointer;
            }
        }
        .action-desc {
            text-align: center;
            font-size: 14px;
        }
    }
}
