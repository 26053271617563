#maintenance {
    align-items: center;
    justify-content: center;
    height: 100vh;
    #img {
        width: 24vw;
        min-width: 20rem;
        margin-bottom: 10vh;
    }
    #animated-logo {
        width: 3.5rem;
    }
    #title-container {
        margin: 0px 0px 2vh 0px;
        flex-wrap: wrap;
        justify-content: center;
        #title {
            font-size: 36px;
            margin: 2vh 2vw 2vh 2vw;
            text-align: center;
            color: var(--gray);
        }
        @media screen and (max-width: 1000px) {
            #title {
                font-size: 30px;
            }
            margin: 0px 0px 4vh 0px;
        }
    }
    #sub-title {
        max-width: 90%;
        color: var(--gray);
        margin: 0px 0px 0px 0px;
        text-align: center;
    }
}
