.modal {
    position: fixed;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;

    padding: 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #000000, $alpha: 0.2);

    &-content {
        position: relative;
        z-index: 6;
        box-shadow: 5px 5px 6px #0000003a;
        width: 50vw;
        min-width: 545px;
        max-width: 800px;
        max-height: 80vh;
        padding: 1vh 2vw;
        background-color: white;

        box-sizing: border-box;

        overflow-x: visible;
        overflow-y: auto;

        &-header {
            position: relative;
            padding: 20px 0;
            width: 100%;
            
            &>h2 {
                margin: 0;
                max-width: calc(100% - 32px);
            }
        }

        &-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            row-gap: 1vh;
        }

        &-footer {
            position: relative;
            padding: 20px 0;
            width: 100%;
        }
    }

    .close {
        z-index: 6;
        position: absolute;
        top: 0;
        right: 0;
        color: var(--dashboard-main-color);
        width: 30px;
        height: 30px;
    
        &:hover {
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 545px) {
    .modal {
        &-content {
            width: 100vw;
            min-width: 0px;
            height: 100vh;
            max-height: none;
        }
    }
}