#faq {
    align-items: center;
    #banner-top-container {
        position: relative;
        margin: 6vh 0vw 10vh 0vw;
        width: 70%;
        background: var(--gradient);

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 30px;

        #banner-text-container {
            position: relative;
            width: 60%;
            padding: 4vh 0;
            text-align: center;
            #title {
                color: white;
                margin: 0px;
                font-size: 50px;
                @media screen and (max-width: 1000px) {
                    font-size: 2rem;
                }
                @media screen and (max-width: 800px) {
                    font-size: 1.5rem;
                }
            }
            #sub-title {
                color: white;
                font-size: 20px;
                @media screen and (max-width: 800px) {
                    font-size: 1rem;
                }
            }
        }
    }

    #faq-content-container {
        width: 90%;
        margin-bottom: 15vh;
        
        #faq-filters-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;

            #faq-filterWrapper {
                position: relative;
                background: var(--gray);
                padding: 2px;
            
                border-radius: 30px;
                margin-top: 1vh;
                margin-bottom: 1vh;
                overflow: hidden;
                white-space: nowrap;
                transition: all 100ms linear;
            
                &__selected {
                    position: relative;
                    background: var(--gradient);
                    padding: 2px;
            
                    border-radius: 30px;
                    margin-top: 1vh;
                    margin-bottom: 1vh;
                    overflow: hidden;
                    white-space: nowrap;
                    transition: all 100ms linear;
            
                    #filterWrapped {
                        background-color: var(--background-color);
                        border-radius: 30px;
                
                        text-align: center;
                        align-items: center;
                        padding: 0.5rem 1em 0.5rem 1em;
                        color: var(--gradient);
                        font-size: 1em;
                        transition: all 0.1s linear;
                        
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            
                #filterWrapped {
                    background-color: var(--background-color);
                    padding: 1rem;
                    border-radius: 30px;
            
                    text-align: center;
                    align-items: center;
                    padding: 0.5rem 1em 0.5rem 1em;
                    color: var(--gray);
                    font-size: 1em;
                    transition: all 0.1s linear;
                }
            
                &:hover {
                    cursor: pointer;
                }
            }

            :not(:first-of-type) {
                margin-left: 10px;
            }

            :not(:last-of-type) {
                margin-right: 10px;
            }
        }

        #questions-answers-container {
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: flex-start;

            #questions-container {
                flex: 1;
                min-width: 20rem;
                margin: 10vh 0vw 10vh 0vw;

                .question-answer-wrapper {
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    &:last-child {
                        margin: 0vh 0vw 0vh 0vw;
                    }
                    .question-container {
                        margin: 0vh 0vw 4vh 0vw;
                        padding: 4vh 0vw 4vh 0vw;
                        width: 100%;
                        align-items: center;
                        justify-content: space-between;
                        background-color: var(--light-gray2);
                        .question-label {
                            flex: 1;
                            margin: 0px 2vw 0px 5vw;
                            font-size: 16px;
                            text-transform: uppercase;
                        }
                        .icon-display-question {
                            margin-right: 2vw;
                            font-size: 20px;
                        }
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
            #answer-container {
                background-color: white;
                padding: 2em;
                border-radius: 25px;
                box-shadow: -5px 5px 20px 1px rgba(0, 0, 0, 0.12);

                .answer-label {
                    margin: 0px;
                    margin: 0vh 4vw 8vh 4vw;
                    text-align: justify;
                    line-height: 2rem;
                    font-size: 18px;
                    white-space: pre-line;
                    .link {
                        text-decoration: underline;
                        color: inherit;
                    }
                }
            }
        }
    }
}
