#impacted-molecules {
    margin-bottom: 12vh;
    position: relative;
    #molecules-wrapper {
        background-color: var(--light-gray);
        padding: 7vh 0vw 7vh 0vw;
        position: relative;
        #molecules-content-wrapper {
            margin-left: 15vw;
            #molecules-wrapper-title {
                text-transform: uppercase;
                font-size: 20px;
                width: 60%;
                margin: 0px 0px 2vh 0px;
                @media screen and (max-width: 800px) {
                    width: 80%;
                }
                @media screen and (max-width: 500px) {
                    font-size: 16px;
                }
            }
            #molecules-list-wrapper {
                flex-wrap: wrap;
                max-width: 60%;
                justify-content: space-between;
                ul {
                    padding-left: 1rem;
                    padding-right: 4vw;
                    z-index: 1;
                    width: auto;
                    margin: 1vh 0px 1vh 0px;
                }
                li {
                    margin: 0.75vh 0px 0px 0px;
                    white-space: nowrap;
                    color: var(--blue-performance);
                    span {
                        color: black;
                    }
                }
                .molecule-name {
                    font-size: 18px;
                    @media screen and (max-width: 500px) {
                        font-size: 15px;
                    }
                }
                @media screen and (max-width: 800px) {
                    max-width: 80%;
                }
            }

            #logo-bdc {
                position: absolute;
                bottom: 0px;
                left: -3rem;
                max-width: 25rem;
                width: 25vw;
                min-width: 15rem;
            }
            @media screen and (max-width: 1200px) {
                margin-left: 0px;
                align-items: center;
            }
        }
    }

    #button-medical-articles-wrapper {
        justify-content: center;
        width: 70%;
        align-items: center;
        margin: 8vh 0vw 8vh 0vw;
        @media screen and (max-width: 1200px) {
            width: 100%;
        }
        #button-medical-articles {
            border: solid 2px var(--dark-gray);
            padding: 0.5rem 2rem 0.5rem 2rem;
            border-radius: 20px;
            &:hover {
                cursor: pointer;
            }
            span {
                color: var(--dark-gray);
                font-size: 12px;
                text-transform: uppercase;
            }
        }
    }

    #athletics-image {
        position: absolute;
        width: 35vw;
        bottom: 0px;
        right: 0px;
        @media screen and (max-width: 1200px) {
            display: none;
        }
    }
}
