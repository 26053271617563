.complement-detail-modal {
    &-detail {
        display: flex;
        align-items: flex-start;
        gap: 20px;

        &-img {
            float: left;
            margin-right: 20px;

            width: 14rem;
            height: 14rem;
            aspect-ratio: 1/1;
            object-fit: cover;
        }
    }
}