.CompanyNewsSection {
    &-wrapper {
        border: 2px solid var(--dashboard-main-color);
        border-radius: 30px;
        padding: 4vh 10vw;
        display: flex;
        flex-direction: row;

        &>img {
            width: 220px;
        }

        &-content {
            padding-left: 15px;
        }
    }
}