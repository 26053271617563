#contact{
    width: 50%;
    height: 300px;

    #dashboard-container {
        min-height: 220px;
    }

    .icon-container {
        display: flex;
        align-items: center;

        .action {
            cursor: pointer;
        }
    }

    h2 {
        margin: 0;
    }

}

#content-contact {
    margin-left: 10%;
    padding-top: 2em;
    justify-content: space-evenly;

    .row:not(:first-child) {
        margin-top: 2em;
    }
}

.icon-info {
    width: 2rem;
    margin-right: 1.5vw;
}