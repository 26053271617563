#questionnaire-patient {

    #questionnaire-patient-wrapper {
        margin: 2vh 0;
    }

    h2 {
        font-size: 20px;
    }

    #circularProgressBar-questionnaire {
        width: 100px;
        height: 100px;
        dominant-baseline: middle;
        text-anchor: middle;
        margin-right: 5vw;
    }

    #button-questionn-patient {
        display: flex;
        align-items: center;
    }
}