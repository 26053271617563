#previous-analysis {
    #previous-analysis-wrapper {
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 2vh 0vw 2vh 0vw;
        .analysis-wrapper {
            width: 43%;
            min-width: 20rem;
            padding: 1.5vh 1rem 1.5vh 1rem;
            margin: 1.5vh 0vw 1.5vh 0vw;
            justify-content: space-between;
            align-items: center;
            background-color: var(--background-dashboard-color);
            .analysis-date {
                margin: 0px;
                text-transform: uppercase;
            }
        }
    }
}
