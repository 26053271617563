.order-page {
    flex-grow: 1;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    width: 65vw;

    column-gap: 4vw;
    row-gap: 4vw;

    padding: 4vw 0;
    
    ~ .order-page {
        padding: 2vw 0 4vw 0;
    }
}

.solution-type-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 28vw;
    min-width: 340px;
    max-width: 480px;

    border-radius: 20px;
    box-shadow: -5px 5px 20px 1px rgba(0, 0, 0, 0.12);
    transition: all .2s ease-in-out;

    &>img {
        width: min(28vw, auto);
        min-width: 335px;
        max-width: 475px;

        max-height: 330px;

        height: auto;
    }

    &:hover {
        cursor: pointer;
        box-shadow: -5px 5px 20px 1px rgba(0, 0, 0, 0.301);
    }
}

@media screen and (max-width: 1060px) {
    #order-page {
        box-sizing: border-box;

        width: 100vw;
        padding: 1vh 2vw;
    }
}