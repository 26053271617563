@import "/src/tags.scss";
@import "/src/style/flex.scss";
@import "/src/style/modal.scss";
@import "/src/style/fonts.scss";
@import "/src/style/stepper.scss";

:root {
    font-size: 16px;
    font-family: NewJune;
    --sidebar-width: 18rem;
    --background-index: 0;
    --content-index: 5;
    --header-index: 10;
    --foreground-index: 15;
    --gray: #555555;
    --gray2: #646464;
    --dark-gray: #4a4e54;
    --light-dark: #6c6c6c;
    --light-gray: #b7b7b7;
    --light-gray2: #eff0f0;
    --light-gray3: #f8f8f8;
    --yellow: #ffd464;
    --yellow-opacity: rgba(255, 212, 100, 0.2);
    --dark-yellow: #f3bf3c;
    --blue: #0099ff;
    --blue-opacity: rgba(125, 216, 255, 0.2);
    --navy: #1b5299;
    --dark-blue: #5d7798;
    --blue-performance: #36a9e1;
    --purple: #7f5ff3;
    --purple-opacity: rgba(127, 95, 243, 0.2);
    --purple-low-opacity: rgba(127, 95, 243, 0.8);
    --red: #cc0000;
    --light-red: #ff9996;
    --light-red-opacity: rgba(255, 153, 150, 0.2);
    --green: #0f9d58;
    --light-green: #66d34c;
    --light-green-opacity: rgba(102, 211, 76, 0.2);
    --orange: #ffa500;
    --orange-opacity: rgba(255, 165, 0, 0.2);
    --background-color: #ffffff;
    --background-dashboard-color: #f0f4f5;
    // --dashboard-main-color: var(--blue-green-corporate);
    --dashboard-main-color: var(--gradient-blue);
    --blue-green-corporate: #36d0a6;
    --gradient-green: #78c939;
    --gradient-green-rgb: rgb(120, 201, 57);
    --gradient-blue: #0099ff;
    --gradient-blue-rgb: rgb(0, 153, 255);
    --gradient: -webkit-linear-gradient(left, var(--gradient-blue) 0%, var(--gradient-green) 100%);
}

#root {
    position: sticky;

    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    flex-grow: 1;

    min-width: 400px;
    width: 100vw;
    min-height: 100vh;
}

.text {
    &-error {
        color: var(--red);
        font-style: italic;
    }

    &-important {
        color: var(--dashboard-main-color);
        font-weight: 600;
    }
}

.dashboard-page-wrapper {
    margin: auto;
    justify-content: center;
    align-items: center;
}

.line-over {
    text-decoration: line-through;
}

.space, .justify {
    &-start {
        justify-content: start;
    }

    &-flex-start {
        justify-content: flex-start;
    }

    &-end {
        justify-content: end;
    }

    &-flex-end {
        justify-content: flex-end;
    }

    &-center {
        justify-content: center;
    }

    &-between {
        justify-content: space-between;
    }

    &-around {
        justify-content: space-around;
    }

    &-evenly {
        justify-content: space-evenly;
    }
}

.align-items {
    &-center {
        align-items: center;
    }

    &-start {
        align-items: flex-start;
    }

    &-end {
        align-items: flex-end;
    }

    &-stretch {
        align-items: stretch;
    }
}

.justify-content {
    &-center {
        justify-content: center;
    }

    &-start {
        justify-content: flex-start;
    }

    &-end {
        justify-content: flex-end;
    }
}

.align-self {
    &-center {
        align-self: center;
    }

    &-start {
        align-self: flex-start;
    }

    &-end {
        align-self: flex-end;
    }

    &-stretch {
        align-self: stretch;
    }
}

.full {
    &-width {
        width: 100%;
    }

    &-height {
        height: 100%;
    }
}

.half {
    &-width {
        width: 50%;
    }
    
    &-height {
        height: 50%;
    }
}

.bold {
    font-family: NewJune-Bold;
}

.relative {
    position: relative;
}

.shadow {
    box-shadow: -5px 5px 20px 1px rgba(0, 0, 0, 0.12);

    &-plus {
        box-shadow: -5px 5px 20px 1px rgba(0, 0, 0, 0.36);
    }
}

.loader-container {
    align-items: center;
    justify-content: center;
    min-height: inherit;
    height: 100%;
}

.full-height {
    height: 100%;
}

.clickable {
    &:hover {
        cursor: pointer;
    }
}

.icon-back {
    width: 1.5rem;
    &:hover {
        cursor: pointer;
    }
}

.input {
    border: 0;
    border-bottom: 1px solid #d1d1d1;
    font-size: 16px;
    color: var(--gray);
    padding: 1vh 2vw 1vh 0.25vw;
}

.background {
    &-gradient {
        background: -webkit-linear-gradient(left, var(--gradient-blue), var(--gradient-green));
    }

    &-main-color {
        background: var(--dashboard-main-color);
    }
}

.font-title {
    font-family: MadeCanvas;
}

.font-title-bold {
    font-family: MadeCanvas-Bold;
}

.text {
    &-align {
        &-justify {
            text-align: justify;
        }
    
        &-center {
            text-align: center;
        }

        &-start {
            text-align: start;
        }

        &-end {
            text-align: end;
        }
    }

    &-main-color {
        color: var(--dashboard-main-color);
    }

    &-gradient {
        background: -webkit-linear-gradient(left, var(--gradient-blue), var(--gradient-green));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &-letter-spacing {
        letter-spacing: 0.25rem;
    }

    &-capitalize {
        text-transform: capitalize;
    }

    &-uppercase {
        text-transform: uppercase;
    }

    &-lowercase {
        text-transform: lowercase;
    }

    &-italic {
        font-style: italic;
    }
}

.input-gradient {
    border-image: linear-gradient(to right, var(--gradient-blue) 0%, var(--gradient-green) 100%) !important;
    border-image-slice: 1 !important;
}

.unavailable {
    color: var(--light-gray);
}

.delimiter {
    position: absolute;
    width: 40%;
    height: 16px;
    border: 0;
    z-index: 5;

    background: var(--gradient);
}

.topDelimiter {
    top: -14px;
}

.botDelimiter {
    bottom: -14px;
}

.gradient-button {
    position: relative;
    background: var(--gradient);
    padding: 2px;
    text-decoration: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 30px;
    white-space: nowrap;
    transition: all 100ms linear;

    :first-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 30px;

        padding: 0 1.2em;

        text-align: center;
        align-items: center;
        transition: all 0.1s linear;

        font-size: 1rem;

        :first-child {
            display: block;
            margin: 0.7em 0;
            background-color: var(--gradient-blue);
            background-image: var(--gradient);
            background-size: 100%;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &:hover {
        cursor: pointer;
    }
}

.gradient-list {
    position: relative;
    text-align: start;
    display: block;

    $bullet-size: 12px;

    &-item {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;

        &-bullet {
            position: relative;
            transform: translateY(30%);
            width: $bullet-size !important;
            height: $bullet-size !important;
            background: var(--gradient);
            border: 0;
            border-radius: 100%;
            content: "";

            margin: 2px 18px 0 0;
        }

        & > p {
            margin: 0;
            width: 95%;
            font-weight: lighter;
        }
    }
}

%buttonBase {
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-style: solid;
    border-radius: 30px;
    padding: 0.5vh 2vw;
    font-size: 1.1em;
}

.dashboardBtn {
    @extend %buttonBase;

    border-color: var(--dashboard-main-color);
    background-color: var(--dashboard-main-color);
    color: white;

    &-dngr {
        @extend %buttonBase;

        border-color: var(--red);
        background-color: var(--red);
        color: white;
    }

    &-invert {
        @extend %buttonBase;

        border-color: var(--dashboard-main-color);
        background-color: white;
        color: var(--dashboard-main-color);
    }
}

.dashboardBtn,
.dashboardBtn-dngr,
.dashboardBtn-invert {
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    &:disabled {
        cursor: not-allowed;
        border-color: var(--light-gray);
        background-color: var(--light-gray);
    }

    &:not(:only-child) {
        margin-left: 5px;
        margin-right: 5px;
    }
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}

%baseInputString {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    padding: 7px 12px 7px 12px;
    margin: 2px;
    min-width: 180px;
    width: 100%;
    border-radius: 5px;
    background: var(--background-color);

    box-shadow: 0 1px 3px -2px #9098A9;
    cursor: pointer;
    font-size: 16px;
    font-family: Montserrat;

    transition: all 150ms ease-out;

    &::-ms-clear {
        display: none;
    }

    &:hover {
        cursor: text;
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        &:hover {
            cursor: not-allowed;
        }
    }
}

.dashboardInput {
    &[type="number"], &[type="text"], &[type="email"], &[type="tel"], &[type="date"], &[type="time"] {
        @extend %baseInputString;

        border: 2px solid var(--dashboard-main-color);

        &:focus {
            border-color: var(--dashboard-main-color);
            box-shadow: 0 0 0 2px rgba(var(--dashboard-main-color), .2);
        }
    }

    &[type="password"] {
        @extend %baseInputString;

        border: 2px solid var(--dashboard-main-color);

        &:focus {
            border-color: var(--dashboard-main-color);
            box-shadow: 0 0 0 2px rgba(var(--dashboard-main-color), .2);
        }
    }

    &[type="textarea"] {
        @extend %baseInputString;

        border: 2px solid var(--dashboard-main-color);

        &:focus {
            border-color: var(--dashboard-main-color);
            box-shadow: 0 0 0 2px rgba(var(--dashboard-main-color), .2);
        }
    }

    &[type="radio"] {
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        margin: 0;
        margin-right: 5px;

        font: inherit;
        color: currentColor;
        width: 1.15em;
        height: 1.15em;
        border: 0.15em solid currentColor;
        border-radius: 50%;
        transform: translateY(-0.075em);

        display: grid;
        place-content: center;

        &::before {
            content: "";
            width: 0.9em;
            height: 0.9em;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--dashboard-main-color);
        }

        &:checked::before {
            transform: scale(1);
        }

        &:focus {
            border: 0.15em solid var(--dashboard-main-color);
        }

        &:disabled {
            &:hover {
                cursor: not-allowed;
            }
        }
    }

    &-invalid {
        &[type="number"], &[type="text"], &[type="email"], &[type="password"], &[type="tel"], &[type="date"], &[type="time"] {
            @extend %baseInputString;

            border: 2px solid var(--red);

            &:focus {
                border-color: var(--red);
                box-shadow: 0 0 0 2px rgba(var(--red), .2);
            }

            &:disabled {
                &:hover {
                    cursor: not-allowed;
                }
            }
        }

        &[type="textarea"] {
            @extend %baseInputString;

            border: 2px solid var(--red);

            &:focus {
                border-color: var(--red);
                box-shadow: 0 0 0 2px rgba(var(--red), .2);
            }

            &:disabled {
                &:hover {
                    cursor: not-allowed;
                }
            }
        }
    }
}

.dashboardTextArea {
    @extend %baseInputString;

    padding: 10px 25px 10px 10px !important;
    border: 2px solid var(--dashboard-main-color);

    &:focus {
        border-color: var(--dashboard-main-color);
        box-shadow: 0 0 0 2px rgba(var(--dashboard-main-color), .2);
    }

    &-invalid {
        @extend %baseInputString;

        padding: 10px 25px 10px 10px !important;
        border: 2px solid var(--red);

        &:focus {
            border-color: var(--red);
            box-shadow: 0 0 0 2px rgba(var(--red), .2);
        }

        &:disabled {
            &:hover {
                cursor: not-allowed;
            }
        }
    }
}

%baseInputSelect {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 7px 12px 7px 12px;
    margin: 2px;
    min-width: 180px;
    width: 100%;
    border-radius: 5px;
    background: var(--background-color);

    // TODO : Determine what that shit was used to do, and if necessary, replace it
    background-image: none;
    // background-image: url("data:image/svg+xml;utf8,<svg fill='var(--dashboard-main-color)' height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    // background-repeat: no-repeat;
    // background-position-x: 100%;
    // background-position-y: 5px;

    box-shadow: 0 1px 3px -2px #9098A9;
    cursor: pointer;
    font-size: 16px;
    font-family: Montserrat;

    transition: all 150ms ease;

    &::-ms-clear {
        display: none;
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        &:hover {
            cursor: not-allowed;
        }
    }

    &::after {
        content: '▼'; // Icône de dropdown
        font-size: 0.75em;
        color: #555; // Couleur de l'icône
        position: absolute;
        left: 0.75em; // Ajuster la position de l'icône
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none; // Empêche l'icône d'interférer avec l'interaction utilisateur
    }

    option, &-option {
        &:hover, &:checked, &:active {
            background-color: var(--background-color);

            background: -webkit-linear-gradient(left, var(--gradient-blue), var(--gradient-green));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &:checked {
            display: none;
        }

        &[value=""][disabled] {
            display: none;
        }
    }
}

.dashboardSelect {
    @extend %baseInputSelect;
    
    border: 2px solid var(--dashboard-main-color);

    &:focus {
        border-color: var(--dashboard-main-color);
        box-shadow: 0 0 0 2px rgba(var(--dashboard-main-color), .2);
    }

    &-invalid {
        @extend %baseInputSelect;
    
        border: 2px solid var(--red);

        &:focus {
            border-color: var(--red);
            box-shadow: 0 0 0 2px rgba(var(--red), .2);
        }
    }

    &-multiple {
        @extend %baseInputSelect;

        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 37px;
        border: 2px solid var(--dashboard-main-color);

        display: flex;
        align-items: flex-start;

        overflow: scroll;

        fieldset {
            display: none;
        }
    }
}

.icon-check {
    color: var(--dashboard-main-color);
}

%baseNoData {
    @extend .MavenPro-bold;
    text-align: center;
    font-style: italic;
    color: var(--light-gray);
    margin: 0px;
}

.no-data {
    @extend %baseNoData;
    font-size: 18px;

    &-small {
        @extend %baseNoData;
        font-size: 14px;
    }
}

.text-dashboard-no-data {
    font-size: 18px;
    color: var(--light-gray);
    margin: 0px;
}

@media screen and (max-width: 740px) {
    .delimiter {
        height: 10px;
    }
}

.dashboard-container-gap {
    column-gap: 8vh;
}
