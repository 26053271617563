#form-prescription {
    width: 90%;
    margin: auto;

    #go-back {
        margin: 10vh 0vw 2vh 0vw;

        h2 {
            font-weight: bold;
            font-size: 24px;
            text-transform: uppercase;
        }
    }

    #dashboard-container {
        margin-top: 20px;
    }

    #form-prescription-content {
        margin: 7vh 0 0 0;

        #pdf-visual {
            min-height: 450px;
            min-width: 400px;
        }

        #buttons-prescrition {
            width: 20%;
        }
    }

}