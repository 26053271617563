@import '/src/index.scss';

.baseInformations {
    &-open-button {
        @extend .flex-column;
        @extend .shadow;
    
        position: fixed;
        right: 0;
        z-index: 5;
    
        padding: 10px;
        border-radius: 10px 0 0 10px;
        background-color: var(--background-color);   
    }

    &-wrapper {
        @extend .flex-column;
        @extend .shadow;

        position: fixed;
        right: 0;
        z-index: 5;
        min-width: 100px;

        padding: 10px;
        border-radius: 10px 0 0 10px;
        background-color: var(--background-color);

        & > hr {
            width: 80%;
            border: 0;
            border-radius: 20px 5px;
            background-color: var(--dashboard-main-color);
            height: 4px;
        }

        > p {
            margin: 0;
        }

        > h4 {
            margin: 0 0 5px 0;
        }
    }
}