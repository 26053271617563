$sectionBackground: #ebf1f3;

#purpose-wrapper {
    position: relative;
    background-color: $sectionBackground !important;
}

#purpose-container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    align-self: center;

    padding: 4vh 2vw 0 2vw;

    #purpose-title {
        position: relative;
        display: flex;

        flex-direction: column;
        width: 380px;

        &>p {
            width: auto;
            position: relative;
            font-size: 50px !important;
            margin: 0;
            text-align: left;
            width: max-content;
        }

        &>span {
            position: relative;
            font-weight: lighter;
            text-align: right;
            margin-right: 50px;
            font-size: 18px !important;
            color: var(--gray);
        }
    }

    #purpose-subtitle {
        align-self: flex-end;
        font-weight: lighter;
        font-size: 16px;
        text-align: center;
    }

    #purpose-description {
        text-align: center;
        font-size: 16px;
        line-height: 1.5em;
        letter-spacing: 0.1rem;
        word-spacing: 0.1rem;

        margin-bottom: 0;
    }

    .text {
        line-height: 5vh;
        margin: 0px 0px 4vh 0px;
    }

    #purpose-order {
        z-index: 1;
        margin: 4vh 0;

        :first-child {
            background-color: $sectionBackground;
        }

        &:hover {
            cursor: pointer;
    
            :first-child {
                background-color: rgba($color: #000000, $alpha: 0.0);
    
                :first-child {
                    background: $sectionBackground;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }

    .purpose-container-halfLogo {
        position: absolute;
        z-index: 0;
        bottom: 0;
        
        width: 18vw;
        height: auto;
        max-width: 280px;
        
        right: 25%;
    }

    @media screen and (max-width: 906px) {
        width: auto;
        padding: 10px 4vw;

        #purpose-title-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            
            #purpose-title {
                margin-bottom: 30px;
            }
        }
        
        &>div {
            text-align: center;
        }


        #purpose-description {
            padding: 10px 1vw;
        }
    }

    @media screen and (max-width: 800px) {
        #title {
            font-size: 2rem;
        }
        .text {
            font-size: 0.8rem;
        }
    }
}