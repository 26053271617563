@import '/src/index.scss';

%baseAnswer {
    @extend .flex-column;

    flex: 1;
    min-width: 30%;

    background-color: var(--light-gray2);
    padding: 2vh 2vw;
    border-radius: 5px;
    margin: 10px 15px;
    
    &>p {
        text-align: center;
        margin: 0;
    }
}

.question-wrapper {
    @extend .flex-column;

    max-width: 700px;
    margin-left: auto;
    margin-right: auto;

    &>h2 {
        @extend .text-align-center;
        @extend .text-main-color;

        font-size: 2.5em;
    }

    #answers-container {
        @extend .full-width;
        @extend .flex-row;
        @extend .flex-wrap;

        align-items: stretch;

        .answer {
            @extend %baseAnswer;

            &-selected {
                outline: 2px solid var(--dashboard-main-color);

                @extend %baseAnswer;
            }
        }
    }
}

@media screen and (max-width: 730px) {
    %baseAnswer {
        min-width: 60%;
    }
}