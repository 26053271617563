@import '/src/index.scss';

#last-messages {
    height: 100%;
}

#searchbar {
        
    #input-search-contact {
        width: 90%;
        border: none;
        background-color: var(--light-gray2);
        padding: 1vh 1rem 1vh 1rem;
        font-size: 16px;
        margin: 2vh 0vw 2vh 0vw;
    }
}

#contact-messages-wrapper {
    flex-grow: 1;
    justify-content: space-between;
}

#last-messages-wrapper {
    overflow-y: auto;
    height: 0vh;
    flex-grow: 1;
    align-items: center;
}

.last-message-wrapper {
    @extend .full-width;

    box-sizing: border-box;
    border: solid 1px var(--dashboard-main-color);
    border-radius: 10px;

    &:not(:last-child) {
        margin-bottom: 1vh;
    }

    &:hover {
        cursor: pointer;
    }

    .message-content-wrapper {
        padding: 1rem;
        .contact-info-wrapper {
            align-items: center;
            img {
                width: 3rem;
                height: 3rem;
                object-fit: cover;
                border-radius: 50%;
                margin-right: 0.75rem;
                border: solid 1px var(--dashboard-main-color);
            }
            .icon-no-pic {
                width: 3rem;
                height: 3rem;
                margin-right: 0.75rem;
                color: var(--light-gray);
            }
            .contact-name {
                text-transform: uppercase;
                font-size: 18px;
            }
            .message-date {
                font-size: 14px;
            }
        }
        .last-message {
            font-size: 14px;
            margin: 1.5vh 0vw 0vh 0vw;
        }
    }
}

.message-selected {
    background-color: var(--dashboard-main-color);
    color: white;
    img {
        border: solid 1px white !important;
    }
    .icon-no-pic {
        color: white !important;
    }
}

#button-new-message {
    background-color: var(--dashboard-main-color);
    align-self: center;
    padding: 0.5vh 2rem 0.5vh 2rem;
    margin: 2vh 0rem 0vh 0rem;
    border-radius: 20px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    #label-button {
        color: white;
        text-transform: uppercase;
    }
}
