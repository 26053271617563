#privacy {
    align-items: center;
    justify-content: center;
    padding-bottom: 10vh;

    #content {
        margin: 2vh 0px 0px 0px;
        width: 70%;
        .bold {
            font-weight: 500;
        }
        h4,
        p,
        ul {
            margin: 1% 0% 1% 0%;
        }
        .color-link {
            color: var(--navy);
            font-weight: 600;
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
        .underline {
            text-decoration: underline;
        }
        a {
            color: var(--purple);
        }
        .section-title {
            font-size: 20px;
        }
    }
    #privacy-policy-top-container {
        width: 100%;
        align-items: center;
        padding: 3vh 0vw 3vh 0vw;
        #privacy-policy-wrapper {
            width: 70%;
            color: white;
        }
        #title {
            font-size: 36px;
            margin: 0px 0px 2vh 0px;
        }
        #sub-title {
            font-size: 20px;
            margin: 0px 0px 0.5vh 0px;
        }
        p {
            margin: 0px;
        }
    }
}
