body {
    margin: 0;
    // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    // 	"Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input,
select,
textarea,
option,
button {
    font-family: NewJune;
}

textarea {
    resize: none;
}

fieldset {
    border: 2px solid var(--dashboard-main-color);
    border-radius: 5px;
}