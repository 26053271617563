.MavenPro {
    font-family: MavenPro-Regular;

    &-black {
        font-family: MavenPro-Black;
    }
    &-bold {
        font-family: MavenPro-Bold;
    }
}

.Sofachrome {
    font-family: Sofachrome-Regular;

    &-italic {
        font-family: Sofachrome-Italic;
    }
}

.uppercase {
    text-transform: uppercase;
}

.hide-text-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}