#step-mission {
    margin-bottom: 12vh;
    align-items: center;
    #step-mission-wrapper {
        width: 90%;
        align-items: center;

        #step-mission-content-wrapper {
            width: 100%;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            #rugby-image {
                width: 20rem;
                margin: 2vh 1rem 2vh 1rem;
            }
            #text-wrapper {
                padding: 0vh 1rem 0vh 1rem;
                max-width: 35rem;
                min-width: 25rem;
                flex: 1 1 0;
                @media screen and (max-width: 500px) {
                    min-width: 15rem;
                }

                #main-text {
                    border-bottom: solid 2vh var(--blue-performance);
                    margin-bottom: 7vh;
                    font-size: 18px;
                }
                #text-pre-list {
                    margin: 0px;
                }

                li {
                    color: var(--blue-performance);
                    margin-bottom: 1vh;
                    span {
                        color: black;
                    }
                }
            }
        }
    }
}
