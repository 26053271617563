@import '/src/index.scss';

#chat {
    height: 100%;
}

#chat-header {
    width: 100%;
    align-items: center;
    margin-bottom: 1vh;
    #chat-header-data {
        width: 100%;
        p {
            color: red;
        }
    }
    #contact-img {
        width: 5rem;
        height: 5rem;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 1rem;
    }
    #empty-profile-pic {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        margin-right: 1rem;
        justify-content: center;
        align-items: center;
    }
    #contact-name {
        text-transform: uppercase;
    }
}

#conversation-wrapper {
    @extend .full-width;
    
    flex-grow: 1;
    overflow-y: auto;
    scroll-behavior: smooth;

    padding-right: 10px;

    .message-sent {
        align-self: flex-end !important;
        background-color: var(--light-gray);
    }
    .message-received {
        align-self: flex-start;
        background-color: var(--dashboard-main-color);
    }
    .message-wrapper {
        vertical-align: bottom;

        margin: 1vh 0vw 1vh 0vw;
        padding: 1vh 1rem 1vh 1rem;
        max-width: 60%;
        border-radius: 10px;
    }
    .message-date-left {
        align-self: flex-start;
    }
    .message-date-right {
        align-self: flex-end;
    }
    .message-date {
        font-size: 14px;
        color: var(--light-gray);
        margin-top: 2vh;
    }
    .message {
        color: white;
    }
}

#chat-input-wrapper {
    @extend .flex-row;
    @extend .full-width;
    
    position: relative;

    #chat-input {
        flex-grow: 1;
        border-radius: 10px;
        padding: 1.5vh 1rem 1vh 1rem;
        border: solid 1px var(--dashboard-main-color);
        height: 8vh;
        min-height: 150px;
        font-size: 16px;
    }
    &>button {
        position: absolute;
        bottom: 0px;
        right: 0px;
        margin: 1.5vh 0.75rem;
    }
}
