#prescription-preview {
    #prescription-wrapper {
        justify-content: center;
        align-items: center;
        margin: 3vh 0vw 3vh 0vw;
        #prescription-doctor-wrapper {
            margin-right: 3vw;
            font-size: 18px;
            #prescription-doctor-name {
                text-transform: uppercase;
                margin: 0vh 0vw 1vh 0vw;
            }
        }
    }
}
