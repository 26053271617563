@import './flex.scss';
@import './fonts.scss';

.step {
    @extend .flex-column;
    @extend .MavenPro;
    
    border-radius: 100%;
}
.step-label {
    @extend .MavenPro-black;
    @extend .uppercase;

    font-size: 16px;
}
.step-not-done {
    background-color: white;
    color: var(--dashboard-main-color);
    border: solid 2px white;
    font-size: 20px;
}
.step-done {
    border: solid 2px var(--dashboard-main-color);
    color: var(--dashboard-main-color);
}
.MuiStepConnector-line {
    border: solid 1px var(--dashboard-main-color);
}