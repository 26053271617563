#faq-contact-wrapper {
    justify-content: space-around;
    margin: 2vh 0vw 2vh 0vw;
    flex-wrap: wrap;
    .contact-wrapper {
        align-items: center;
        margin: 1vh 0vw 1vh 0vw;
        .contact-icon {
            margin-right: 1.5rem;
            width: 3rem;
        }
        .contact-info {
            // font-size: 14px;
        }
        .contact-label {
            font-size: 14px;
            color: var(--light-gray);
        }
    }
}
