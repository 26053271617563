@import '/src/index.scss';

.titre-colonne {
    color: var(--dashboard-main-color);
    width: 25%;
}

#data-titre {
    padding: 0 0.6em;
    font-weight: 100;
}

.icon-arrow-right {
    width: 1.25rem;
}

.table {
    margin-bottom: 2em;

    &-body {
        width: 100%;

        .data-row {
            @extend .space-center;

            &:nth-child(odd) {
                background-color: var(--light-gray3);
            }

            &:nth-child(even) {
                background-color: var(--background-color);
            }

            .data {
                @extend .hide-text-overflow;
                
                text-align: center;
                padding: 0 10px;
            }
        }
    }
}
