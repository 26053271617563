#modal-header {
    #title {
        margin: 0px;
        font-size: 20px;
        text-transform: uppercase;
        color: var(--dashboard-main-color);
    }
    #icon-close {
        &:hover {
            cursor: pointer;
        }
    }
}

#modal-content {
    margin: 2vh 0rem 6vh 0rem;
    #modal-content-text {
        font-size: 16px;
    }

    textarea {
        width: 100%;
        border: 2px solid var(--dashboard-main-color);
        padding: 10px;
        border-radius: 25px;
    }
}

#modal-buttons-wrapper {
    justify-content: flex-end;
    margin-bottom: 1vh;
    column-gap: 1.5rem;
}
