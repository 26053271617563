#doctor-comment {
    #title-wrapper {
        margin-bottom: 3vh;
    }
    #doctor-comment-content-wrapper {
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 2rem;
        #comment-wrapper {
            flex: 50%;
            border: solid 2px var(--dashboard-main-color);
            border-radius: 25px;
            min-width: 20rem;
            p {
                padding: 1.5rem 2rem 2rem 1.5rem;
            }
        }
    }
    #doctor-wrapper {
        width: auto;
        align-items: center;
        #doctor-info-wrapper {
            align-items: center;
            margin-bottom: 6vh;
            img {
                width: 6rem;
                height: 6rem;
                border-radius: 100%;
                object-fit: cover;
                margin-right: 2rem;
            }
            #icon-no-pic {
                width: 6rem;
                height: 6rem;
                margin-right: 2rem;
            }
            .libelle-info {
                color: var(--light-gray);
                text-transform: uppercase;
            }
            .margin {
                margin-bottom: 2vh;
            }
        }
        #button-send-message-wrapper {
        }
    }
}
