#sample-video {
    align-items: center;
    margin-bottom: 12vh;
    #title-wrapper {
        box-sizing: border-box;
        padding: 0vh 2rem 0vh 2rem;
        align-items: center;
        position: relative;
        margin-bottom: 3vh;
        #title {
            font-size: 36px;
            margin: 0rem 0vw 1rem 0vw;
        }
        #sub-title {
            text-transform: uppercase;
            bottom: 0px;
            right: 0px;
        }
    }

    #video-wrapper {
        width: 70vw;
        min-width: 20rem;
        height: 60vh;
    }
}
