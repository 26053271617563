@import "/src/index.scss";

#referring-doctor {
    .dashboard-button {
        align-self: center;
    }
}

#referring-doctor-wrapper {
    align-items: center;
    margin: 1vh 0vw 1.5vh 0vw;

    .doctor-info-wrapper {
        &:first-child {
            margin-bottom: 2vh;
        }
    }
    .label-referring-doctor {
        color: var(--light-gray);
        text-transform: uppercase;
        margin: 0vh 0vw 0.5vh 0vw;
    }
    .info-referring-doctor {
        margin: 0vh 2rem 0vh 0vw;
    }
    #doctor-name {
        // margin: 0vh 0vw 1vh 0vw;
    }
}

#doctor-image-wrapper {
    @extend .background-main-color;

    padding: 0.2rem;
    border-radius: 100px;
    margin-right: 2vw;
    min-width: 80px;
    min-height: 80px;
    width: 80px;
    height: 80px;
    
    #doctor-image {
        width: 100%;
        height: 100%;
        height: 100%;
        border-radius: 100%;
        object-fit: cover;
    }
}
