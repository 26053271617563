@import "/src/index.scss";

#responsive-sidebar {
    @extend .flex-column;
    @extend .justify-content-start;
    @extend .align-items-start;

    position: absolute;
    z-index: var(--foreground-index);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background-color: rgba($color: #000000, $alpha: .4);

    opacity: 0;
    -webkit-animation: background-fadein 0.5s forwards;
    animation: background-fadein 0.5s forwards;

    &-wrapper {
        @extend .flex-column;
        @extend .justify-content-start;
        @extend .full-height;

        position: relative;
        background-color: var(--background-color);
        width: 80vw;
        max-width: 350px;
        top: 0;
        left: -100%;
        padding: 4vh 0;

        overflow-x: hidden;
        overflow-y: auto;

        left: -100%;
        -webkit-animation: menu-slidein 0.4s forwards;
        -webkit-animation-delay: .3s;
        animation: menu-slidein 0.4s forwards;
        animation-delay: .3s;

        @media screen and (max-width: 437px) {
            width: 100vw;
        }
    }

    &-logo {
        width: 70%;

        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }

    &-close {
        @extend .flex-column;
        position: fixed;
        top: 0;
        margin: 6vh 0;
        padding: 5px;
        background-color: var(--background-color);

        left: 350px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

        @media screen and (max-width: 387px) {
            left: auto;
            right: 0;
            border-radius: 10px;
        }

        &:hover {
            cursor: pointer;
        }

        opacity: 0;
        -webkit-animation: background-fadein 0.5s forwards;
        -webkit-animation-delay: .7s;
        animation: background-fadein 0.5s forwards;
        animation-delay: .7s;
    }

    &-welcome {
        @extend .flex-column;
        
        max-width: 90%;
        align-items: center;
        padding: 0 10px;

        .sidebar-title {
            @extend .text-align-center;
            @extend .text-uppercase;
            @extend .hide-text-overflow;

            margin: 3vh 0vw 0.5vh 0vw;
            font-size: 22px;
        }

        #subTitle {
            width: 90%;
            text-align: center;
            margin: 0vh 0vw 3vh 0vw;
            font-size: 15px;
        }
    }

    @media screen and (min-width: 900px) {
        display: none;
    }
}

#responsive-sidebar-button {
    display: none;
    position: absolute;

    top: 0;
    left: 0;
    margin: 6.3vh 0vw 1vh 6vw;

    &:hover {
        cursor: pointer;
    }

    @media screen and (max-width: 900px) {
        display: block;
    }
}

@-webkit-keyframes background-fadein {
    100% { opacity: 1; }
}
@keyframes background-fadein {
    100% { opacity: 1; }
}

@-webkit-keyframes menu-slidein {
    100% { left: 0; }
}
@keyframes menu-slidein {
    100% { left: 0; }
}