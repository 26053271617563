.cart-items-container {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    position: relative;
    box-sizing: border-box;
    padding: 2vh 1vw;
    max-height: 18.5vh;
    overflow-y: auto;
    row-gap: 1.5vh;
}