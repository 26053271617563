#products {
  #product-presentation {
    background: var(--light-gray3);

    #img-container {
      width: 45%;
      justify-content: center;
      img{
        width: 30vw;
      }
    }

    #box-image {
      width: 100%;
      height: auto;
    }

    #product-presentation-left {
      justify-content: center;
      width: 45%;
    }

    #product-presentation-text {
      margin-left: 15%;
    }

    #product-presentation-h1 {
      color: var(--light-dark);
      font-family: Montserrat;
      font-size: 40px;
      margin-bottom: 10%;
    }

    #product-presentation-h2 {
      font-size: 20px;
      font-family: Montserrat;
      color: var(--light-gray);
    }

    #product-presentation-p {
      font-family: Montserrat;
      width: 90%;
      color: var(--light-gray);
      line-height: 30px;
      font-size: 16px;
    }

    #icon-arrow {
      width: 10%;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 5%;
    }

    #arrow-down {
      border-radius: 50%;
      background: #FFFFFF;
      width: 5vw;
      height: 5vw;
      justify-content: center;
      align-items: center;
    }
  }

  #check-health-title {
    font-family: Montserrat;
    font-size: 32px;
    color: #5C5C5C;
    align-self: center;
    width: 30%;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .box-details {
    background: var(--light-gray3);
  }

  .box-details-left {
    width: 50%;
    justify-content: center;
  }

  .box-details-right {
    width: 50%;
    position: relative;
    overflow: hidden;
  }

  #box-details-first-ellipse-1 {
    border-radius: 50%;
    position: absolute;
    background: rgba(255, 153, 150, 0.4);
    z-index: 1;
    padding: 10%;
    top: 5%;
    left: 30%;
  }

  #box-details-first-ellipse-2 {
    border-radius: 50%;
    position: absolute;
    background: rgba(127, 95, 243, 0.4);
    z-index: 1;
    padding: 10%;
    bottom: 5%;
    left: 0;
  }

  #box-details-first-ellipse-3 {
    border-radius: 50%;
    position: absolute;
    background: rgba(255, 212, 100, 0.4);
    z-index: 1;
    padding: 5%;
    bottom: 15%;
    left: 50%;
  }

  #box-details-first-ellipse-4 {
    border-radius: 50%;
    position: absolute;
    background: rgba(125, 216, 255, 0.4);
    z-index: 1;
    padding: 5%;
    top: 15%;
    right: 5%;
  }

  #box-details-first-ellipse-5 {
    border-radius: 50%;
    position: absolute;
    background: rgba(255, 153, 150, 0.4);
    z-index: 1;
    padding: 15%;
    bottom: -10%;
    right: -10%;
  }

  .box-details-right img {
    width: 85%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .box-details-left-text {
    margin-left: 15%;

    ul {
      padding: 0;
      margin-top: 7%;
    }

    li {
      list-style-type: none;
      margin-bottom: 5%;
      background-image: url("https://img.icons8.com/android/24/26e07f/checkmark.png");
      background-repeat: no-repeat;
      padding-left: 7%;
      line-height: 25px;
      background-position: 0 center;
      color: var(--gray2);

      span {
        font-family: Montserrat-Bold;
      }
    }

    .button-subscribe {
      width: 60%;
      padding: 3%;
      font-family: Montserrat-Bold;
      color: var(--gray);
      border: 2px solid var(--gray);
      margin-top: 5%;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 10%;
    }
  }

  .box-details-left-first-line {
    align-items: center;
    margin-top: 5%;

    h1 {
      width: 60%;
      font-size: 38px;
      font-family: Montserrat-Bold;
      color: #C4C4C4;
    }

    p {
      width: 20%;
      font-size: 30px;
      color: var(--gray);
      font-family: Montserrat-Bold;
      margin: 0;
      line-height: 20px;

      span {
        font-size: 15px;
      }
    }
  }

  #banner-black {
    width: 100%;
    background-color: #000000;
    margin-top: 5%;
    height: 20%;
    overflow: hidden;

    #banner-black-text {
      margin-left: 15%;
      width: 80%;
    }

    h1 {
      color: #FFFFFF;
      font-size: 32px;
      margin-top: 10%;
    }

    p {
      color: #FFFFFF;
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 10%;
    }
  }

  .banner-left {
    width: 50%;
  }

  .banner-right {
    width: 50%;
    position: relative;
    overflow: hidden;
    border-top-left-radius: 50px 50%;
    border-bottom-left-radius: 50px 50%;

    img {
      position: absolute;
      top: -9999px;
      left: -9999px;
      right: -9999px;
      bottom: -9999px;
      margin: auto;
    }
  }

  #double-product-presentation {
    margin-top: 5%;
    width: 100%;
    justify-content: space-between;

    .product-presentation-small {
      width: 48%;
      background: var(--light-gray3);
      position: relative;
      overflow: hidden;

      img {
        width: 80%;
        margin-left: 10%;
      }

      .product-presentation-small-button {
        justify-content: center;
        width: 80%;
        margin-bottom: 10%;

        button {
          width: 80%;
          padding: 4%;
        }
      }

      #product-presentation-small-button-right {
        margin-top: 13%;
      }

      #product-presentation-ellipse {
        border-radius: 50%;
        position: absolute;
        background: rgba(255, 153, 150, 0.4);
        z-index: 1;
        padding: 15%;
        bottom: -5%;
        right: -5%;
      }
    }
  }

  #banner-white {
    background: var(--light-gray3);
    margin-top: 5%;
    margin-bottom: 5%;

    #banner-white-text {
      margin-left: 15%;
      width: 80%;

      h1 {
        color: #5C5C5C;
        font-size: 32px;
        margin-top: 10%;
      }

      p {
        color: #5C5C5C;
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 10%;
      }
    }
  }

  #box-details-second-ellipse-1 {
    border-radius: 50%;
    position: absolute;
    background: rgba(255, 153, 150, 0.4);
    z-index: 1;
    padding: 10%;
    bottom: 5%;
    left: 10%;
  }

  #box-details-second-ellipse-2 {
    border-radius: 50%;
    position: absolute;
    background: rgba(125, 216, 255, 0.4);
    z-index: 1;
    padding: 7%;
    top: 15%;
    right: 5%;
  }

  #box-details-second-ellipse-3 {
    border-radius: 50%;
    position: absolute;
    background: rgba(255, 212, 100, 0.4);
    z-index: 1;
    padding: 10%;
    bottom: 10%;
    right: 5%;
  }

  .box-details:last-child{
    margin-bottom: 5%;
  }
}
