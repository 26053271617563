#form-contact-us {
    align-items: flex-start;
    border-radius: 20px;
    padding: 3rem;
    // input,
    textarea {
        margin-bottom: 5vh;
        padding: 2vh 1vw 2vh 1vw;
    }
    #icon-back {
        width: 2rem;
        margin-bottom: 1vh;
        &:hover {
            cursor: pointer;
        }
    }
    ::placeholder {
        text-transform: uppercase;
        color: var(--light-gray);
        font-size: 14px;
    }
    #title-container {
        margin: 1vh 0vw 4vh 0vw;
        .title {
            margin: 0vh 0vw 1vh 0vw;
            color: var(--dark-gray);
            font-size: 42px;
        }
    }
    textarea {
        height: 8vh;
        margin: 0px;
    }
    .inputs-row {
        width: 100%;
        justify-content: space-between;
        // input {
        //     width: 40%;
        // }
    }
    #button-wrapper {
        align-self: center;
        margin-top: 4vh;
    }
    #send-email-wrapper {
        margin-top: 2vh;
        font-size: 14px;
        #send-email-text {
            color: var(--light-gray);
            #email-label {
                color: var(--blue);
                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }
}
