#terms {
    align-items: center;
    padding-bottom: 10vh;
    #content {
        width: 70%;
        margin: 5vh 0px 0px 0px;
        #conditions-title {
            margin: 0px;
            font-size: 36px;
            color: var(--dark-gray);
        }
    }
    .title {
        font-size: 20px;
    }
}
