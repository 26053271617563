#questionnaire {
    min-height: 100vh;
    justify-content: space-between;
    #header-questionnaire {
        margin: 2vh 0vw 2vh 0vw;
        justify-content: space-between;
        align-items: center;
        padding: 0% 5% 0% 5%;

        #logo {
            width: 8rem;
            &:hover {
                cursor: pointer;
            }
        }

        #back-container {
            cursor: pointer;
            align-items: center;
            span {
                font-size: 18px;
            }
        }
    }

    #buttons-navigation-container {
        justify-content: center;
        align-items: center;
        margin-bottom: 10vh;
        button {
            margin: 0% 3% 0% 3%;
        }
    }

    .questionnaire-button {
        justify-content: center;
        align-items: center;
        padding: 1vh 3rem 1vh 3rem;
        border-radius: 20px;
        border: none;
        span {
            text-transform: uppercase;
            color: white;
            font-size: 15px;
        }
        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }
    .default-background {
        background-color: var(--light-gray);
        &:hover {
            cursor: not-allowed;
            opacity: 0.8;
        }
    }

    #success-container {
        align-items: center;
        width: 70%;
        align-self: center;
        #title {
            font-size: 40px;
            color: var(--dashboard-main-color);
        }
        #success-message {
            font-size: 18px;
            font-weight: 600;
            text-align: center;
        }
    }
}

#questionIndexResume {
    text-align: center;
}